import React, { useRef, useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "./leftPanel.scss";
import Select, { components } from "react-select";
import EventQuerySelector from "./QuerySelectorComponents/EventQuerySelector/EventQuerySelector";
import FilterQuerySelector from "./QuerySelectorComponents/FilterQuerySelector/FilterQuerySelector";
import BreakdownSelector from "./QuerySelectorComponents/BreakdownQuerySelector/BreakdownQuerySelector";
import axios from "axios";
const LeftPanel = ({ submitQueryData, savedEventData }) => {
  const featureTypeSelectStyle = {
    control: (styles, { isFocused, isSelected }) => ({
      ...styles,
      width: "280px",
      overflow: "wrap ",
      fontSize: "14px",
      textAlignLast: "center",
      border: "1px solid #c5c5c5",
      borderRadius: "10px",
      color: "black",
      fontWeight: "700",
    }),

    menu: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        width: "145px",
        borderRadius: "5px",
        padding: "5px",
      };
    },
    dropdownIndicator: (base) => ({
      ...base,
      color: "black",
      marginLeft: "-6px",
    }),

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        borderRadius: "10px",
        marginBottom: "4px",
        textAlign: "center",
        backgroundColor: isSelected
          ? "#BB6BD9"
          : isFocused
          ? "#ddedf2"
          : "white",
      };
    },
  };

  const eventQuerySelectorRef = useRef();
  const filterQuerySelectorRef = useRef();
  const breakdownSelectorRef = useRef();
  const [propertyOptions, setPropertyOptions] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState({});
  const kitOptions = [
    { label: "Tacto", value: "Tacto" },
    { label: "Plugo", value: "Plugo" },
    { label: "Orboot Earth", value: "OrbootE" },
    { label: "Orboot Dino", value: "OrbootD" },
  ];

  const [breakdownOptions, setBreakdownOptions] = useState([]);
  const [selectedKit, setSelectedKit] = useState({
    label: "Tacto",
    value: "Tacto",
  });
  const selectedKitRef = useRef(selectedKit);

  useEffect(() => {
    loadBreakdownOptions();
  }, []);
  const handleKitChange = (e) => {
    setSelectedKit(e);
    selectedKitRef.current = e;
    console.log("loading breakdown option - ");
    loadBreakdownOptions();
  };

  const appendGlobalFilterData = (data, additionalData) => {
    const newData = data.map((d) => {
      const eventData = d;
      const eventName = Object.keys(eventData)[0];
      let size = Object.keys(eventData[eventName]).length;
      const newObj = Object.assign(eventData[eventName]);
      Object.keys(additionalData).forEach((key) => {
        if (additionalData[key]["filter"] !== "") {
          newObj[size] = additionalData[key];
          size++;
        }
      });
      d[eventName] = newObj;
      return d;
    });
    return newData;
  };

  const breakdownJSONData = require("../../../Dashboard/Insight/BreakdownJSON.json");
  const handleInsightSubmitSelection = () => {
    const finalData = {};
    let allEventsData = eventQuerySelectorRef.current.getAllEventsData();
    let newAllEventsData = {};

    console.log("final all events data - ", allEventsData);
    const globalFilterData =
      filterQuerySelectorRef.current.getGlobalFilterData();
    console.log("final global filter data final - ", globalFilterData);
    allEventsData = appendGlobalFilterData(allEventsData, globalFilterData);
    let index = 0;
    Object.keys(allEventsData).forEach((key) => {
      newAllEventsData[index++] = allEventsData[key];
    });
    allEventsData = newAllEventsData;
    //console.log("new allEventsData - ", allEventsData);
    // breakdow
    const breakdownData = breakdownSelectorRef.current.getBreakdownData();
    finalData["event_data"] = allEventsData;
    finalData["breakdown"] = breakdownSelectorRef.current.getBreakdownData();
    finalData["platform"] = selectedKit.value.includes("Orboot")
      ? "Orboot"
      : selectedKit.value;
    finalData["kit"] = selectedKit.value.includes("OrbootE")
      ? "Orboot_Earth"
      : selectedKit.value.includes("OrbootD")
      ? "Orboot_Dino"
      : selectedKit.value;
    // console.log("final data - ", finalData);
    submitQueryData(finalData);
  };

  useEffect(() => {
    if (savedEventData) {
      const savedKit = savedEventData["0"]["kit"];
      // setSelectedKit({ label: savedKit.split("_").join(" "), value: savedKit });
      handleKitChange({
        label: savedKit.split("_").join(" "),
        value: savedKit,
      });
    }
  }, [savedEventData]);
  const loadBreakdownOptions = () => {
    const breakdownOptionArr = [{ key: "Breakdown", value: "" }];
    Object.entries(breakdownJSONData).forEach((breakdownData) => {
      const [jsonKit, jsonBreakdownOptions] = breakdownData;
      if (jsonKit === selectedKitRef.current.label) {
        console.log("break ", jsonBreakdownOptions);
        Object.entries(jsonBreakdownOptions).forEach((options) => {
          // console.log("options,", options);
          breakdownOptionArr.push({ label: options[0], value: options[1] });
        });
      }
    });
    console.log("breakdown optios -  ", breakdownOptionArr);
    setBreakdownOptions(breakdownOptionArr);
  };

  const kitStyle = {
    control: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: "white",
      width: "18rem",
      border: "0.5px solid rgba(0, 0, 0, 0.3)",
      borderRadius: "10px",
      height: "30px",
      overflow: "wrap ",
      fontSize: "12px",
      marginBottom: "32px",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 10,
      overflow: "wrap",
      fontSize: "13px",
      width: "16rem",
      borderRadius: "20px",
      padding: "10px",
    }),

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#bb6bd9" : "white",
        color: isSelected ? "white" : "black",
        borderRadius: "10px",
        marginBottom: "5px",
        padding: "8px",
        fontSize: "12px",
        fontWeight: "500",
      };
    },
  };

  const handleEventSelected = (eventData) => {
    console.log("in left panel events data - ", eventData);
    let kit = selectedKit.value;
    if (kit === "OrbootE") {
      kit = "Orboot_Earth";
    } else if (kit === "OrbootD") {
      kit = "Orboot_Dino";
    }
    setSelectedEvents(eventData["0"]);
    const getEventsAPI =
      "https://dd9fu0j50h.execute-api.us-east-2.amazonaws.com/filterFurnace";
    const payloadData = {
      event_selected: {
        kit: selectedKit.value,
        event: eventData,
      },
    };
    axios({
      method: "POST",
      url: getEventsAPI,
      data: payloadData,
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        const data = response.data.result;
        const allProperties = [];
        data.forEach(function (data) {
          if (
            !data.startsWith("_") &&
            !data.toLowerCase().startsWith("sdc") &&
            !data.toLowerCase().startsWith("firebase")
          ) {
            let label = data.split("_").join(" ");

            label = label.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
              letter.toUpperCase()
            );
            label = label.replaceAll("Mp Reserved", "");
            label = label.replaceAll("Mp", "");
            // if (
            //   !label.split(" ")[0] === "Sdc" &&
            //   !label.split(" ")[0] === "Firebase"
            // )
            allProperties.push({ label: label, value: data });
          }
        });
        setPropertyOptions(allProperties);
        // console.log("-------- properties - ", allProperties);
      })
      .catch((err) => {
        console.log("error in get global filter properties!!");
        console.log(err);
      });
  };
  return (
    <div>
      <Row
        style={{
          borderRight: "1px solid #DFDFDF",
          flexDirection: "column",
          width: "95%",
          height: "88vh",
        }}>
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minHeight: "20vh",
            maxHeight: "82vh",
            paddingBottom: "20px",
            overflowX: "hidden",
            overflowY: "scroll",
          }}>
          <span
            style={{
              width: "18rem",
              fontWeight: 700,
              marginBottom: "4px",
              marginTop: "10px",
            }}>
            Kit
          </span>
          <Select
            id={"kit-selector"}
            components={{
              IndicatorSeparator: () => null,
            }}
            closeMenuOnSelect
            placeholder="Select here"
            styles={kitStyle}
            value={selectedKit}
            options={kitOptions}
            onChange={handleKitChange}
            menuPlacement="auto"
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            isSearchable={false}
          />
          <EventQuerySelector
            kitName={selectedKit}
            ref={(el) => (eventQuerySelectorRef.current = el)}
            sendSelectedEventToParent={handleEventSelected}
            savedEventData={savedEventData}
          />
          <FilterQuerySelector
            ref={(el) => (filterQuerySelectorRef.current = el)}
            propertyOptions={propertyOptions}
            event={selectedEvents}
            savedEventData={savedEventData}
            kitName={selectedKit}
          />
          <BreakdownSelector
            breakdownOptions={breakdownOptions}
            ref={(el) => (breakdownSelectorRef.current = el)}
            savedEventData={savedEventData}
            kitName={selectedKit}
          />
        </Col>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "30px",
            marginTop: "20px",
          }}>
          <button
            className={"query-selector-go-btn"}
            onClick={handleInsightSubmitSelection}>
            Go
          </button>
        </div>
      </Row>
    </div>
  );
};

export default LeftPanel;
