import React, { useState, useEffect } from "react";
import Header from "./MainHeader/Header";
import Sidebar from "./Sidebar/Sidebar";
import RightPanel from "./MainPanel/Main";
// import { Redirect } from "react-router";
import { useAuth } from "../contexts/AuthContext";
// import { Link, useHistory } from "react-router-dom"

const MainScreen = () => {
	// const [error, setError] = useState("");
	// const { currentUser, logout } = useAuth();
	// const history = useHistory()
	const [kits, setKits] = useState([]);
	const [kitValue, setKitValue] = useState("Plugo");

	useEffect(() => {
		fetch("https://2b03ggq1we.execute-api.us-east-2.amazonaws.com/getKits/")
			.then((response) => response.json())
			.then((data) => {
				setKits(Object.keys(data.from));
				console.log(data.from);
			});
	}, []);

	let changeValue = (e) => {
		setKitValue(e.value);
	};

	const [timePeriod, setTimePeriod] = useState("Last 7 days");
	const changeTimePeriod = (val) => {
		setTimePeriod(val);
		console.log("HELOOOO");
	};
	const [listLength, setListLength] = useState(20);
	return (
		<>
			<div style={{ display: "flex", boxSizing: "border-box" }}>
				{/* <Row> */}
				<div
					style={{
						borderRight: "2px solid #DDE7F1",
						width: "14%",
						position: "fixed",
						top: "0",
						bottom: "0",
						backgroundColor: "white",
						zIndex: "99",
						padding: 0,
						margin: 0,
						boxSizing: "border-box",
					}}
				>
					<Sidebar defaultDate={timePeriod} changedate={changeTimePeriod} />
				</div>

				<div
					style={{
						marginLeft: "14vw",
						height: "100vh",
						width: "86vw",
						display: "flex",
						flexDirection: "column",
						boxSizing: "border-box",
						alignItems: "center",
					}}
				>
					<Header
						kits={kits}
						kitValue={kitValue}
						changeValue={changeValue}
						listLength={listLength}
					/>

					<RightPanel
						kitValue={kitValue}
						timePeriod={timePeriod}
						listLength={listLength}
						setListLength={setListLength}
					/>
				</div>
			</div>
		</>
	);
};

export default MainScreen;
