import React, { useState, useEffect,  } from 'react';
import {Container, Row, Col} from 'react-bootstrap'
import FunnelPanel from './Funnel/Funnel';
import InsightPanel from './Insight/Insight'
import DropOffPanel from './DropOff/DropOff'


const DashPanel = ({id}) => {
    document.addEventListener('mouseup', function(e) {
		var container = document.getElementById('game-main-dropdown-' + id);
		if (container && !container.contains(e.target)) {
			container.style.display = 'none';
		}
	});
    const [selectedFeature, setSelectedFeature] = useState(id === 0 ? "Distribution" : "Insight")
    // const [featureList, setFeatureList] = useState([]);
    const [gameKitList, setGameKitList] = useState();

    // const getFeatureSelectionData = async () => {
	// 	const featureSelectionURL = "https://0u7lfceho4.execute-api.us-east-2.amazonaws.com/FeatureSelection"
	// 	const response = await fetch(featureSelectionURL)
	// 	const responseJSON = await response.json();
	// 	setFeatureList(responseJSON.result)
	// }

    const onFeatureTypeChangeEvent = (selectedFeature) => {
        setSelectedFeature(selectedFeature)
    }
    
    const getKitsData = async () => {
        const kitSelectionURL =
          "https://2b03ggq1we.execute-api.us-east-2.amazonaws.com/getKits";
        const response = await fetch(kitSelectionURL);
        const responseJSON = await response.json();
        setGameKitList(responseJSON.from);
      }

    useEffect( async () => {
        // await getFeatureSelectionData();
        await getKitsData();
    }, [])

    return (
        <Container style={{ border:"2px solid rgb(207 204 204 / 47%)", borderRadius:"20px", height:"460px" }}>

            {
            selectedFeature === "Insight" ? <InsightPanel key={id} id={id} featureTypeChanged={onFeatureTypeChangeEvent} /> : 
            selectedFeature === "Funnel" ? <FunnelPanel key={id} id={id} featureTypeChanged={onFeatureTypeChangeEvent} /> : 
            selectedFeature === "Distribution" ? <DropOffPanel key={id} id={id} featureTypeChanged={onFeatureTypeChangeEvent} gameKits={gameKitList}/> : ""
            }
        </Container>
    )
};

export default DashPanel;