import React, { useEffect, useRef, useState } from "react";
import ReportEmptyIcon from "../../../assets/reports/report-empty-icon.svg";
import { Row, Col } from "react-bootstrap";
import Select, { components } from "react-select";
import Switch from "react-switch";
import "./rightPanel.scss";
import ChartPanel2 from "../../../Dashboard/MainPanel/ChartPanel2/ChartPanel2";
import axios from "axios";
import StringToColor from "string-to-color";
import { nanoid } from "nanoid";
import SyncLoader from "react-spinners/PulseLoader";
import { css } from "@emotion/react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import { DateRange } from "react-date-range";
const RightPanel = ({ queryData, savedEventData }) => {
  const bucketStyle = {
    control: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: "white",
      width: "18rem",
      border: "0.5px solid rgba(0, 0, 0, 0.3)",
      borderRadius: "10px",
      height: "30px",
      overflow: "wrap ",
      fontSize: "12px",
      marginBottom: "32px",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 10,
      overflow: "wrap",
      fontSize: "13px",
      width: "16rem",
      borderRadius: "20px",
      padding: "10px",
    }),

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#bb6bd9" : "white",
        color: isSelected ? "white" : "black",
        borderRadius: "10px",
        marginBottom: "5px",
        padding: "8px",
        fontSize: "12px",
        fontWeight: "500",
      };
    },
  };

  const override = css`
    position: absolute;
    left: 58%;
    top: 40%;
    z-index: 999;
  `;
  let [loading, setLoading] = useState(false);
  const insightResponseRef = useRef();
  const [customSelectionState, setCustomSelectionState] = useState("Custom");
  const [customIsOpen, setCustomIsOpen] = useState("hiddenDateRange");
  const [selectionDateRange, setSelectionDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [chartType, setChartType] = useState("bar");
  const [chartData, setChartData] = useState();
  const [displayingChart, setDisplayingChart] = useState(false);
  const chartRef = useRef();
  const [finalQueryData, setFinalQueryData] = useState({});
  const selectedDateIdRef = useRef("date-range-7D");
  const [switchCheckStatus, setSwitchCheckStatus] = useState(false);
  const switchStatusRef = useRef(false);
  const dateRangeOptions = [
    // "Today",
    "Yesterday",
    "7D",
    "30D",
    "3M",
    "6M",
    "12M",
    "24M",
    "Custom",
  ];

  const formatQueryDataForInsight = () => {
    const [from_date, to_date] = formatDate(selectedDateIdRef.current);
    let chartyQueryData = !queryData ? savedEventData : queryData;
    let finalQueryData = chartyQueryData.map((eventQueryData) => {
      eventQueryData["from_date"] = from_date;
      eventQueryData["to_date"] = to_date;
      return eventQueryData;
    });
    setFinalQueryData(finalQueryData);
    let reportData = {};
    reportData["reportType"] = "Funnel";
    reportData["queryData"] = finalQueryData;
    localStorage.setItem("reportData", JSON.stringify(reportData));
    return finalQueryData;
  };

  const getInsightResponse = async (eventInsightQuery) => {
    return new Promise((resolve, reject) => {
      const insightQueryAPI =
        "https://asuse3algl.execute-api.us-east-2.amazonaws.com/insightFurnace/v2";
      axios({
        method: "post",
        url: insightQueryAPI,
        data: eventInsightQuery, //qs.stringify(selectionData),
        headers: { "Content-Type": "application/json" },
      })
        .then(function (response) {
          // setLoading(false);
          console.log("insight query response received: ");
          console.log(response);
          if (response.status == 200) {
            console.log("RESOLVING - ", {
              response: response.data.response,
            });
            resolve(response.data.response);
          } else {
            alert("insight api request failed!");
            reject("error");
          }
        })
        .catch((error) => {
          // setLoading(false);
          console.log("error in insight query!");
          console.log(error);
          alert("Error in insight query, please validate selected options");
          reject(error);
        });
    });
  };
  useEffect(() => {
    if (savedEventData) {
      const fetchFromSaved = true;
      // generateInsightChart(fetchFromSaved);
    }
  }, [savedEventData]);
  useEffect(async () => {
    if (queryData) {
      // generateInsightChart();
      const [from_date, to_date] = formatDate(selectedDateIdRef.current);
      queryData["from_date"] = from_date;
      queryData["to_date"] = to_date;
      const funnelResponse = await getFunnelResponse(queryData);
      console.log("funnel response - ", funnelResponse);
      const allEvents = [];
      Object.entries(queryData["event_data"]).forEach((event) => {
        const [key, value] = event;
        allEvents.push(Object.keys(value)[0]);
      });
      const chartData = prepareChartDataForFunnel(funnelResponse, allEvents);
      console.log("chart data - ", chartData);
      setDisplayingChart(false);
      setChartData(chartData);
      setDisplayingChart(true);
    }
  }, [queryData]);

  const getFunnelResponse = async (funnelSelectionData) => {
    return new Promise((resolve, reject) => {
      const allEvents = [];
      setLoading(true);
      Object.entries(funnelSelectionData.event_data).forEach((event) => {
        // console.log("event ------ ", event);
        const [key, value] = event;
        // const [eventName, eventVal] = value;
        // console.log("value - ", eventName);
        allEvents.push(Object.keys(value)[0]);
      });

      const funnelQueryAPI =
        "https://ftn79mlg7b.execute-api.us-west-1.amazonaws.com/insight/funnel_furnance_api";
      axios({
        method: "post",
        url: funnelQueryAPI,
        data: funnelSelectionData, //qs.stringify(selectionData),
        headers: { "Content-Type": "application/json" },
      })
        .then(function (response) {
          setLoading(false);
          console.log("funnel query response received: ");
          console.log(response);
          if (response.status == 200) {
            resolve(response.data.response);
          } else {
            alert("funnel api request failed!");
            reject(null);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log("error in funnel query!");
          console.log(error);
          alert("Error in funnel query, please validate selected options");
          reject(null);
        });
    });
  };

  const prepareChartDataForFunnel = (funnelQueryResponse, allEvents) => {
    if (queryData["breakdown"] === "") {
      let xAxis = [];
      let yAxis = [];
      funnelQueryResponse.forEach((d) => {
        xAxis.push(d["Funnel"]);
        yAxis.push(d["converted"]);
      });
      // convert into perecentages
      const topValue = yAxis[0];
      const absoluteValues = yAxis;
      const labels = [];
      for (let index = 0; index < absoluteValues.length; index++) {
        const value = absoluteValues[index];
        const event = allEvents[index];
        labels.push(event + " - " + value);
      }
      yAxis = yAxis.map((val) => Number((val / topValue) * 100).toFixed(2));
      const ds = {
        labels: labels,
        datasets: [
          {
            label: "converted(%)",
            backgroundColor: "rgba(114, 74, 207,0.9)",
            hoverBackgroundColor: "rgba(114, 74, 207,1)",
            hoverBorderColor: "rgba(100, 194, 245,0.7)",
            fill: true,
            fillColor: "black",
            borderRadius: 8,
            data: yAxis,
          },
        ],
      };
      return ds;
    } else {
      // for breakdown funnel query
      const bgColorsArr = [
        "#7856FF",
        "#FF7557",
        "#80E1D9",
        "#F8BC3B",
        "#B2596E",
      ];
      console.log("for breakdown funnel query");
      const breakdown = queryData["breakdown"];
      const breakdownToValuesMap = new Map();
      funnelQueryResponse.forEach((d) => {
        if (!breakdownToValuesMap.has(d[breakdown]))
          breakdownToValuesMap.set(d[breakdown], []);
        breakdownToValuesMap.get(d[breakdown]).push(d["converted"]);
      });
      console.log("breakdownToValuesMap - ", breakdownToValuesMap);
      const datasetArr = [];
      let index = 0;
      for (let [key, value] of breakdownToValuesMap) {
        datasetArr.push({
          label: key,
          backgroundColor: bgColorsArr[index % bgColorsArr.length],
          fill: true,
          fillColor: "black",
          borderRadius: 8,
          data: value,
        });
        index++;
      }
      console.log("datasetArr - ", datasetArr);
      const ds = {
        labels: allEvents,
        datasets: datasetArr,
      };
      return ds;
    }
  };

  useEffect(() => {
    document.getElementById(selectedDateIdRef.current).style.fontWeight = "800";
  }, []);
  const handleDateRangeSelected = async (event) => {
    setCustomIsOpen("hiddenDateRange");
    setCustomSelectionState("Custom");
    document.getElementById("date-range-Custom").textContent = "Custom";
    document.getElementById(selectedDateIdRef.current).style.fontWeight = "500";
    selectedDateIdRef.current = event.target.id;
    document.getElementById(event.target.id).style.fontWeight = "800";
    if (!queryData && savedEventData) {
      // generateInsightChart(true);
    } else {
      // generateInsightChart();
      if (queryData) {
        const [from_date, to_date] = formatDate(selectedDateIdRef.current);
        queryData["from_date"] = from_date;
        queryData["to_date"] = to_date;
        const funnelResponse = await getFunnelResponse(queryData);
        console.log("funnel response - ", funnelResponse);
        const allEvents = [];
        Object.entries(queryData["event_data"]).forEach((event) => {
          const [key, value] = event;
          allEvents.push(Object.keys(value)[0]);
        });
        const chartData = prepareChartDataForFunnel(funnelResponse, allEvents);
        console.log("chart data - ", chartData);
        setDisplayingChart(false);
        setChartData(chartData);
        setDisplayingChart(true);
      }
    }
  };

  const formatDate = (date) => {
    let fromDate = new Date();
    let toDate = new Date();
    if (date.includes("7D")) {
      fromDate.setDate(fromDate.getDate() - 7);
    } else if (date.includes("30D")) {
      fromDate.setMonth(fromDate.getMonth() - 1);
    } else if (date.includes("3M")) {
      fromDate.setMonth(fromDate.getMonth() - 3);
    } else if (date.includes("6M")) {
      fromDate.setMonth(fromDate.getMonth() - 6);
    } else if (date.includes("12M")) {
      fromDate.setMonth(fromDate.getMonth() - 12);
    } else if (date.includes("24M")) {
      fromDate.setMonth(fromDate.getMonth() - 24);
    } else if (date.includes("Today")) {
      fromDate = toDate;
    } else if (date.includes("Yesterday")) {
      fromDate.setDate(fromDate.getDate() - 1);
      toDate.setDate(toDate.getDate() - 1);
    } else if (date.includes("Custom")) {
      fromDate = selectionDateRange.startDate.toLocaleString().split(",")[0];
      toDate = selectionDateRange.endDate.toLocaleString().split(",")[0];
      let year = fromDate.split("/")[2];
      let month = fromDate.split("/")[1];
      let day = fromDate.split("/")[0];
      fromDate = year + "-" + month + "-" + day;
      year = toDate.split("/")[2];
      month = toDate.split("/")[1];
      day = toDate.split("/")[0];
      toDate = year + "-" + month + "-" + day;
      return [fromDate, toDate];
    } else {
      fromDate = "";
      toDate = "";
    }
    if (fromDate !== "")
      return [
        fromDate.getFullYear() +
          "-" +
          String(fromDate.getMonth() + 1).padStart(2, "0") +
          "-" +
          String(fromDate.getDate()).padStart(2, "0"),
        toDate.getFullYear() +
          "-" +
          String(toDate.getMonth() + 1).padStart(2, "0") +
          "-" +
          String(toDate.getDate()).padStart(2, "0"),
      ];
    else return ["", ""];
  };

  const handleCustomButtonClicked = async () => {
    document.getElementById(selectedDateIdRef.current).style.fontWeight = "500";
    selectedDateIdRef.current = "date-range-Custom";
    document.getElementById(selectedDateIdRef.current).style.fontWeight = "800";
    if (customSelectionState === "Custom") {
      setCustomSelectionState("Apply Range");
      document.getElementById("date-range-Custom").textContent = "Apply Range";
      setCustomIsOpen("visibleDateRange");
    } else {
      setCustomSelectionState("Custom");

      let fromDate = selectionDateRange.startDate;
      let toDate = selectionDateRange.endDate;
      document.getElementById("date-range-Custom").textContent =
        fromDate.toLocaleString().split(",")[0] +
        " - " +
        toDate.toLocaleString().split(",")[0];
      setCustomIsOpen("hiddenDateRange");
      const [from_date, to_date] = formatDate(selectedDateIdRef.current);
      if (queryData) {
        queryData["from_date"] = from_date;
        queryData["to_date"] = to_date;
        const funnelResponse = await getFunnelResponse(queryData);
        console.log("funnel response - ", funnelResponse);
        const allEvents = [];
        Object.entries(queryData["event_data"]).forEach((event) => {
          const [key, value] = event;
          allEvents.push(Object.keys(value)[0]);
        });
        const chartData = prepareChartDataForFunnel(funnelResponse, allEvents);
        console.log("chart data - ", chartData);
        setDisplayingChart(false);
        setChartData(chartData);
        setDisplayingChart(true);
      }
    }
  };
  const handleCustomDateRangeSelect = (ranges) => {
    console.log(ranges);
    if ("range1" in ranges) {
      setSelectionDateRange({
        startDate: ranges.range1.startDate,
        endDate: ranges.range1.endDate,
        // key: "selection",
      });
    } else {
      setSelectionDateRange({
        startDate: ranges.selection.startDate,
        endDate: ranges.selection.endDate,
        // key: "selection",
      });
    }
  };

  return (
    <>
      <Row
        style={{
          width: "100%",
          border: "1px solid #DFDFDF",
          height: "500px",
          borderRadius: "24px",
          flexDirection: "column",
        }}>
        <Col
          lg="12"
          style={{ display: "flex", marginTop: "10px", marginBottom: "10px" }}>
          <Col lg="9" style={{ position: "relative" }}>
            {dateRangeOptions.map((option, index) => {
              const additionClass =
                index === dateRangeOptions.length - 1
                  ? "date-range-last-btn"
                  : index === 0
                  ? "date-range-first-btn"
                  : "";
              return (
                <button
                  className={"date-range-btn " + additionClass}
                  id={"date-range-" + option}
                  onClick={
                    option === "Custom"
                      ? handleCustomButtonClicked
                      : handleDateRangeSelected
                  }>
                  {option}
                </button>
              );
            })}
            <DateRange
              ranges={[selectionDateRange]}
              onChange={handleCustomDateRangeSelect}
              className={customIsOpen}
              maxDate={new Date()}
            />
          </Col>

          <Col
            lg="3"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}></Col>
        </Col>
        <hr />
        {displayingChart ? (
          <Col
            lg="12"
            style={{
              height: "410px",
            }}>
            <ChartPanel2
              ref={chartRef}
              id={nanoid()}
              data={chartData}
              chartType={chartType}
            />
            <SyncLoader
              color="#bb6bd9"
              id={"panel-loader"}
              loading={loading}
              css={override}
              size={18}
              margin={8}
            />
          </Col>
        ) : (
          <Col
            lg="12"
            style={{
              height: "410px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <img src={ReportEmptyIcon} alt="" />
            <SyncLoader
              color="#bb6bd9"
              id={"panel-loader"}
              loading={loading}
              css={override}
              size={18}
              margin={8}
            />
          </Col>
        )}
      </Row>
    </>
  );
};

export default RightPanel;
