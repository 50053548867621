import React, { useContext, useState, useEffect } from "react";
import { auth } from "../firebase";
import axios from "axios";

const AuthContext = React.createContext();

export function useAuth() {
	return useContext(AuthContext);
}

export function AuthProvider({ children }) {
	const [currentUser, setCurrentUser] = useState();
	const [loading, setLoading] = useState(true);

	function signup(email, password) {
		return auth.createUserWithEmailAndPassword(email, password);
	}

	function login(email, password) {
		return auth.signInWithEmailAndPassword(email, password);
	}

	function logout() {
		return auth.signOut();
	}
	/*function resetPassword(email) {
    return auth.sendPasswordResetEmail(email)
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email)
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password)
  }*/
	useEffect(() => {
		mainData();
	}, []);
	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			setCurrentUser(user);
			setLoading(false);
		});

		return unsubscribe;
	}, []);

	const [initialTableData, setInitialTableData] = useState("");
	let mainData = () => {
		let data = {
			event_data: { "": { Infilter: "", Infilter_val: "", datatype: "", equator: "" } },
			platform: "Plugo",
			kit: "",
			range: "Last 7 days",
			limit: "40",
		};
		setInitialTableData("");
		axios({
			method: "post",
			url: "https://bdntd9w2sg.execute-api.us-east-2.amazonaws.com/liveView",
			data: data,
			headers: { "Content-Type": "application/json" },
		})
			.then((response) => {
				console.log("FINAL VIEW", Object.values(response.data.response));
				setInitialTableData(Object.values(response.data.response));
				// return response;
			})
			.catch((error) => {
				console.log("EROORRRRRRRRRR", error.message);
			});
	};

	const value = {
		currentUser,
		login,
		// signup,
		logout,
		initialTableData,
		// resetPassword,
		// updateEmail,
		// updatePassword
	};

	return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}
