import React from "react";
import styling from "./EachData.module.scss";
import ReactTooltip from "react-tooltip";

export default function EachData({ data }) {
	return (
		<div className={styling.EachData}>
			<div className={styling.EachDataNext}>
				{Object.keys(data).map((each, index) => (
					<div className={styling.row}>
						<span className={styling.title}>{each} :</span>{" "}
						<span data-tip={data[each]} className={styling.data}>
							{String(data[each]).slice(0, 25)}
						</span>
						<ReactTooltip />
					</div>
				))}
			</div>
		</div>
	);
}
