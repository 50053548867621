import React, { useState, useEffect, useRef } from "react";
import styling from "./Main.module.scss";
import EachData from "./EachData/EachData";
import Filter from "./Filter/Filter";
import tableCloseIcon from "../../assets/tableClose.svg";
import tableOpenIcon from "../../assets/tableOpen.svg";
import tableCrossIcon from "../../assets/tableCross.svg";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { cloneDeep } from "lodash";
import axios from "axios";
import { nanoid } from "nanoid";
import { useAuth } from "../../contexts/AuthContext";
import { height } from "@mui/system";

export default function Main({ kitValue, timePeriod, listLength, setListLength }) {
	const { initialTableData } = useAuth();
	const [hideShow, setHideShow] = useState(false);
	const [showDetails, setShowDetails] = useState(false);
	let toggleData = (index) => {
		index === false ? setShowDetails(false) : setShowDetails(index);
	};

	const [eventsData, setEventsData] = useState([]);
	const [selectedKit, setSelectedKit] = useState("");
	//  Filter each data
	const stepListRef = useRef([]);
	let [loading, setLoading] = useState(false);
	const [dropdownPanels, setDropdownPanels] = useState([{ id: nanoid(), value: "" }]);
	const panelsRef = useRef([...dropdownPanels]);

	// console.log("HELLLLLLOOOOOOOOOOOOO", stepListRef);
	// search getSelectedData

	function getEventsData(kitName) {
		setEventsData([]);
		let kit = kitName;
		setSelectedKit(kit);
		if (kit.includes("Orboot")) {
			if (kit.includes("Earth")) kit = "OrbootE";
			else kit = "OrbootD";
		}
		const getEventsAPI =
			"https://5nbim5vr4j.execute-api.us-west-1.amazonaws.com/prod/events-all-games?kit=" +
			kit;
		setLoading(true);
		axios({
			method: "get",
			url: getEventsAPI,
			headers: { "Content-Type": "application/json" },
		})
			.then((response) => {
				setLoading(false);
				const data = cloneDeep(response.data);
				let selectionObjects = data.map(function (val) {
					return { value: val, label: val };
				});
				selectionObjects = [{ value: "", label: "All Event" }, ...selectionObjects];
				setEventsData({ steps: selectionObjects });
				// console.log("selectionObjects", selectionObjects);
				// allAvailableEventsRef.current = selectionObjects;
			})
			.catch((err) => {
				setLoading(false);
				console.log("error in get events!!");
				console.log(err);
			});
	}
	useEffect(() => {
		getEventsData(kitValue);
	}, [kitValue, timePeriod]);

	function handleRemoveSelection(id) {
		console.log("remove clicked - " + id);
		console.log(dropdownPanels);
		const newArr = dropdownPanels.filter((d) => d.id !== id);
		panelsRef.current = cloneDeep(newArr);
		setDropdownPanels(newArr);
	}

	function addFunnelStepSelector() {
		const newArr = [...panelsRef.current, { id: nanoid(), value: "" }];
		setDropdownPanels(newArr);
		panelsRef.current = cloneDeep(newArr);
	}

	// Main data fetch
	const [tableData, setTableData] = useState("");
	// useEffect(() => {
	// 	setTableData(initialTableData);
	// }, [initialTableData]);
	useEffect(() => {
		setTableData("");
		console.log(kitValue);
		if (kitValue === "Plugo" && timePeriod === "Last 7 days") {
			setTableData(initialTableData);
		}
		if (kitValue !== "Plugo" || (kitValue === "Plugo" && timePeriod !== "Last 7 days")) {
			let platform = kitValue;
			let kit = "";

			if (kitValue.includes("Orboot")) {
				platform = "Orboot";
				kit = kitValue;
			}
			let data = {
				event_data: { "": { Infilter: "", Infilter_val: "", datatype: "", equator: "" } },
				platform: platform,
				kit: kit,
				range: timePeriod,
				limit: "40",
			};
			axios({
				method: "post",
				url: "https://bdntd9w2sg.execute-api.us-east-2.amazonaws.com/liveView",
				data: data,
				headers: { "Content-Type": "application/json" },
			})
				.then((response) => {
					console.log("VIEW", Object.values(response.data.response));
					// setInitialTableData(Object.values(response.data.response));
					setTableData(Object.values(response.data.response));
					// return response;
				})
				.catch((error) => {
					console.log("EROORRRRRRRRRR", error.message);
				});
		}
	}, [kitValue, initialTableData, timePeriod]);
	// let mainData = () => {
	// 	let data = {
	// 		event_data: { "": { Infilter: "", Infilter_val: "", datatype: "", equator: "" } },
	// 		platform: "Plugo",
	// 		kit: "",
	// 		range: "Last 7 days",
	// 		limit: "20",
	// 	};
	// 	axios({
	// 		method: "post",
	// 		url: "https://bdntd9w2sg.execute-api.us-east-2.amazonaws.com/liveView",
	// 		data: data,
	// 		headers: { "Content-Type": "application/json" },
	// 	})
	// 		.then((response) => {
	// 			console.log("FINAL VIEW", Object.values(response.data.response));
	// 			setTableData(Object.values(response.data.response));
	// 			// return response;
	// 		})
	// 		.catch((error) => {
	// 			console.log("EROORRRRRRRRRR", error.message);
	// 		});
	// };
	// useEffect(() => {
	// 	mainData();

	function getDatabeforeSubmit() {
		setTableData("");
		const eventsObject = {};
		const allProperties = [{}];
		const allFilters = [{}];
		const allSelectedValues = [{}];
		const isFilterAppliedList = [{}];
		for (let index = 0; index < stepListRef.current.length; index++) {
			console.log("for index: " + index);
			if (stepListRef.current[index] == null) continue;
			const selectedData = stepListRef.current[index].getSelectedData();
			console.log("selectedData", selectedData);
			isFilterAppliedList.push({
				id: selectedData.id,
				isFilterApplied: selectedData.isFilter,
			});
			// formatting filter applied value array as required by the api
			if (selectedData.isFilter) {
				allFilters.push({
					id: selectedData.id,
					filters: selectedData.allFilters,
				});
				allProperties.push({
					id: selectedData.id,
					properties: selectedData.allProperties,
				});
				allSelectedValues.push({
					id: selectedData.id,
					selectedStep: selectedData.selectedStep,
					selectedProperty: selectedData.selectedProperty,
					selectedFilters: selectedData.selectedFilters,
				});
				const filters = selectedData.filter;
				let formattedFilterVal = filters;
				if (selectedData.operator.includes("is")) {
					if (
						selectedData.datatype &&
						selectedData.datatype === "string" &&
						selectedData.operator &&
						selectedData.operator.includes("is")
					) {
						formattedFilterVal = "'" + filters.join("','") + "'";
					} else if (selectedData.datatype && selectedData.datatype === "number") {
						if (selectedData.operator && selectedData.operator.includes("between"))
							formattedFilterVal = '"' + filters.join(",") + '"';
						else formattedFilterVal = filters.join(",");
					}
					eventsObject[selectedData.selectedStep] = {
						Infilter: selectedData.property ? selectedData.property.value : "",
						Infilter_val: formattedFilterVal ? formattedFilterVal : "",
						datatype: selectedData.datatype ? selectedData.datatype : "",
						equator: selectedData.operator ? selectedData.operator : "",
					};
				} else {
					console.log("correct path... value found in between");
					let selectedFilter = filters;
					if (selectedData.datatype === "number") {
						// check if it is whole number or decimal
						if (selectedFilter % 1 != 0) selectedFilter = parseFloat(filters);
						else selectedFilter = parseInt(filters);
					}

					eventsObject[selectedData.selectedStep] = {
						Infilter: selectedData.property ? selectedData.property.value : "",
						Infilter_val: selectedFilter,
						datatype: selectedData.datatype ? selectedData.datatype : "",
						equator: selectedData.operator ? selectedData.operator : "",
					};
				}
			} else {
				console.log("going in the without filter area...");
				eventsObject[selectedData.selectedStep] = {
					Infilter: "",
					Infilter_val: "",
					datatype: "",
					equator: "",
				};
				allSelectedValues.push({
					id: selectedData.id,
					selectedStep: selectedData.selectedStep,
				});
			}
		}
		// console.log("is filter applied list object now");
		// console.log(isFilterAppliedList);
		let platform = kitValue;
		let kit = "";
		if (kitValue.includes("Orboot")) {
			platform = "Orboot";
			kit = kitValue;
		}
		if (Object.keys(eventsObject).length) {
			let data = {
				event_data: eventsObject,
				platform: platform,
				kit: kit,
				range: timePeriod,
				limit: "40",
			};
			axios({
				method: "post",
				url: "https://bdntd9w2sg.execute-api.us-east-2.amazonaws.com/liveView",
				data: data,
				headers: { "Content-Type": "application/json" },
			})
				.then((response) => {
					// console.log("last one", response.data.response);
					setTableData(Object.values(response.data.response));
					// return response;
				})
				.catch((error) => {
					console.log("EROORRRRRRRRRR", error.message);
				});
		}
	}
	// const [wholeList, setWholeList] = useState(false);

	return (
		<div className={styling.Main}>
			{/* Header section */}
			{/* <Filter kitValue={kitValue} /> */}
			<div className={styling.headSection}>
				<div className={styling.headTitle}>Select Event</div>
				<div className={styling.mainFilterData}>
					<div className={`${styling.filter} ${hideShow ? styling.filterAdded : ""}`}>
						{dropdownPanels.map((m, index) => (
							<Filter
								ref={(el) => (stepListRef.current[index] = el)}
								key={m.id}
								index={index}
								id={m.id}
								selectedValue={m.value}
								data={eventsData}
								remove={handleRemoveSelection}
								kit={selectedKit}
							/>
						))}
					</div>
					<div
						className={styling.addEvent}
						onClick={() => {
							if (hideShow === true) {
								addFunnelStepSelector();
							}
							if (hideShow === false) {
								setHideShow(true);
							}
						}}
					>
						<div className={styling.plusIcon}>+</div>
						<div className={styling.addText}>Add event</div>
					</div>
				</div>
				<div
					className={`${styling.filter} ${hideShow ? styling.filterAdded : ""}`}
					style={{
						height: "100%",
						// border: "1px solid red",
						alignSelf: "flex-end",
						paddingBottom: "7px",
					}}
				>
					<div className={styling.goButton} onClick={getDatabeforeSubmit}>
						Go
					</div>
				</div>
			</div>

			<div className={styling.tableSection}>
				{tableData.length ? (
					<table className={styling.table}>
						<tr className={styling.headingRow}>
							<th className={`${styling.tableHtext} ${styling.serialNo}`}>
								<img src={tableCrossIcon} alt="serial" />
							</th>
							{Object.keys(tableData[0].Panel1).map((each, i) => (
								<th
									key={i}
									className={`${styling.tableHtext} ${
										Object.keys(tableData[0].Panel1).length === i + 1
											? styling.lastHeading
											: ""
									}`}
								>
									{each}
								</th>
							))}
						</tr>
						{tableData.slice(0, listLength).map((each, idx) => (
							<>
								<tr className={styling.contentRow}>
									<td
										className={`${styling.tableData} ${styling.serialData}`}
										// onClick={() => toggleData(showDetails ? false : idx + 1)}
									>
										{showDetails === idx + 1 ? (
											<div
												className={styling.toggleButtonDiv}
												onClick={() => toggleData(false)}
											>
												<img src={tableOpenIcon} alt="openIcon" />
											</div>
										) : (
											<div
												className={styling.toggleButtonDiv}
												onClick={() => toggleData(idx + 1)}
											>
												<img src={tableCloseIcon} alt="closeIcon" />
											</div>
										)}
									</td>
									{Object.values(each.Panel1).map((data, index) => (
										<td className={styling.tableData}>{data || "NA"}</td>
									))}
								</tr>
								{showDetails === idx + 1 ? (
									<tr>
										<td colSpan="6" style={{ height: "50px" }}>
											<EachData data={each.Panel2} />
										</td>
									</tr>
								) : null}
							</>
						))}
					</table>
				) : (
					<div className={styling.loader}>
						{tableData === "" ? (
							<div className={styling.loaderCircle}></div>
						) : (
							<p className={styling.loaderText}>List is empty</p>
						)}
					</div>
				)}
			</div>
			<div className={styling.footer}>
				{listLength < 40 ? (
					<div
						className={styling.footerButton}
						onClick={() => setListLength(listLength + 20)}
					>
						Show more results
					</div>
				) : (
					<div className={styling.footerButton}>Showing all 40 results</div>
				)}
			</div>
		</div>
	);
}
