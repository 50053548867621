import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import Badge from "react-bootstrap/Badge";
import Select, { components } from "react-select";
import ClearIcon from "@mui/icons-material/Clear";
const FilterDropdown = forwardRef(
  (
    { id, index, removeBreakdown, breakdownOptions, savedBreakdownData },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      getBreakdownValue() {
        return selectedBreakdown.value;
      },
    }));
    const breakdownStyle = {
      control: (styles, { isFocused }) => ({
        ...styles,
        backgroundColor: "white",
        width: "15rem",
        border: "0px solid #D3D3D3",
        borderRadius: "8px",
        overflow: "wrap ",
        fontSize: "11px",
        //   marginRight: "5px",
        marginLeft: "0.2rem",
        //   textAlignLast: "center",
      }),

      singleValue: (provided) => ({
        ...provided,
        color: "black",
        fontWeight: "600",
      }),

      menu: (base) => ({
        ...base,
        zIndex: 10,
        overflow: "wrap",
        fontSize: "14px",
        width: "14rem",
        borderRadius: "8px",
        padding: "4px",
        textAlignLast: "center",
      }),

      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isSelected ? "#bb6bd9" : "white",
          color: isSelected ? "white" : "black",
          borderRadius: "5px",
          marginBottom: "5px",
          padding: "4px",
          fontSize: "12px",
        };
      },
    };
    useEffect(() => {
      console.log("breakdown option changed - ", breakdownOptions);
    }, [breakdownOptions]);
    const [selectedBreakdown, setSelectedBreakdown] =
      useState(savedBreakdownData);
    const handleBreakdownChange = (e) => {
      setSelectedBreakdown(e);
    };
    return (
      <div
        style={{
          border: "1px solid rgba(202,202,202,0.47)",
          borderRadius: "10px",
          padding: "4px",
          marginBottom: "6px",
          marginTop: "10px",
        }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            //   marginBottom: "4px",
          }}>
          <Select
            id={"filter-selector-" + id}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            closeMenuOnSelect
            styles={breakdownStyle}
            value={selectedBreakdown}
            options={breakdownOptions}
            onChange={handleBreakdownChange}
            menuPlacement="auto"
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
          />
          <ClearIcon
            className="clear-icon-btn"
            onClick={() => removeBreakdown(id)}
          />
        </div>
      </div>
    );
  }
);

export default FilterDropdown;
