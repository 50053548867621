import React from "react";
// import Row from "react-bootstrap/Row";
import styling from "./Sidebar.module.scss";
import furnaceLogo from "../../assets/FrameNew.svg";
import LiveView from "../../assets/LiveViewPurple.svg";
import TableDictionary from "../../assets/DictionaryGrey.svg";
import DashboardGreyLogo from "../../assets/DashboardGrey.svg";
import shifuLogo from "../../assets/playshifu-logo.png";
import { Link } from "react-router-dom";
import ReportsGreyIcon from "../../assets/reports_grey_icon.svg";

const Sidebar = ({ defaultDate, changedate }) => {
	return (
		<div className={styling.Sidebar}>
			
			<div className={styling.iconRow}>
				<img src={furnaceLogo} style={{ width: "100%" }} alt="shifuLogo" />
			</div>
			<div className={styling.tabSection}>
			<div className={styling.navLink}>
					<div className={styling.logo}>
						<img src={ReportsGreyIcon} style={{ height: "13px", color:"red" }} alt="Reports Page" />
					</div>
					<div className={styling.linkDiv}>
						<Link to="/Reports/Insight" className={styling.sidepanelLink} style={{  }}>
							Reports
						</Link>
					</div>
				</div>
				<div className={styling.navLink}>
					<div className={styling.logo}>
						<img src={DashboardGreyLogo} style={{ height: "70%" }} alt="dashboard" />
					</div>
					<div className={styling.linkDiv}>
						<Link to="/" className={styling.sidepanelLink}>
							Dashboard
						</Link>
					</div>
				</div>
				<div className={styling.navLink}>
					<div className={styling.logo}>
						<img src={TableDictionary} style={{ height: "70%" }} alt="dashboard" />
					</div>
					<div className={styling.linkDiv}>
						<Link to="/tabledictionary" className={styling.sidepanelLink}>
							Table Dictionary
						</Link>
					</div>
				</div>
				<div className={styling.navLink}>
					<div className={styling.logo}>
						<img src={LiveView} style={{ height: "70%" }} alt="dashboard" />
					</div>
					<div className={styling.linkDiv}>
						<Link to="/liveview" className={styling.sidepanelLinkActive}>
							Live View
						</Link>
					</div>
				</div>
			</div>
			<div className={styling.FilterSection}>
				{/* <div className={styling.eachFilter}>Custome Date</div> */}
				<div
					onClick={() => changedate("1 hour")}
					className={`${styling.eachFilter} ${
						defaultDate === "1 hour" ? styling.filterSelected : ""
					}`}
				>
					Last 1 hour
				</div>
				<div
					onClick={() => changedate("Today")}
					className={`${styling.eachFilter} ${
						defaultDate === "Today" ? styling.filterSelected : ""
					}`}
				>
					Today
				</div>
				<div
					onClick={() => changedate("Yesterday")}
					className={`${styling.eachFilter} ${
						defaultDate === "Yesterday" ? styling.filterSelected : ""
					}`}
				>
					Yesterday
				</div>
				<div
					onClick={() => changedate("Last 7 days")}
					className={`${styling.eachFilter} ${
						defaultDate === "Last 7 days" ? styling.filterSelected : ""
					}`}
				>
					Last 7 days
				</div>
				{/* <div className={styling.eachFilter}>6 Months</div> */}

				{/* <div className={styling.goButton}>Go</div> */}
			</div>
			<div className={styling.shifuLogo}>
				<img style={{ width: "100%", height: "auto" }} src={shifuLogo} alt="shifulogo" />
			</div>
		</div>
	);
};

export default Sidebar;
