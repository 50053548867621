import React, { useState } from "react";
import './TableDictionary.scss'
import Header from "./Header/Header";
import LeftPanel from "./LeftPanel/LeftPanel";
import RightPanel from "./RightPanel/RightPanel";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
// import { Redirect } from "react-router";
import { useAuth } from "../contexts/AuthContext"
// import { Link, useHistory } from "react-router-dom"

const TableDictionary = ( ) => {
    const [error, setError] = useState("")
    const { currentUser, logout } = useAuth()
    // const history = useHistory()

	return (
    
        <div style={{ display: "flex", boxSizing: "border-box" }}>
        
        <div
          style={{
            borderRight: "2px solid #DDE7F1",
            width: "14%",
            position: "fixed",
            top: "0",
            bottom: "0",
            backgroundColor: "white",
            zIndex: "99",
            padding: 0,
            margin: 0,
            boxSizing: "border-box",
          }}>
            
                    <LeftPanel/>
                    </div>
                <Col  style={{ marginLeft:"12vw", marginTop:"10vh"}}>                   
                    <Row >
                        <Col sm={12} lg={12} xl={12} >
                        <RightPanel/>
                        </Col>
                    </Row>   
                </Col>

            
        </div>
        
        
    );
};

export default TableDictionary;
