import React, { useState, useEffect } from "react";
import styling from "./Header.module.scss";
import Select from "react-select";

export default function Header({ kits, kitValue, changeValue, listLength }) {
	const [formatKits, setFormatKits] = useState([]);
	const [currentValue, setCurrentValue] = useState("");
	useEffect(() => {
		let labelValue = kitValue;
		if (labelValue.includes("Orboot")) {
			labelValue = labelValue.split("_").join(" ");
		}

		setCurrentValue({ label: labelValue, value: kitValue });
	}, [kitValue]);

	useEffect(() => {
		let arr = [];
		if (kits) {
			kits.map((each) => {
				if (each === "Orboot") {
					arr.push({ value: "Orboot_Dino", label: "Orboot Dino" });
					arr.push({ value: "Orboot_Earth", label: "Orboot Earth" });
				} else {
					arr.push({ value: each, label: each });
				}
			});
			setFormatKits(arr);
		}
	}, [kits]);
	const filterStyle = {
		control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
			...styles,
			border: "none",
			cursor: "pointer",
			boxShadow: "none",
		}),
		menu: (base) => ({
			...base,
			zIndex: 10,
			overflow: "wrap",
			fontSize: "13px",
			// width: "250px",
			borderRadius: "10px",
			padding: "4px",
			// border: "1px solid red",
		}),
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				color: "black",
				borderRadius: "10px",
				marginBottom: "5px",
				padding: "8px",
				fontSize: "12px",
				// cursor: "pointer",
				backgroundColor: isSelected ? "#bb6bd9" : "white",
				cursor: "pointer",
				"&:hover": {
					backgroundColor: "rgba(187, 107, 217, 0.5)",
				},
			};
		},
	};
	return (
		<div className={styling.Header}>
			<div className={styling.textSection}>
				<div className={styling.heading}>EVENTS</div>
				<div className={styling.title}>
					Showing {listLength} results for {kitValue.split("_").join(" ")}
				</div>
			</div>
			<div className={styling.dropdownSection}>
				<Select
					// defaultValue="strawberry"
					options={formatKits}
					components={{
						// DropdownIndicator: () => null,
						IndicatorSeparator: () => null,
					}}
					value={currentValue}
					onChange={changeValue}
					styles={filterStyle}
				/>
			</div>
		</div>
	);
}
