import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import "./funnel.scss";
import { nanoid } from "nanoid";
import SyncLoader from "react-spinners/SyncLoader";
import { cloneDeep } from "lodash";
import axios from "axios";
import { css } from "@emotion/react";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import DropdownSelection from "../MainPanel/SelectionPanel/DropdownSelection";
import SettingsIcon from "@mui/icons-material/Settings";
import ChartPanel2 from "../MainPanel/ChartPanel2/ChartPanel2";
import TodayIcon from "@mui/icons-material/Today";
import LegendToggleIcon from "@mui/icons-material/LegendToggle";
import Tooltip from "@mui/material/Tooltip";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const Funnel = ({ id, previousStateData, featureTypeChanged }, ref) => {
  const override = css`
    position: relative;
    left: 45%;
    top: -45%;
    z-index: 999;
  `;
  const [feaureOptions, setFeatureOptions] = useState(["Kit", "Date Range"]);
  const dateRangeOptions = [
    "Last 7 Days",
    "Last 30 Days",
    "This Month",
    "Last 3 Months",
    "Last 6 Months",
    "Lifetime",
  ];
  const [featureTypeOptions, setFeatureTypeOptions] = useState([
    "Insight",
    "Distribution",
    "Funnel",
  ]);

  const [breakdownOptions, setBreakdownOptions] = useState([
    { key: "Breakdown", value: "" },
  ]);
  const bucketDropdownOptions = [{ Bucket: [1, 2, 5, 10, 20, 50] }];
  const [selectedBucket, setSelectedBucket] = useState(1);

  const kitOptions = [
    { Plugo: ["Plugo"] },
    { Tacto: ["Tacto"] },
    { Orboot: ["Orboot Earth", "Orboot Dino"] },
  ];
  const [displayingChart, setDisplayingChart] = useState(false);
  const [chartData, setChartData] = useState("");
  const [chartType, setChartType] = useState("barFunnel");
  const [dropdownPanels, setDropdownPanels] = useState([
    { id: nanoid(), value: "" },
  ]);

  const [selectedBreakdown, setSelectedBreakdown] = useState("");
  const selectedBreakdownRef = useRef("");

  const [headerLine1, setHeaderLine1] = useState("");
  const [headerLine2, setHeaderLine2] = useState("");
  const allAvailableEventsRef = useRef(
    previousStateData ? previousStateData.all_available_events : ""
  );
  const funnelSelectionDataRef = useRef("");
  const stepListRef = useRef([]);
  const [eventsData, setEventsData] = useState([]);
  const [selectedKit, setSelectedKit] = useState("");
  const [duplicateEventData, setDuplicatedEventData] = useState("");
  let [loading, setLoading] = useState(false);
  function handleRemoveSelection(id) {
    console.log("remove clicked - " + id);
    console.log(dropdownPanels);
    const newArr = dropdownPanels.filter((d) => d.id !== id);
    panelsRef.current = cloneDeep(newArr);
    setDropdownPanels(newArr);
  }

  function addInsightStepSelector() {
    const uniqueId = nanoid();
    const newArr = [...panelsRef.current, { id: uniqueId, value: "" }];
    setDropdownPanels(newArr);
    panelsRef.current = cloneDeep(newArr);
    return uniqueId;
  }

  async function gameOptionSelected(platform, kit, game) {
    const gameAnchor = document.getElementById("games-div-lable-" + id);

    gameAnchor.text = kit;
    getInsightEventsData(kit);
    for (let index = 0; index < stepListRef.current.length; index++) {
      console.log("for index: " + index);
      if (stepListRef.current[index] == null) continue;
      stepListRef.current[index].notifyKitChange(kit);
    }

    document.getElementById("games-platformdata-" + id).value = platform;
    document.getElementById("games-kitdata-" + id).value = kit;
  }

  function getInsightEventsData(kitName) {
    let kit = kitName;
    setSelectedKit(kit);
    if (kit.includes("Orboot")) {
      if (kit.includes("Earth")) kit = "OrbootE";
      else kit = "OrbootD";
    }
    const getEventsAPI =
      "https://5nbim5vr4j.execute-api.us-west-1.amazonaws.com/prod/events-all-games?kit=" +
      kit;
    setLoading(true);
    axios({
      method: "get",
      url: getEventsAPI,
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        setLoading(false);
        const data = cloneDeep(response.data);
        const selectionObjects = data.map(function (val) {
          return { value: val, label: val };
        });
        setEventsData({ steps: selectionObjects });
        allAvailableEventsRef.current = selectionObjects;
      })
      .catch((err) => {
        setLoading(false);
        console.log("error in get events!!");
        console.log(err);
      });
  }
  const panelsRef = useRef([...dropdownPanels]);
  const fillFeatureData = (option) => {
    // console.log("option came for feature data - " + option + "-" + id);
    if (option === "Date Range") {
      return (
        <select
          className="selection-modifier-insight"
          id={"funnel-date-" + id}
          key={"funnel-date-" + id}>
          {dateRangeOptions.map((date) => (
            <option value={date}>{date}</option>
          ))}
        </select>
      );
    } else if (option === "Kit") {
      return (
        <div
          id={"games-div-" + id}
          className="selection-modifier-insight"
          style={{ width: "114px", padding: "4px" }}
          onClick={() => toggleclasses()}>
          <a
            id={"games-div-lable-" + id}
            role="button"
            selected="true"
            className="game-title-a"
            style={{ verticalAlign: "sub" }}>
            Kits
          </a>
          <input type="hidden" id={"games-platformdata-" + id}></input>
          <input type="hidden" id={"games-kitdata-" + id}></input>
          <ul
            className="dropdown-menu"
            id={"game-main-dropdown-" + id}
            role="menu"
            style={{ display: "none" }}>
            {kitOptions.map((kitOption) => {
              const platform = Object.keys(kitOption)[0];
              const kits = Object.values(kitOption)[0];
              return generatePlatformAndKits(platform, kits);
            })}
          </ul>
        </div>
      );
    }
  };

  const generatePlatformAndKits = (platform, kits) => {
    // console.log("kits received ---- ", kits);
    return (
      <li
        className="dropdown-submenu"
        id={"game-option-" + platform + "-" + id}>
        <a
          id={"level-1-" + platform + "-" + id}
          className="dropdown-item"
          tabindex="-1"
          href="#">
          {platform}
        </a>
        <ul
          className="dropdown-menu dropdown-menu-right-open"
          id={"option-level2-" + platform + "-" + id}>
          <div id={"div-level2-" + platform + "-" + id}>
            {kits.map((kit) => {
              const identifier = platform + "-" + kit + "-" + id;
              return (
                <li
                  id={"li-option-level2-" + identifier}
                  onClick={() => gameOptionSelected(platform, kit)}>
                  <a
                    id={"level-2-" + platform + identifier}
                    className="dropdown-item"
                    href="#">
                    {" "}
                    {kit}{" "}
                  </a>
                </li>
              );
            })}
          </div>
        </ul>
      </li>
    );
  };

  const toggleclasses = () => {
    const mainDropdown = document.getElementById("game-main-dropdown-" + id);
    if (mainDropdown) {
      const currentStyle = mainDropdown.style.display;
      mainDropdown.style.display = currentStyle === "block" ? "none" : "block";
    }
  };

  function formatDate(date) {
    let fromDate = new Date();
    let toDate = new Date();
    if (date.includes("7 Days")) {
      fromDate.setDate(fromDate.getDate() - 7);
    } else if (date.includes("30 Days")) {
      fromDate.setMonth(fromDate.getMonth() - 1);
    } else if (date.includes("This Month")) {
      fromDate.setDate(1);
    } else if (date.includes("3 Months")) {
      fromDate.setMonth(fromDate.getMonth() - 3);
    } else if (date.includes("6 Months")) {
      fromDate.setMonth(fromDate.getMonth() - 6);
    } else if (date.includes("Lifetime")) {
      // fromDate = "2020-01-01";
      fromDate.setDate(1);
      fromDate.setMonth(1);
      fromDate.setYear(2020);
    } else {
      fromDate = "";
      toDate = "";
    }
    if (fromDate !== "")
      return [
        fromDate.getFullYear() +
          "-" +
          String(fromDate.getMonth() + 1).padStart(2, "0") +
          "-" +
          String(fromDate.getDate()).padStart(2, "0"),
        toDate.getFullYear() +
          "-" +
          String(toDate.getMonth() + 1).padStart(2, "0") +
          "-" +
          String(toDate.getDate()).padStart(2, "0"),
      ];
    else return ["", ""];
  }

  const validateSelectionBeforeSubmit = async () => {
    // do the validation before submit

    const eventsObject = {};
    const allProperties = [{}];
    const allFilters = [{}];
    const allSelectedValues = [{}];
    const isFilterAppliedList = [{}];
    for (let index = 0; index < stepListRef.current.length; index++) {
      const currentEventObject = {};
      if (stepListRef.current[index] == null) continue;
      const selectedData = stepListRef.current[index].getSelectedData();
      isFilterAppliedList.push({
        id: selectedData.id,
        isFilterApplied: selectedData.isFilter,
      });

      // formatting filter applied value array as required by the api
      if (selectedData.isFilter) {
        allFilters.push({
          id: selectedData.id,
          filters: selectedData.allFilters,
        });
        allProperties.push({
          id: selectedData.id,
          properties: selectedData.allProperties,
        });
        allSelectedValues.push({
          id: selectedData.id,
          selectedStep: selectedData.selectedStep,
          selectedProperty: selectedData.selectedProperty,
          selectedFilters: selectedData.selectedFilters,
        });
        const filters = selectedData.filter;
        let formattedFilterVal = filters;
        if (selectedData.operator.includes("is")) {
          if (
            selectedData.datatype &&
            selectedData.datatype === "string" &&
            selectedData.operator &&
            selectedData.operator.includes("is")
          ) {
            formattedFilterVal = "'" + filters.join("','") + "'";
          } else if (
            selectedData.datatype &&
            selectedData.datatype === "number"
          ) {
            if (
              selectedData.operator &&
              selectedData.operator.includes("between")
            )
              formattedFilterVal = '"' + filters.join(",") + '"';
            else formattedFilterVal = filters.join(",");
          }
          currentEventObject[selectedData.selectedStep] = {
            filter: selectedData.property ? selectedData.property.value : "",
            val: formattedFilterVal ? formattedFilterVal : "",
            datatype: selectedData.datatype ? selectedData.datatype : "",
            equator: selectedData.operator ? selectedData.operator : "",
          };
        } else {
          //   console.log("correct path... value found in between");
          let selectedFilter = filters;
          if (selectedData.datatype === "number") {
            // check if it is whole number or decimal
            if (selectedFilter % 1 != 0) selectedFilter = parseFloat(filters);
            else selectedFilter = parseInt(filters);
          }

          currentEventObject[selectedData.selectedStep] = {
            filter: selectedData.property ? selectedData.property.value : "",
            val: selectedFilter,
            datatype: selectedData.datatype ? selectedData.datatype : "",
            equator: selectedData.operator ? selectedData.operator : "",
          };
        }
      } else {
        // console.log("going in the without filter area...");
        currentEventObject[selectedData.selectedStep] = {
          filter: "",
          val: "",
          datatype: "",
          equator: "",
        };
        allSelectedValues.push({
          id: selectedData.id,
          selectedStep: selectedData.selectedStep,
        });
      }
      eventsObject[index] = currentEventObject;
    }
    // console.log("is filter applied list object now");
    // console.log(isFilterAppliedList);
    // console.log("events object....");
    const date = document.getElementById("funnel-date-" + id).value;
    const platform = document
      .getElementById("games-platformdata-" + id)
      .value.split(" ")
      .join("_");
    const kit = document
      .getElementById("games-kitdata-" + id)
      .value.split(" ")
      .join("_");
    setHeaderLine1(
      "Funnel - " + document.getElementById("games-kitdata-" + id).value
    );
    setHeaderLine2("Date Range - " + date);
    console.log("platfrom - ", platform);
    console.log("kit - ", kit);
    const dateSelected = date;
    const [from_date, to_date] = formatDate(date);
    console.log(eventsObject);
    const funnelSelectionData = {
      event_data: eventsObject,
      platform: platform,
      kit: kit,
      from_date: from_date,
      to_date: to_date,
      did_not: false,
      date_selected: dateSelected,
      dropdown_panels: panelsRef.current,
      all_available_events: allAvailableEventsRef.current,
      all_properties: allProperties,
      all_filters: allFilters,
      all_selected_values: allSelectedValues,
      is_filter_applied_list: isFilterAppliedList,
    };
    console.log(funnelSelectionData);
    funnelSelectionDataRef.current = funnelSelectionData;
    console.log("need to check this ", funnelSelectionDataRef.current);
    const chartData = await getFunnelData(funnelSelectionData);
    setChartData(chartData);
    // console.log("got the chart data!!!");
    // console.log(chartData);
    // generateChart(funnelSelectionData);
    setDisplayingChart(true);

    // submitSelection(selectionData);
  };

  const getFunnelData = async (funnelSelectionData) => {
    return new Promise((resolve, reject) => {
      const allEvents = [];
      setLoading(true);
      Object.entries(funnelSelectionData.event_data).forEach((event) => {
        // console.log("event ------ ", event);
        const [key, value] = event;
        // const [eventName, eventVal] = value;
        // console.log("value - ", eventName);
        allEvents.push(Object.keys(value)[0]);
      });

      const funnelQueryAPI =
        "https://s3ba1pjc51.execute-api.us-east-2.amazonaws.com/furnaceFunnel/v2";
      axios({
        method: "post",
        url: funnelQueryAPI,
        data: funnelSelectionData, //qs.stringify(selectionData),
        headers: { "Content-Type": "application/json" },
      })
        .then(function (response) {
          setLoading(false);
          console.log("funnel query response received: ");
          console.log(response);
          if (response.status == 200) {
            const chartData = prepareChartDataForFunnel(
              response.data.response,
              allEvents
            );
            resolve(chartData);
          } else {
            alert("funnel api request failed!");
            reject(null);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log("error in funnel query!");
          console.log(error);
          alert("Error in funnel query, please validate selected options");
          reject(null);
        });
    });
  };
  const prepareChartDataForFunnel = (funnelQueryResponse, allEvents) => {
    let xAxis = [];
    let yAxis = [];
    funnelQueryResponse.forEach((d) => {
      xAxis.push(d["Funnel"]);
      yAxis.push(d["converted"]);
    });
    // convert into perecentages
    const topValue = yAxis[0];
    const absoluteValues = cloneDeep(yAxis);
    const labels = [];
    for (let index = 0; index < absoluteValues.length; index++) {
      const value = absoluteValues[index];
      const event = allEvents[index];
      labels.push(event + " - " + value);
    }
    yAxis = yAxis.map((val) => Number((val / topValue) * 100).toFixed(2));
    const ds = {
      labels: labels,
      datasets: [
        {
          label: "converted(%)",
          backgroundColor: "rgba(114, 74, 207,0.9)",
          // borderColor: 'black',
          // borderWidth: 1,
          hoverBackgroundColor: "rgba(114, 74, 207,1)",
          hoverBorderColor: "rgba(100, 194, 245,0.7)",
          fill: true,
          fillColor: "black",
          borderRadius: 8,
          data: yAxis,
        },
      ],
    };
    return ds;
  };

  const onBackToSelectionClicked = () => {
    setDuplicatedEventData("");
    setDisplayingChart(false);
  };

  const handleDuplicateEvent = (data) => {
    console.log("dupolicated event called ", data);
    const id = addInsightStepSelector();
    data["id"] = id;
    setDuplicatedEventData(data);
  };

  useEffect(async () => {
    if (!displayingChart) {
      let index = 0;
      // setDropdownPanels(previousStateData.dropdown_panels)

      const eventsArray = [];

      if (funnelSelectionDataRef.current.event_data) {
        document.getElementById("games-platformdata-" + id).value =
          funnelSelectionDataRef.current.platform;
        document.getElementById("games-kitdata-" + id).value =
          funnelSelectionDataRef.current.kit;

        document.getElementById("games-div-lable-" + id).text =
          funnelSelectionDataRef.current.kit;

        setSelectedKit(funnelSelectionDataRef.current.kit);
        document.getElementById("funnel-date-" + id).value =
          funnelSelectionDataRef.current?.date_selected;
        Object.entries(funnelSelectionDataRef.current.event_data).forEach(
          (eventData) => {
            const [key, value] = eventData;
            eventsArray.push(key);
          }
        );

        console.log("for previous data ,", funnelSelectionDataRef.current);
        setEventsData({
          steps: funnelSelectionDataRef.current.all_available_events,
          allFilters: funnelSelectionDataRef.current.all_filters,
          allProperties: funnelSelectionDataRef.current.all_properties,
          allSelectedValues: funnelSelectionDataRef.current.all_selected_values,
          isFilterAppliedList:
            funnelSelectionDataRef.current.is_filter_applied_list,
        });

        console.log("events data updated, ", funnelSelectionDataRef.current);
        console.log("events data received - ");
        const newPanels = [];

        funnelSelectionDataRef.current.dropdown_panels.forEach((panel) => {
          newPanels.push({ id: panel.id, value: eventsArray[index++] });
        });

        console.log("new panels, ", newPanels);
        setDropdownPanels(newPanels);
        panelsRef.current = newPanels;
      }
    }
  }, [displayingChart]);
  return (
    <>
      {/* header part */}
      {displayingChart ? (
        //   on displaying chart panel

        <>
          <Row style={{ height: "74px" }}>
            {/*<Col
              lg="10"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}>
              <span className="chart-header-span">{headerLine1}</span>
            </Col>
            <Col lg="2" style={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip title="Back to selection">
                <SettingsIcon
                  style={{
                    fontSize: "28px",
                    color: "#9d9d9dbf",
                    alignSelf: "center",
                  }}
                  onClick={onBackToSelectionClicked}
                />
              </Tooltip>
            </Col>*/}
            <Col
              lg="2"
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0",
              }}>
              <Tooltip title="Back to selection">
                <ArrowBackIcon
                  style={{
                    marginLeft: "10px",
                    fontSize: "28px",
                    color: "#C27ADD",
                    cursor: "pointer",
                  }}
                  onClick={onBackToSelectionClicked}
                />
              </Tooltip>
            </Col>
            <Col
              lg="7"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "13px",
              }}>
              <span
                className="chart-header-span"
                style={{ fontSize: "1.07rem" }}>
                {headerLine1}
              </span>
            </Col>
            <Col lg="3">
              <select
                className="selection-modifier-insight"
                id={"insight-breakdown-" + id}
                key={"insight-breakdown-" + id}
                // onChange={breakdownChanged}
              >
                {breakdownOptions.map((breakdown) => (
                  <option value={breakdown.value}>{breakdown.key}</option>
                ))}
              </select>
            </Col>
          </Row>

          <Row>
            <hr />
          </Row>

          <Row
            style={{
              //   border: "2px solid",
              height: "335px",
              width: "auto",
              marginTop: "-14px",
            }}>
            <ChartPanel2 id={id} data={chartData} chartType={chartType} />
          </Row>
          <Row>
            <Col style={{ marginTop: "8px" }}>
              <span
                style={{
                  border: "2px solid rgba(207, 204, 204, 0.47)",
                  borderRadius: "10px",
                  fontWeight: "500",
                  padding: "5px",
                  fontSize: "0.75rem",
                  color: "#828282",
                  backgroundColor: "#F6F6F6",
                }}>
                <TodayIcon style={{ color: "#6c757da6", height: "16px" }} />
                {funnelSelectionDataRef.current?.from_date} {" ~ "}
                {funnelSelectionDataRef.current?.to_date} {"  "}
              </span>
            </Col>
          </Row>
        </>
      ) : (
        //   on displying selection panel
        <>
          <Row
            style={{
              display: "flex",
              flexDirection: "row",
            }}>
            <Col
              style={{
                display: "flex",
                flexDirection: "row",
              }}>
              {feaureOptions.map(fillFeatureData)}
            </Col>
            <Col style={{ display: "flex", justifyContent: "flex-end" }}>
              <select
                name=""
                id=""
                className="featureType-selection"
                onChange={(e) => featureTypeChanged(e.target.value)}>
                {featureTypeOptions.map((feautureType) => {
                  return feautureType === "Funnel" ? (
                    <option value={feautureType} selected>
                      {feautureType}
                    </option>
                  ) : (
                    <option value={feautureType}>{feautureType}</option>
                  );
                })}
              </select>
            </Col>
          </Row>
          <Row>
            <hr />
          </Row>
          {/* body part */}
          <Row style={{ height: "295px" }}>
            <div className="steps-selector-wrapper">
              {dropdownPanels.map((m, index) => (
                <DropdownSelection
                  ref={(el) => (stepListRef.current[index] = el)}
                  key={m.id}
                  index={index}
                  id={m.id}
                  selectedValue={m.value}
                  data={eventsData}
                  remove={handleRemoveSelection}
                  kit={selectedKit}
                  sendDuplicatedEvent={handleDuplicateEvent}
                  duplicateEventData={duplicateEventData}
                  triggerLoading={(val) => setLoading(val)}
                />
              ))}
              <div style={{ display: "flex", alignItems: "center" }}>
                <AddCircleOutlineRoundedIcon
                  id={"add-step-icon-" + id}
                  onClick={() => addInsightStepSelector()}
                  style={{
                    width: "30px",
                    height: "30px",
                    marginTop: "10px",
                    cursor: "pointer",
                    color: "#bb6bd9",
                  }}
                />
                <span
                  style={{
                    color: "#bb6bd9",
                    marginLeft: "10px",
                    marginTop: "10px",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                  onClick={() => addInsightStepSelector()}>
                  Add Event
                </span>
              </div>
            </div>
          </Row>
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <button
              className="go-button-funnel"
              onClick={validateSelectionBeforeSubmit}>
              Go
            </button>
          </Row>
          <Row>
            {chartData ? (
              <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                <Tooltip title="Back to chart!">
                  <LegendToggleIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => setDisplayingChart(true)}
                  />
                </Tooltip>
              </Col>
            ) : (
              ""
            )}
          </Row>
          <SyncLoader
            color="#e09e53"
            id={"panel-" + id + "-loader"}
            loading={loading}
            css={override}
            size={15}
          />
        </>
      )}
    </>
  );
};
export default Funnel;
