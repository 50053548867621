import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import "./insight.scss";
import { nanoid } from "nanoid";
import SyncLoader from "react-spinners/SyncLoader";
import { cloneDeep } from "lodash";
import axios from "axios";
import { css } from "@emotion/react";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import DropdownSelection from "../MainPanel/SelectionPanel/DropdownSelection";
import ChartPanel2 from "../MainPanel/ChartPanel2/ChartPanel2";
import Tooltip from "@mui/material/Tooltip";
import SettingsIcon from "@mui/icons-material/Settings";
import LegendToggleIcon from "@mui/icons-material/LegendToggle";
import TodayIcon from "@mui/icons-material/Today";
import forwardArrow from "../../assets/forward-arrow.svg";
import chartTypeIcon from "../../assets/chartType_icon.svg";
import dayIcon from "../../assets/day_icon.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Select, { components } from "react-select";
const Insight = (
  { id, previousStateData, featureTypeChanged, submitSelection },
  ref
) => {
  const override = css`
    position: relative;
    left: 45%;
    top: -45%;
    z-index: 999;
  `;
  const [feaureOptions, setFeatureOptions] = useState(["Kit", "Date Range"]);

  const breakdownJSONData = require("./BreakdownJSON.json");
  // console.log(breakdownJSONData);
  const [consolidator, setConsolidator] = useState("daily");
  const [dataFormatLabel, setDataFormatLabel] = useState("Linear");
  const dataFormatRef = useRef("Linear");
  const insightTypeArrRef = useRef("");
  const insightResponseRef = useRef([]);
  const [metricViewOptions, setMetricViewOptions] = useState([]);
  const [isMetricView, setMetricView] = useState(false);
  const eventNamesRef = useRef([]);

  const settingsDropdownOptions = [
    { ChartType: ["Line", "Metric"] },
    { Day: ["Day", "Month", "Year"] },
  ];

  const dateRangeOptions = [
    "Last 7 Days",
    "Last 30 Days",
    "This Month",
    "Last 3 Months",
    "Last 6 Months",
    "Lifetime",
  ];
  const [featureTypeOptions, setFeatureTypeOptions] = useState([
    "Insight",
    "Distribution",
    "Funnel",
  ]);
  const chartRef = useRef();
  const insightSelectionDataRef = useRef("");
  const [displayingChart, setDisplayingChart] = useState(false);
  const [chartData, setChartData] = useState("");

  const [insightChartType, setInsightChartType] = useState("line");
  const [insightConsoliator, setInsightConsoliator] = useState("Day");
  const [chartType, setChartType] = useState(
    "insight_line-" + insightConsoliator.toLowerCase()
  );
  document.addEventListener("mouseup", function (e) {
    var container = document.getElementById("game-main-dropdown-insight-" + id);
    if (container && !container.contains(e.target)) {
      container.style.display = "none";
    }
  });

  const kitOptions = [
    { Plugo: ["Plugo"] },
    { Tacto: ["Tacto"] },
    { Orboot: ["Orboot Earth", "Orboot Dino"] },
  ];

  const [selectedOption, setSelectedOption] = useState({
    label: "Total",
    value: "Count Total",
  });
  const [displayOptionProperties, setDisplayOptionProperties] = useState(false);
  const optionTypeList = [
    { label: "Total", value: "Count Total" },
    { label: "Unique", value: "Count Unique" },
    { label: "Sum - Agg. per user", value: "sum" },
    { label: "Min - Agg. per user", value: "min" },
    { label: "Max - Agg. per user", value: "max" },
    { label: "Average - Agg. per user", value: "avg" },
    { label: "Distinct - Agg. per user", value: "distinct count" },
  ];
  const [breakdownOptions, setBreakdownOptions] = useState([
    { key: "Breakdown", value: "" },
  ]);
  const [dropdownPanels, setDropdownPanels] = useState([
    { id: nanoid(), value: "" },
  ]);
  const allAvailableEventsRef = useRef(
    previousStateData ? previousStateData.all_available_events : ""
  );
  const stepListRef = useRef([]);
  const [eventsData, setEventsData] = useState([]);
  const [selectedKit, setSelectedKit] = useState("");
  const [duplicateEventData, setDuplicatedEventData] = useState("");
  let [loading, setLoading] = useState(false);
  function handleRemoveSelection(id) {
    console.log("remove clicked - " + id);
    console.log(dropdownPanels);
    const newArr = dropdownPanels.filter((d) => d.id !== id);
    panelsRef.current = cloneDeep(newArr);
    setDropdownPanels(newArr);
  }

  function addInsightStepSelector() {
    const uniqueId = nanoid();
    const newArr = [...panelsRef.current, { id: uniqueId, value: "" }];
    setDropdownPanels(newArr);
    panelsRef.current = cloneDeep(newArr);
    return uniqueId;
  }

  async function gameOptionSelected(platform, kit, game) {
    const gameAnchor = document.getElementById("games-div-lable-" + id);

    gameAnchor.text = kit;
    getInsightEventsData(kit);
    setSelectedKit(kit);

    // set breakdown option list for the selected kit
    const breakdownOptionArr = [{ key: "Breakdown", value: "" }];
    Object.entries(breakdownJSONData).forEach((breakdownData) => {
      const [jsonKit, jsonBreakdownOptions] = breakdownData;
      if (jsonKit === kit) {
        console.log("break ", jsonBreakdownOptions);
        Object.entries(jsonBreakdownOptions).forEach((options) => {
          // console.log("options,", options);
          breakdownOptionArr.push({ key: options[0], value: options[1] });
        });
      }
    });
    setBreakdownOptions(breakdownOptionArr);

    for (let index = 0; index < stepListRef.current.length; index++) {
      console.log("for index: " + index);
      if (stepListRef.current[index] == null) continue;
      stepListRef.current[index].notifyKitChange(kit);
    }

    document.getElementById("games-platformdata-" + id).value = platform;
    document.getElementById("games-kitdata-" + id).value = kit;
  }

  function getInsightEventsData(kitName) {
    let kit = kitName;
    setSelectedKit(kit);
    if (kit.includes("Orboot")) {
      if (kit.includes("Earth")) kit = "OrbootE";
      else kit = "OrbootD";
    }
    const getEventsAPI =
      "https://5nbim5vr4j.execute-api.us-west-1.amazonaws.com/prod/events-all-games?kit=" +
      kit;
    setLoading(true);
    axios({
      method: "get",
      url: getEventsAPI,
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        setLoading(false);
        const data = cloneDeep(response.data);
        const selectionObjects = data.map(function (val) {
          return { value: val, label: val };
        });
        setEventsData({ steps: selectionObjects });
        allAvailableEventsRef.current = selectionObjects;
      })
      .catch((err) => {
        setLoading(false);
        console.log("error in get events!!");
        console.log(err);
      });
  }
  const panelsRef = useRef([...dropdownPanels]);
  const fillFeatureData = (option) => {
    // console.log("option came for feature data - " + option + "-" + id);
    if (option === "Date Range") {
      return (
        <select
          className="selection-modifier-insight"
          id={"insight-date-" + id}
          key={"insight-date-" + id}>
          {dateRangeOptions.map((date) =>
            date.includes("30") ? (
              <option value={date} selected>
                {date}
              </option>
            ) : (
              <option value={date}>{date}</option>
            )
          )}
        </select>
      );
    } else if (option === "Breakdown") {
      return (
        <select
          className="selection-modifier-insight"
          id={"insight-breakdown-" + id}
          key={"insight-breakdown-" + id}>
          {breakdownOptions.map((breakdown) => (
            <option value={breakdown.value}>{breakdown.key}</option>
          ))}
        </select>
      );
    } else if (option === "Kit") {
      return (
        <div
          id={"games-div-" + id}
          className="selection-modifier-insight"
          style={{ width: "114px", padding: "4px" }}
          onClick={() => toggleclasses()}>
          <a
            id={"games-div-lable-" + id}
            role="button"
            selected="true"
            className="game-title-a"
            style={{ verticalAlign: "sub" }}>
            Kits
          </a>
          <input type="hidden" id={"games-platformdata-" + id}></input>
          <input type="hidden" id={"games-kitdata-" + id}></input>
          <ul
            className="dropdown-menu"
            id={"game-main-dropdown-" + id}
            role="menu"
            style={{ display: "none" }}>
            {kitOptions.map((kitOption) => {
              const platform = Object.keys(kitOption)[0];
              const kits = Object.values(kitOption)[0];
              return generatePlatformAndKits(platform, kits);
            })}
          </ul>
        </div>
      );
    }
  };

  const generatePlatformAndKits = (platform, kits) => {
    // console.log("kits received ---- ", kits);
    return (
      <li
        className="dropdown-submenu"
        id={"game-option-" + platform + "-" + id}>
        <a
          id={"level-1-" + platform + "-" + id}
          className="dropdown-item"
          tabindex="-1"
          href="#">
          {platform}
        </a>
        <ul
          className="dropdown-menu dropdown-menu-right-open"
          id={"option-level2-" + platform + "-" + id}>
          <div id={"div-level2-" + platform + "-" + id}>
            {kits.map((kit) => {
              const identifier = platform + "-" + kit + "-" + id;
              return (
                <li
                  id={"li-option-level2-" + identifier}
                  onClick={() => gameOptionSelected(platform, kit)}>
                  <a
                    id={"level-2-" + platform + identifier}
                    className="dropdown-item"
                    href="#">
                    {" "}
                    {kit}{" "}
                  </a>
                </li>
              );
            })}
          </div>
        </ul>
      </li>
    );
  };

  //   const gameOptionSelected = (platform, kit) => {
  //     const gameAnchor = document.getElementById("games-div-lable-" + id);
  //     if (gameAnchor) {
  //       gameAnchor.text = kit;
  //     }
  //   };
  const toggleclasses = () => {
    const mainDropdown = document.getElementById("game-main-dropdown-" + id);
    if (mainDropdown) {
      const currentStyle = mainDropdown.style.display;
      mainDropdown.style.display = currentStyle === "block" ? "none" : "block";
    }
  };

  const validateSelectionBeforeSubmit = async () => {
    // do the validation before submit

    const eventsObject = {};
    const allProperties = [{}];
    const allFilters = [{}];
    const allSelectedValues = [{}];
    const isFilterAppliedList = [{}];
    const insightTypeArr = [];
    for (let index = 0; index < stepListRef.current.length; index++) {
      if (stepListRef.current[index] == null) continue;
      const selectedData = stepListRef.current[index].getSelectedData();

      insightTypeArr.push({
        agg_per_user: selectedData.agg_per_user,
        events_performed_type: selectedData.events_performed_type,
      });

      isFilterAppliedList.push({
        id: selectedData.id,
        isFilterApplied: selectedData.isFilter,
      });
      // formatting filter applied value array as required by the api
      if (selectedData.isFilter) {
        allFilters.push({
          id: selectedData.id,
          filters: selectedData.allFilters,
        });
        allProperties.push({
          id: selectedData.id,
          properties: selectedData.allProperties,
        });
        allSelectedValues.push({
          id: selectedData.id,
          selectedStep: selectedData.selectedStep,
          selectedProperty: selectedData.selectedProperty,
          selectedFilters: selectedData.selectedFilters,
        });
        const filters = selectedData.filter;
        let formattedFilterVal = filters;
        if (selectedData.operator.includes("is")) {
          if (
            selectedData.datatype &&
            selectedData.datatype === "string" &&
            selectedData.operator &&
            selectedData.operator.includes("is")
          ) {
            formattedFilterVal = "'" + filters.join("','") + "'";
          } else if (
            selectedData.datatype &&
            selectedData.datatype === "number"
          ) {
            if (
              selectedData.operator &&
              selectedData.operator.includes("between")
            )
              formattedFilterVal = '"' + filters.join(",") + '"';
            else formattedFilterVal = filters.join(",");
          }
          eventsObject[selectedData.selectedStep] = {
            0: {
              filter: selectedData.property ? selectedData.property.value : "",
              val: formattedFilterVal ? formattedFilterVal : "",
              datatype: selectedData.datatype ? selectedData.datatype : "",
              equator: selectedData.operator ? selectedData.operator : "",
            },
          };
        } else {
          //   console.log("correct path... value found in between");
          let selectedFilter = filters;
          if (selectedData.datatype === "number") {
            // check if it is whole number or decimal
            if (selectedFilter % 1 != 0) selectedFilter = parseFloat(filters);
            else selectedFilter = parseInt(filters);
          }

          eventsObject[selectedData.selectedStep] = {
            0: {
              filter: selectedData.property ? selectedData.property.value : "",
              val: selectedFilter,
              datatype: selectedData.datatype ? selectedData.datatype : "",
              equator: selectedData.operator ? selectedData.operator : "",
            },
          };
        }
      } else {
        // console.log("going in the without filter area...");
        eventsObject[selectedData.selectedStep] = {
          0: {
            filter: "",
            val: "",
            datatype: "",
            equator: "",
          },
        };
        allSelectedValues.push({
          id: selectedData.id,
          selectedStep: selectedData.selectedStep,
        });
      }
    }
    insightTypeArrRef.current = insightTypeArr;
    console.log("insight type ---- ", insightTypeArr);
    const date = document.getElementById("insight-date-" + id).value;
    const platform = document
      .getElementById("games-platformdata-" + id)
      .value.split(" ")
      .join("_");
    const kit = document
      .getElementById("games-kitdata-" + id)
      .value.split(" ")
      .join("_");

    console.log("platfrom - ", platform);
    console.log("kit - ", kit);
    const dateSelected = date;
    const [from_date, to_date] = formatDate(date);

    console.log(eventsObject);
    const insightSelectionData = {
      event_data: eventsObject,
      platform: platform,
      kit: kit,
      from_date: from_date,
      to_date: to_date,
      date_selected: dateSelected,
      dropdown_panels: panelsRef.current,
      all_available_events: allAvailableEventsRef.current,
      all_properties: allProperties,
      all_filters: allFilters,
      all_selected_values: allSelectedValues,
      is_filter_applied_list: isFilterAppliedList,
      consolidate: consolidator,
      breakdown: "",
    };
    console.log(insightSelectionData);
    insightSelectionDataRef.current = insightSelectionData;

    generateChartForInsight();
  };

  const generateChartForInsight = async () => {
    setLoading(true);
    let lablesArr = [];
    let datasetsArr = [];
    const eventEntries = Object.entries(
      insightSelectionDataRef.current.event_data
    );

    const insightResponseArr = [];
    let index = 0;
    for await (const event of eventEntries) {
      //   console.log("this is one event - ", event);
      const insightResponse = await getInsightQueryResponse(
        insightSelectionDataRef.current,
        event,
        insightTypeArrRef.current[index]
      );
      index++;
      const eventName = cloneDeep(event[0]);
      eventNamesRef.current.push(eventName);
      insightResponseArr.push(insightResponse);
    }

    console.log("insightResponseArr - ", insightResponseArr);
    insightResponseRef.current = cloneDeep(insightResponseArr);
    insightResponseArr.forEach((res) => {
      const chartData = prepareChartDataForInsight(res.event);
      console.log("chartdata", chartData);
      if (Array.isArray(chartData)) {
        chartData.forEach((cData) => {
          lablesArr.push.apply(lablesArr, cData.label);
          datasetsArr.push(cData.dataset);
        });
      } else {
        lablesArr.push.apply(lablesArr, chartData.label);
        datasetsArr.push(chartData.dataset);
      }
    });

    console.log("insght response ref, ", insightResponseRef.current);
    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    const uniqueLables = lablesArr.filter(onlyUnique);
    uniqueLables.sort();
    const ds = {
      labels: uniqueLables,
      datasets: datasetsArr,
    };
    console.log("ds generated - ", ds);
    setLoading(false);
    setChartData(ds);
    setChartType("insight_line-" + insightConsoliator.toLowerCase());
    chartRef.current?.updateChart(ds);
    setDisplayingChart(true);
  };
  const prepareChartDataForInsight = (eventName) => {
    let data = "";

    for (let index = 0; index < insightResponseRef.current.length; index++) {
      //   console.log("insightResponseRef - ", insightResponseRef.current[index]);
      if (insightResponseRef.current[index] == null) continue;
      if (insightResponseRef.current[index].event === eventName) {
        data = insightResponseRef.current[index].response;
        console.log("what cought - ", insightResponseRef.current[index]);
        break;
      }
    }

    const isBreakdownApplied =
      insightSelectionDataRef.current.breakdown === "" ? false : true;
    if (isBreakdownApplied) {
      // for breakdown added insight
      const datasets = [];
      // console.log("generateChartForInsight, data - ", data);
      const yAxes = [];

      let ySum = 0;
      Object.entries(data.distributed_time).forEach((dsEntries) => {
        console.log("each dsentry: ", dsEntries);
        const labels = [];
        const datapoints = [];
        console.log("DS - ", dsEntries[0], ", vals: ", dsEntries[1]);
        const key = dsEntries[0];
        const value = dsEntries[1];
        // const [key, value] = dsEntries;
        const count = value.count;
        const time = value.time;
        ySum = 0;
        if (dataFormatRef.current === "Cummulative") {
          value.forEach((data) => {
            ySum += data.count;
            datapoints.push({ x: data.time, y: ySum });
            labels.push(data.time);
          });
        } else {
          value.forEach((data) => {
            datapoints.push({ x: data.time, y: data.count });
            labels.push(data.time);
          });
        }
        const randomColor = stringToColour(eventName + "/" + key);
        datasets.push({
          dataset: {
            label: eventName + " / " + key,
            borderColor: randomColor,
            borderWidth: 2,
            hoverBackgroundColor: randomColor,
            hoverBorderColor: randomColor,
            tension: 0.2,
            data: datapoints,
          },
          label: labels,
        });
        console.log("datasets,", datasets);
      });

      return datasets;
    } else {
      // for non-breakdown insight
      const datasets = [];
      // console.log("generateChartForInsight, data - ", data);
      const yAxes = [];
      const labels = [];
      const datapoints = [];
      let ySum = 0;
      Object.entries(data.distributed_time).forEach((dsEntries) => {
        const [key, value] = dsEntries;
        const count = value.count;
        const time = value.time;
        ySum += count;
        if (dataFormatRef.current === "Cummulative") {
          datapoints.push({ x: time, y: ySum });
          labels.push(time);
        } else {
          datapoints.push({ x: time, y: count });
          labels.push(time);
        }
      });

      const randomColor = stringToColour(eventName);
      return {
        dataset: {
          label: eventName,
          borderColor: randomColor,
          borderWidth: 2,
          hoverBackgroundColor: randomColor,
          hoverBorderColor: randomColor,
          tension: 0.2,
          data: datapoints,
        },
        label: labels,
      };
    }
  };
  function formatDate(date) {
    let fromDate = new Date();
    let toDate = new Date();
    if (date.includes("7 Days")) {
      fromDate.setDate(fromDate.getDate() - 7);
    } else if (date.includes("30 Days")) {
      fromDate.setMonth(fromDate.getMonth() - 1);
    } else if (date.includes("This Month")) {
      fromDate.setDate(1);
    } else if (date.includes("3 Months")) {
      fromDate.setMonth(fromDate.getMonth() - 3);
    } else if (date.includes("6 Months")) {
      fromDate.setMonth(fromDate.getMonth() - 6);
    } else if (date.includes("Lifetime")) {
      fromDate = "";
      toDate = "";
    } else {
      fromDate = "";
      toDate = "";
    }
    if (fromDate !== "")
      return [
        fromDate.getFullYear() +
          "-" +
          String(fromDate.getMonth() + 1).padStart(2, "0") +
          "-" +
          String(fromDate.getDate()).padStart(2, "0"),
        toDate.getFullYear() +
          "-" +
          String(toDate.getMonth() + 1).padStart(2, "0") +
          "-" +
          String(toDate.getDate()).padStart(2, "0"),
      ];
    else return ["", ""];
  }

  const stringToColour = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = "#";
    for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xff;
      colour += ("00" + value.toString(16)).substr(-2);
    }

    return colour;
  };

  const getInsightQueryResponse = async (
    InsightSelectionData,
    event,
    insightType
  ) => {
    return new Promise((resolve, reject) => {
      setLoading(true);
      const payloadData = cloneDeep(InsightSelectionData);
      let newEvent = {};
      newEvent[event[0]] = event[1];
      payloadData["event_data"] = newEvent;
      payloadData["agg_per_user"] = insightType.agg_per_user;
      payloadData["events_performed_type"] = insightType.events_performed_type;
      console.log("payload data - ", payloadData);
      const insightQueryAPI =
        "https://flg1kyenn4.execute-api.us-west-1.amazonaws.com/insight/Insight-agg-peruser-function";
      axios({
        method: "post",
        url: insightQueryAPI,
        data: payloadData, //qs.stringify(selectionData),
        headers: { "Content-Type": "application/json" },
      })
        .then(function (response) {
          setLoading(false);
          console.log("insight query response received: ");
          console.log(response);
          if (response.status == 200) {
            console.log("RESOLVING - ", {
              event: event[0],
              response: response.data.response,
            });
            resolve({ event: event[0], response: response.data.response });
          } else {
            alert("insight api request failed!");
            reject(null);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log("error in insight query!");
          console.log(error);
          alert("Error in insight query, please validate selected options");
          reject(null);
        });
    });
  };

  const updateChartDataFormat = () => {
    // toggeling button
    if (dataFormatLabel === "Linear") {
      setDataFormatLabel("Cummulative");
      dataFormatRef.current = "Cummulative";
    } else {
      setDataFormatLabel("Linear");
      dataFormatRef.current = "Linear";
    }
    let lablesArr = [];
    const datasetsArr = [];

    insightResponseRef.current.forEach((res) => {
      const chartData = prepareChartDataForInsight(res.event);
      if (Array.isArray(chartData)) {
        chartData.forEach((cData) => {
          lablesArr.push.apply(lablesArr, cData.label);
          datasetsArr.push(cData.dataset);
        });
      } else {
        lablesArr.push.apply(lablesArr, chartData.label);
        datasetsArr.push(chartData.dataset);
      }
    });

    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    const uniqueLables = lablesArr.filter(onlyUnique);
    uniqueLables.sort();
    console.log("lablesArr till here....", uniqueLables);
    const ds = {
      labels: uniqueLables,
      datasets: datasetsArr,
    };
    setChartData(ds);
    console.log("ds generated - ", ds);
    chartRef.current?.updateChart(ds);
    // generateChartForDropOff();
  };

  const onBackToSelectionClicked = () => {
    setDuplicatedEventData("");
    setDisplayingChart(false);
  };

  const onConsolidatorChanged = async (e) => {
    setConsolidator(e.target.value);
    insightSelectionDataRef.current.consolidate = e.target.value;
    setLoading(true);
    await generateChartForInsight();
    setLoading(false);
  };
  const chartViewChanged = (e) => {
    if (e.target.value === "Metric") {
      console.log("view changed to metric");
      console.log("insightResponseRef.current , ", insightResponseRef.current);
      setMetricView(true);
    } else {
      console.log("view changed to line");
      setMetricView(false);
    }
  };

  const toggleSettingclasses = () => {
    const mainDropdown = document.getElementById(
      "game-main-dropdown-insight-" + id
    );
    if (mainDropdown) {
      const currentStyle = mainDropdown.style.display;
      mainDropdown.style.display = currentStyle === "block" ? "none" : "block";
    }
  };

  const handleChartTypeChanged = (val) => {
    setInsightChartType(val);
    if (val === "Line") setMetricView(false);
    else setMetricView(true);
  };
  const handleConsolidatorChanged = async (val) => {
    setInsightConsoliator(val);
    const payloadValue =
      val === "Day" ? "daily" : val === "Month" ? "monthly" : "yearly";
    setConsolidator(payloadValue);
    insightSelectionDataRef.current.consolidate = payloadValue;
    setLoading(true);
    await generateChartForInsight();
    setLoading(false);
  };
  const generateSettingsOptions = (platform, kits) => {
    // console.log("kits received ---- ", kits);
    const openClass =
      id % 2 === 0 ? "dropdown-menu-right-open" : "dropdown-menu-left-open";
    const onChangeFunction =
      platform === "ChartType"
        ? handleChartTypeChanged
        : handleConsolidatorChanged;
    return (
      <li
        className="dropdown-submenu dropdown-submenu-settings"
        id={"game-option-" + platform + "-" + id}>
        <div
          id={"level-1-" + platform + "-" + id}
          className="dropdown-item dropdown-item-settings">
          <div style={{ display: "flex" }}>
            <div style={{ width: "158px" }}>
              {platform === "ChartType" ? (
                <img
                  src={chartTypeIcon}
                  alt=""
                  style={{ marginRight: "8px" }}
                />
              ) : (
                <img src={dayIcon} alt="" style={{ marginRight: "8px" }} />
              )}
              <a style={{ fontStyle: "none" }}>
                {platform === "ChartType" ? "ChartType" : insightConsoliator}
              </a>
            </div>

            <div xl="2">
              <img src={forwardArrow} alt="" />
            </div>
          </div>
        </div>
        <ul
          className={"dropdown-menu " + openClass}
          id={"option-level2-" + platform + "-" + id}>
          <div id={"div-level2-" + platform + "-" + id}>
            {kits.map((kit) => {
              const identifier = platform + "-" + kit + "-" + id;
              return (
                <li
                  id={"li-option-level2-" + identifier}
                  onClick={() => onChangeFunction(kit)}>
                  <a
                    id={"level-2-" + platform + identifier}
                    style={{ fontSize: "14px" }}
                    className="dropdown-item"
                    href="#">
                    {" "}
                    {kit}{" "}
                  </a>
                </li>
              );
            })}
          </div>
        </ul>
      </li>
    );
  };

  useEffect(async () => {
    if (!displayingChart) {
      let index = 0;
      // setDropdownPanels(previousStateData.dropdown_panels)

      const eventsArray = [];

      if (insightSelectionDataRef.current.event_data) {
        document.getElementById("games-platformdata-" + id).value =
          insightSelectionDataRef.current.platform;
        document.getElementById("games-kitdata-" + id).value =
          insightSelectionDataRef.current.kit;

        document.getElementById("games-div-lable-" + id).text =
          insightSelectionDataRef.current.kit;

        // document.getElementById("insight-breakdown-" + id).value =
        //   insightSelectionDataRef.current.breakdownval;

        document.getElementById("insight-date-" + id).value =
          insightSelectionDataRef.current.date_selected;
        setSelectedKit(insightSelectionDataRef.current.kit);
        Object.entries(insightSelectionDataRef.current.event_data).forEach(
          (eventData) => {
            const [key, value] = eventData;
            eventsArray.push(key);
          }
        );

        console.log("for previous data ,", insightSelectionDataRef.current);
        setEventsData({
          steps: insightSelectionDataRef.current.all_available_events,
          allFilters: insightSelectionDataRef.current.all_filters,
          allProperties: insightSelectionDataRef.current.all_properties,
          allSelectedValues:
            insightSelectionDataRef.current.all_selected_values,
          isFilterAppliedList:
            insightSelectionDataRef.current.is_filter_applied_list,
        });

        console.log("events data updated, ", insightSelectionDataRef.current);
        console.log("events data received - ");
        const newPanels = [];

        insightSelectionDataRef.current.dropdown_panels.forEach((panel) => {
          newPanels.push({ id: panel.id, value: eventsArray[index++] });
        });

        console.log("new panels, ", newPanels);
        setDropdownPanels(newPanels);
        panelsRef.current = newPanels;

        let indexJ = 0;
        // insightTypeArrRef.current.forEach((insightType) => {
        //   console.log("each insight type - ", insightType);
        //   document.getElementById(
        //     "insightType-selection-" + indexJ + "-1"
        //   ).value = insightType;
        //   indexJ++;
        // });
      }
    } else {
      // document.getElementById("insight-head-TypeSelection" + id).value =
      //   isMetricView ? "Metric" : "Line";
      document.getElementById("insight-breakdown-" + id).value =
        insightSelectionDataRef.current.breakdown;
    }
  }, [displayingChart]);

  const breakdownChanged = (e) => {
    insightSelectionDataRef.current.breakdown = e.target.value;
    generateChartForInsight();
  };

  const handleDuplicateEvent = (data) => {
    console.log("dupolicated event called ", data);
    const id = addInsightStepSelector();
    data["id"] = id;
    setDuplicatedEventData(data);
  };

  return (
    <>
      {displayingChart ? (
        <>
          <Row style={{ height: "74px" }}>
            <Col
              lg="2"
              style={{
                display: "flex",
                // justifyContent: "flex-end",
                alignItems: "center",
                padding: "0",
              }}>
              <Tooltip title="Back to selection">
                <ArrowBackIcon
                  style={{
                    marginLeft: "10px",
                    fontSize: "28px",
                    color: "#C27ADD",
                    cursor: "pointer",
                  }}
                  onClick={onBackToSelectionClicked}
                />
              </Tooltip>
            </Col>

            <Col
              lg="5"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "20px",
              }}>
              <span
                className="chart-header-span"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  marginLeft: "40px",
                  fontSize: "18px",
                  marginBottom: "16px",
                }}>
                {selectedKit}
              </span>
            </Col>

            <Col lg="3">
              <select
                className="selection-modifier-insight"
                id={"insight-breakdown-" + id}
                key={"insight-breakdown-" + id}
                onChange={breakdownChanged}>
                {breakdownOptions.map((breakdown) => (
                  <option value={breakdown.value}>{breakdown.key}</option>
                ))}
              </select>
            </Col>

            <Col lg="2" style={{ display: "flex", justifyContent: "flex-end" }}>
              <ul
                className="dropdown-menu dropdown-menu-settings"
                id={"game-main-dropdown-insight-" + id}
                role="menu"
                style={{ display: "none" }}>
                {settingsDropdownOptions.map((kitOption) => {
                  const platform = Object.keys(kitOption)[0];
                  const kits = Object.values(kitOption)[0];
                  return generateSettingsOptions(platform, kits);
                })}

                <li
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}>
                  <span
                    className="dropdown-item dropdown-item-settings"
                    style={{
                      padding: "0.25rem 0.4rem 0rem 1rem",
                      backgroundColor: "transparent",
                      border: "none",
                      marginBottom: "0",
                      fontSize: "14px",
                    }}>
                    <label
                      id={"data-format-lable-text-" + id}
                      style={{ fontWeight: "500" }}>
                      {dataFormatLabel}
                    </label>
                    <label
                      className="switch"
                      id={"data-format-lable-" + id}
                      style={{ marginLeft: "auto", right: "0" }}>
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        onClick={() => updateChartDataFormat()}
                      />
                      <span className="slider round"></span>
                    </label>
                  </span>
                </li>
              </ul>
              <Tooltip title="Settings">
                <SettingsIcon
                  style={{
                    fontSize: "28px",
                    color: "#9d9d9dbf",
                    alignSelf: "center",
                    cursor: "pointer",
                  }}
                  onClick={toggleSettingclasses}
                />
              </Tooltip>
            </Col>
          </Row>

          <Row>
            <hr />
          </Row>

          <Row
            style={{
              //   border: "2px solid",
              height: "335px",
              width: "auto",
              marginTop: "-14px",
            }}>
            {!isMetricView && chartData ? (
              <ChartPanel2
                ref={chartRef}
                id={id}
                data={chartData}
                chartType={chartType}
              />
            ) : (
              insightResponseRef.current.map((responseRef, index) => {
                return (
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <Col style={{ textAlign: "center" }}>
                      <span className="span-metric-view-1">
                        {responseRef.event}
                      </span>{" "}
                      <br />
                      <span className="span-metric-view-2">
                        {responseRef.response.total_count}
                      </span>{" "}
                      <br />
                      <span>
                        {insightTypeArrRef.current[index] === "unique"
                          ? "users"
                          : "events"}
                      </span>
                    </Col>
                  </Col>
                );
              })
            )}
          </Row>
          <Row>
            <Col style={{ marginTop: "8px" }}>
              <span
                style={{
                  border: "2px solid rgba(207, 204, 204, 0.47)",
                  borderRadius: "10px",
                  fontWeight: "500",
                  padding: "5px",
                  fontSize: "0.75rem",
                  color: "#828282",
                  backgroundColor: "#F6F6F6",
                }}>
                <TodayIcon style={{ color: "#6c757da6", height: "16px" }} />
                {insightSelectionDataRef.current?.from_date} {" ~ "}
                {insightSelectionDataRef.current?.to_date} {"  "}
              </span>
            </Col>
          </Row>
          <SyncLoader
            color="#e09e53"
            id={"panel-" + id + "-loader"}
            loading={loading}
            css={override}
            size={15}
          />
        </>
      ) : (
        <>
          {/* header part */}
          <Row
            style={{
              display: "flex",
              flexDirection: "row",
            }}>
            <Col
              style={{
                display: "flex",
                flexDirection: "row",
              }}>
              {feaureOptions.map(fillFeatureData)}
            </Col>
            <Col style={{ display: "flex", justifyContent: "flex-end" }}>
              <select
                name=""
                id=""
                className="featureType-selection"
                onChange={(e) => featureTypeChanged(e.target.value)}>
                {featureTypeOptions.map((feautureType) => {
                  return feautureType === "Insight" ? (
                    <option value={feautureType} selected>
                      {feautureType}
                    </option>
                  ) : (
                    <option value={feautureType}>{feautureType}</option>
                  );
                })}
              </select>
            </Col>
          </Row>
          <Row>
            <hr />
          </Row>
          {/* body part */}
          <Row style={{ height: "295px" }}>
            <div className="steps-selector-wrapper">
              {dropdownPanels.map((m, index) => (
                <>
                  <DropdownSelection
                    ref={(el) => (stepListRef.current[index] = el)}
                    key={m.id}
                    index={index}
                    id={m.id}
                    selectedValue={m.value}
                    data={eventsData}
                    remove={handleRemoveSelection}
                    kit={selectedKit}
                    featureType={"Insight"}
                    sendDuplicatedEvent={handleDuplicateEvent}
                    duplicateEventData={duplicateEventData}
                    triggerLoading={(val) => setLoading(val)}
                  />
                </>
              ))}
              <div style={{ display: "flex", alignItems: "center" }}>
                <AddCircleOutlineRoundedIcon
                  id={"add-step-icon-" + id}
                  onClick={() => addInsightStepSelector()}
                  style={{
                    width: "30px",
                    height: "30px",
                    marginTop: "10px",
                    cursor: "pointer",
                    color: "#bb6bd9",
                  }}
                />
                <span
                  style={{
                    color: "#bb6bd9",
                    marginLeft: "10px",
                    marginTop: "10px",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                  onClick={() => addInsightStepSelector()}>
                  Add Event
                </span>
              </div>
            </div>
          </Row>
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <button
              className="go-button-insight"
              onClick={validateSelectionBeforeSubmit}>
              Go
            </button>
          </Row>
          <Row>
            {chartData ? (
              <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                <Tooltip title="Back to chart!">
                  <LegendToggleIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => setDisplayingChart(true)}
                  />
                </Tooltip>
              </Col>
            ) : (
              ""
            )}
          </Row>
          <SyncLoader
            color="#e09e53"
            id={"panel-" + id + "-loader"}
            loading={loading}
            css={override}
            size={15}
          />
        </>
      )}
    </>
  );
};
export default Insight;
