import React, { useEffect, useRef, useState } from "react";
import ReportEmptyIcon from "../../../assets/reports/report-empty-icon.svg";
import { Row, Col } from "react-bootstrap";
import Select, { components } from "react-select";
import Switch from "react-switch";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Calendar } from "react-date-range";
import "./rightPanel.scss";
import { cloneDeep } from "lodash";
import ChartPanel2 from "../../../Dashboard/MainPanel/ChartPanel2/ChartPanel2";
import axios from "axios";
import StringToColor from "string-to-color";
import { nanoid } from "nanoid";
import SyncLoader from "react-spinners/PulseLoader";
import { css } from "@emotion/react";
import { FaSortAmountDown, FaBeer } from "react-icons/fa";
import { SiEventstore } from "react-icons/si";

import DataThresholdingIcon from "@mui/icons-material/DataThresholding";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { DateRange } from "react-date-range";

const RightPanel = ({ queryData, savedEventData }) => {
  const featureTypeSelectStyle = {
    control: (styles, { isFocused, isSelected }) => ({
      ...styles,
      width: "90px",
      overflow: "wrap ",
      fontSize: "14px",
      textAlignLast: "center",
      border: "1px solid #c5c5c5",
      borderRadius: "10px",
      //   fontWeight: "500",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#8f8f8f",
    }),
    menu: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        width: "80px",
        borderRadius: "8px",
        padding: "2px",
      };
    },
    dropdownIndicator: (base) => ({
      ...base,
      marginLeft: "-6px",
    }),

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        borderRadius: "6px",
        marginBottom: "2px",
        textAlign: "center",
        backgroundColor: isSelected
          ? "#BB6BD9"
          : isFocused
          ? "#ddedf2"
          : "white",
      };
    },
  };

  const override = css`
    position: absolute;
    left: 58%;
    top: 40%;
    z-index: 999;
  `;
  const [customSelectionState, setCustomSelectionState] = useState("Custom");
  const [customIsOpen, setCustomIsOpen] = useState("hiddenDateRange");
  const [selectionDateRange, setSelectionDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const chartDataRef = useRef("");
  const [metricTableData, setMetricTableData] = useState([{ breakdown: "" }]);
  let [loading, setLoading] = useState(false);
  const insightResponseRef = useRef();
  // const [datapoints, setDataPoints]
  const consolidatorOptions = [
    { label: "Day", value: "daily" },
    { label: "Month", value: "monthly" },
    { label: "Year", value: "yearly" },
  ];

  function createNotification(type, msg) {
    if (type === "success") return NotificationManager.success(msg, "success");
    else if (type === "error") return NotificationManager.warning(msg, "Error");
  }
  const maxCheckboxSelectLimit = 10;
  const [totalSelectedCheckboxes, setTotalSelectedCheckboxes] = useState(5);

  const [chartType, setChartType] = useState("insight_line");
  const [chartData, setChartData] = useState();
  const [displayingChart, setDisplayingChart] = useState(false);
  const chartRef = useRef();
  const [finalQueryData, setFinalQueryData] = useState({});

  const selectedDateIdRef = useRef("date-range-30D");
  const [selectedConsolidator, setSelectedConsolidator] = useState({
    label: "Day",
    value: "daily",
  });
  const selectedConsolidatorRef = useRef(selectedConsolidator);
  const insightChartTypeOptions = [
    { label: "Line", value: "line" },
    { label: "Metric", value: "metric" },
  ];

  const [selectedInsightChartType, setSelectedInsightChartType] = useState({
    label: "Line",
    value: "line",
  });

  const [switchCheckStatus, setSwitchCheckStatus] = useState(false);
  const switchStatusRef = useRef(false);
  const dateRangeOptions = [
    // "Today",
    "Yesterday",
    "7D",
    "30D",
    "3M",
    "6M",
    "12M",
    "24M",
    "Custom",
  ];
  const handleSwitchChanged = (checked) => {
    setSwitchCheckStatus(checked);
    switchStatusRef.current = checked;
    let lablesArr = [];
    let datasetsArr = [];
    if (!queryData && !savedEventData) return;
    insightResponseRef.current.forEach((res) => {
      const chartData = prepareChartDataForInsight(
        res.index,
        res.eventName,
        res.breakdown
      );
      console.log("chartdata", chartData);
      if (Array.isArray(chartData)) {
        chartData.forEach((cData) => {
          lablesArr.push.apply(lablesArr, cData.label);
          datasetsArr.push(cData.dataset);
        });
      } else {
        lablesArr.push.apply(lablesArr, chartData.label);
        datasetsArr.push(chartData.dataset);
      }
    });

    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    // const tableData = generateMetricTableData();
    const uniqueLables = lablesArr.filter(onlyUnique);
    uniqueLables.sort();
    const ds = {
      labels: uniqueLables,
      datasets: datasetsArr,
    };
    console.log("ds generated - ", ds);

    setChartData(ds);
    handleChartLegendChecked(nanoid(), metricTableData);
    setChartType("insight_line-" + selectedConsolidatorRef.current.value);
    chartRef.current?.updateChart(ds);
  };

  const formatQueryDataForInsight = () => {
    const [from_date, to_date] = formatDate(selectedDateIdRef.current);
    let chartyQueryData = !queryData ? savedEventData : queryData;
    let finalQueryData = chartyQueryData.map((eventQueryData) => {
      eventQueryData["from_date"] = from_date;
      eventQueryData["to_date"] = to_date;
      eventQueryData["consolidate"] = selectedConsolidatorRef.current.value;
      return eventQueryData;
    });
    setFinalQueryData(finalQueryData);
    let reportData = {};
    reportData["reportType"] = "Insight";
    reportData["queryData"] = finalQueryData;
    localStorage.setItem("reportData", JSON.stringify(reportData));
    return finalQueryData;
  };

  useEffect(() => {
    if (chartData && metricTableData) {
      console.log("========= chartData update ......");
      // handleChartLegendChecked(nanoid(), metricTableData);
      handleChartLegendChecked(nanoid(), metricTableData);
    }
  }, [chartData]);
  const getInsightResponse = async (eventInsightQuery) => {
    return new Promise((resolve, reject) => {
      // const insightQueryAPI =
      //   "https://asuse3algl.execute-api.us-east-2.amazonaws.com/insightFurnace/v2";

      const insightQueryAPI =
        "https://flg1kyenn4.execute-api.us-west-1.amazonaws.com/insight/Insight-agg-peruser-function";
      axios({
        method: "post",
        url: insightQueryAPI,
        data: eventInsightQuery, //qs.stringify(selectionData),
        headers: { "Content-Type": "application/json" },
      })
        .then(function (response) {
          // setLoading(false);
          console.log("insight query response received: ");
          console.log(response);
          if (response.status == 200 && response.data.error_code === 0) {
            console.log("RESOLVING - ", {
              response: response.data.response,
            });

            resolve(response.data.response);
          } else {
            createNotification(
              "error",
              "Error in insight query, please validate selected options"
            );
            reject("error");
          }
        })
        .catch((error) => {
          // setLoading(false);
          console.log("error in insight query!");
          console.log(error);
          createNotification(
            "error",
            "Error in insight query, please validate selected options"
          );
          reject(error);
        });
    });
  };
  useEffect(() => {
    if (savedEventData) {
      const fetchFromSaved = true;
      generateInsightChart(fetchFromSaved);
    }
  }, [savedEventData]);
  useEffect(async () => {
    if (queryData) {
      generateInsightChart();
    }
  }, [queryData]);

  const generateInsightChart = async (fetchFromSaved = false) => {
    if (!queryData && !savedEventData) return;
    let finalQueryData;
    // if (fetchFromSaved) {
    // finalQueryData = savedEventData;
    // } else {

    finalQueryData = formatQueryDataForInsight();
    // }
    const insightResponseArr = [];
    let index = 0;
    let lablesArr = [];
    let datasetsArr = [];
    setLoading(true);
    let errorOcurred = false;
    for await (const eventInsightQuery of finalQueryData) {
      let insightResponse = {};
      const eventName = Object.keys(eventInsightQuery["event_data"])[0];
      console.log("event name is - ", eventName);
      insightResponse.response = await getInsightResponse(
        eventInsightQuery
      ).catch((error) => {
        setLoading(false);
        errorOcurred = true;
      });
      if (errorOcurred) return;
      insightResponse.index = index;
      insightResponse.eventName = eventName;
      insightResponse.breakdown = eventInsightQuery.breakdown;
      index++;
      console.log("insight reponse - ", insightResponse);
      insightResponseArr.push(insightResponse);
    }

    insightResponseRef.current = insightResponseArr;
    // generateMetricTableData();
    console.log("insightResponseRef.current - ", insightResponseRef.current);
    insightResponseArr.forEach((res) => {
      const chartData = prepareChartDataForInsight(
        res.index,
        res.eventName,
        res.breakdown
      );
      console.log("chartdata", chartData);
      if (Array.isArray(chartData)) {
        chartData.forEach((cData) => {
          lablesArr.push.apply(lablesArr, cData.label);
          datasetsArr.push(cData.dataset);
        });
      } else {
        lablesArr.push.apply(lablesArr, chartData.label);
        datasetsArr.push(chartData.dataset);
      }
    });

    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    const uniqueLables = lablesArr.filter(onlyUnique);
    uniqueLables.sort();
    const ds = {
      labels: uniqueLables,
      datasets: datasetsArr,
    };
    console.log("ds generated - ", ds);
    setLoading(false);
    generateMetricTableData();
    setChartData(ds);
    setChartType("insight_line-" + selectedConsolidatorRef.current.value);

    // chartRef.current?.updateChart(ds);
    // alert("here....");
    // handleChartLegendChecked(nanoid(), metricTableData);
    setDisplayingChart(true);
  };
  useEffect(() => {
    document.getElementById(selectedDateIdRef.current).style.fontWeight = "800";
  }, []);
  const handleDateRangeSelected = async (event) => {
    setCustomIsOpen("hiddenDateRange");
    setCustomSelectionState("Custom");
    document.getElementById("date-range-Custom").textContent = "Custom";
    document.getElementById(selectedDateIdRef.current).style.fontWeight = "500";
    selectedDateIdRef.current = event.target.id;

    document.getElementById(event.target.id).style.fontWeight = "800";
    if (!queryData && savedEventData) {
      generateInsightChart(true);
    } else {
      generateInsightChart();
    }
  };

  const prepareChartDataForInsight = (eventIndex, eventName, breakdownval) => {
    let data = "";

    for (let index = 0; index < insightResponseRef.current.length; index++) {
      //   console.log("insightResponseRef - ", insightResponseRef.current[index]);
      if (insightResponseRef.current[index] == null) continue;
      if (insightResponseRef.current[index].index === eventIndex) {
        data = insightResponseRef.current[index].response;
        // console.log("data fount - ", insightResponseRef.current[index]);
        break;
      }
    }
    console.log("breakdownval", breakdownval);
    const isBreakdownApplied = breakdownval === "" ? false : true;
    if (isBreakdownApplied) {
      const datasets = [];
      const yAxes = [];

      let ySum = 0;
      Object.entries(data.distributed_time).forEach((dsEntries) => {
        // console.log("each dsentry: ", dsEntries);
        const labels = [];
        const datapoints = [];
        // console.log("DS - ", dsEntries[0], ", vals: ", dsEntries[1]);
        const key = dsEntries[0];
        const value = dsEntries[1];
        // const [key, value] = dsEntries;
        const count = value.count;
        const time = value.time;
        ySum = 0;

        // for cummulative
        if (switchStatusRef.current) {
          value.forEach((data) => {
            ySum += data.count;
            datapoints.push({ x: data.time, y: ySum });
            labels.push(data.time);
          });
        } else {
          value.forEach((data) => {
            datapoints.push({ x: data.time, y: data.count });
            labels.push(data.time);
          });
        }
        // const randomColor = stringToColour(eventName + "/" + key);
        const randomColor = StringToColor(
          (eventName + "/" + key).toLowerCase()
        );
        datasets.push({
          dataset: {
            label: eventName + " / " + key,
            borderColor: randomColor,
            borderWidth: 2,
            hoverBackgroundColor: randomColor,
            hoverBorderColor: randomColor,
            tension: 0.2,
            data: datapoints,
          },
          label: labels,
        });
        // console.log("datasets,", datasets);
      });

      return datasets;
    } else {
      // for non-breakdown insight
      const datasets = [];
      // console.log("generateChartForInsight, data - ", data);
      const yAxes = [];
      const labels = [];
      const datapoints = [];
      let ySum = 0;
      Object.entries(data.distributed_time).forEach((dsEntries) => {
        const [key, value] = dsEntries;
        const count = value.count;
        const time = value.time;
        ySum += count;
        //for cummulative
        if (switchStatusRef.current) {
          datapoints.push({ x: time, y: ySum });
          labels.push(time);
        } else {
          datapoints.push({ x: time, y: count });
          labels.push(time);
        }
      });

      const randomColor = StringToColor(eventName.toLowerCase());
      return {
        dataset: {
          label: eventName,
          borderColor: randomColor,
          borderWidth: 2,
          hoverBackgroundColor: randomColor,
          hoverBorderColor: randomColor,
          tension: 0.2,
          data: datapoints,
        },
        label: labels,
      };
    }
  };

  const formatDate = (date) => {
    let fromDate = new Date();
    let toDate = new Date();

    if (date.includes("7D")) {
      fromDate.setDate(fromDate.getDate() - 7);
    } else if (date.includes("30D")) {
      fromDate.setMonth(fromDate.getMonth() - 1);
    } else if (date.includes("3M")) {
      fromDate.setMonth(fromDate.getMonth() - 3);
    } else if (date.includes("6M")) {
      fromDate.setMonth(fromDate.getMonth() - 6);
    } else if (date.includes("12M")) {
      fromDate.setMonth(fromDate.getMonth() - 12);
    } else if (date.includes("24M")) {
      fromDate.setMonth(fromDate.getMonth() - 24);
    } else if (date.includes("Today")) {
      fromDate = toDate;
    } else if (date.includes("Yesterday")) {
      fromDate.setDate(fromDate.getDate() - 1);
      toDate.setDate(toDate.getDate() - 1);
    } else if (date.includes("Custom")) {
      fromDate = selectionDateRange.startDate.toLocaleString().split(",")[0];
      toDate = selectionDateRange.endDate.toLocaleString().split(",")[0];
      let year = fromDate.split("/")[2];
      let month = fromDate.split("/")[1];
      let day = fromDate.split("/")[0];
      fromDate = year + "-" + month + "-" + day;
      year = toDate.split("/")[2];
      month = toDate.split("/")[1];
      day = toDate.split("/")[0];
      toDate = year + "-" + month + "-" + day;
      return [fromDate, toDate];
    } else {
      fromDate = "";
      toDate = "";
    }
    if (fromDate !== "")
      return [
        fromDate.getFullYear() +
          "-" +
          String(fromDate.getMonth() + 1).padStart(2, "0") +
          "-" +
          String(fromDate.getDate()).padStart(2, "0"),
        toDate.getFullYear() +
          "-" +
          String(toDate.getMonth() + 1).padStart(2, "0") +
          "-" +
          String(toDate.getDate()).padStart(2, "0"),
      ];
    else return ["", ""];
  };
  const handleConsolidateChanged = (event) => {
    setSelectedConsolidator(event);
    selectedConsolidatorRef.current = event;
    if (queryData) generateInsightChart();
  };
  const handleInsightChartTypeChanged = (event) => {
    setSelectedInsightChartType(event);
  };
  const handleCustomButtonClicked = () => {
    document.getElementById(selectedDateIdRef.current).style.fontWeight = "500";
    selectedDateIdRef.current = "date-range-Custom";
    document.getElementById(selectedDateIdRef.current).style.fontWeight = "800";
    if (customSelectionState === "Custom") {
      setCustomSelectionState("Apply Range");
      document.getElementById("date-range-Custom").textContent = "Apply Range";
      setCustomIsOpen("visibleDateRange");
    } else {
      setCustomSelectionState("Custom");

      let fromDate = selectionDateRange.startDate;
      let toDate = selectionDateRange.endDate;
      document.getElementById("date-range-Custom").textContent =
        fromDate.toLocaleString().split(",")[0] +
        " - " +
        toDate.toLocaleString().split(",")[0];
      setCustomIsOpen("hiddenDateRange");
      generateInsightChart();
    }
  };
  const handleCustomDateRangeSelect = (ranges) => {
    console.log(ranges);
    if ("range1" in ranges) {
      setSelectionDateRange({
        startDate: ranges.range1.startDate,
        endDate: ranges.range1.endDate,
        // key: "selection",
      });
    } else {
      setSelectionDateRange({
        startDate: ranges.selection.startDate,
        endDate: ranges.selection.endDate,
        // key: "selection",
      });
    }
  };

  const generateMetricTableData = () => {
    const responseData = insightResponseRef.current;
    let tableData = [];

    responseData.forEach((data) => {
      const eventName = data.eventName;
      let breakdown = "";
      // if breakdown applied
      if (data.breakdown !== "") {
        breakdown = data.breakdown;
        // console.log("data - ", data);
        Object.entries(data.response.total_count).forEach((breakdownVals) => {
          const [breakdownKey, breakdownValue] = breakdownVals;

          tableData.push({
            id: nanoid(),
            eventName,
            breakdown,
            breakdownKey,
            selected: false,
            breakdownValue,
          });
        });
      } else {
        tableData.push({
          id: nanoid(),
          eventName,
          eventValue: data.response.total_count,
          selected: false,
        });
      }
    });
    if ("breakdown" in tableData[0])
      tableData.sort((a, b) => b.breakdownValue - a.breakdownValue);
    else tableData.sort((a, b) => b.eventValue - a.eventValue);
    let index = 0;
    tableData.map((data) => {
      index++;
      return index <= 5 ? (data.selected = true) : (data.selected = false);
    });
    setTotalSelectedCheckboxes(index > 5 ? 5 : index);
    console.log("final table data - ", tableData);

    setMetricTableData(tableData);
    return tableData;
    // if (firstTimeLoad) handleChartLegendChecked(nanoid(), metricTableData);
  };

  const handleMetricListChecked = (id, data) => {
    const newData = cloneDeep(data);
    let totalCheckedItems = newData.filter((e) => e.selected).length;
    console.log("total checkeditems - ", totalCheckedItems);
    newData.map((entry) => {
      if (entry.id === id) {
        let currentCheckedValue = entry.selected;
        if (currentCheckedValue === true) {
          document.getElementById("rowcheck-" + entry.id).checked =
            !currentCheckedValue;
          totalCheckedItems--;
          setTotalSelectedCheckboxes(totalCheckedItems);
          return (entry.selected = !currentCheckedValue);
        } else if (totalCheckedItems < maxCheckboxSelectLimit) {
          document.getElementById("rowcheck-" + entry.id).checked =
            !currentCheckedValue;
          totalCheckedItems++;
          setTotalSelectedCheckboxes(totalCheckedItems);
          return (entry.selected = !currentCheckedValue);
        }
      }
    });
    console.log("new data is now  - ", newData);
    setMetricTableData(newData);
  };
  const handleChartLegendChecked = (id, data) => {
    const newData = cloneDeep(data);
    let totalCheckedItems = newData.filter((e) => e.selected).length;
    console.log("total checkeditems - ", totalCheckedItems);
    newData.map((entry) => {
      if (entry.id === id) {
        let currentCheckedValue = entry.selected;
        if (currentCheckedValue === true) {
          document.getElementById("rowcheck-" + entry.id).checked =
            !currentCheckedValue;
          totalCheckedItems--;

          setTotalSelectedCheckboxes(totalCheckedItems);
          return (entry.selected = !currentCheckedValue);
        } else if (totalCheckedItems < maxCheckboxSelectLimit) {
          document.getElementById("rowcheck-" + entry.id).checked =
            !currentCheckedValue;
          totalCheckedItems++;

          setTotalSelectedCheckboxes(totalCheckedItems);
          return (entry.selected = !currentCheckedValue);
        }
      }
    });

    // create set for selected options
    const selectedOptionsSet = new Set();
    newData.forEach((data) => {
      if (data.selected)
        selectedOptionsSet.add(
          "breakdown" in data
            ? data.eventName + " / " + data.breakdownKey
            : data.eventName
        );
    });
    console.log("new data is now  - ", newData);

    const filteredChartData = filterOnlySelectedChartData(
      chartData,
      selectedOptionsSet
    );
    chartDataRef.current = filteredChartData;
    // setChartData(filteredChartData);
    chartRef.current?.updateChart(filteredChartData);
    // setChartData(filteredChartData);
    console.log("==========> filtered chart data - ", filteredChartData);
    setMetricTableData(newData);
    // return filteredChartData;
  };

  const filterOnlySelectedChartData = (chartData, selectedOptionsSet) => {
    console.log("chart data - ", chartData);
    console.log("selectedOptionsSet - ", selectedOptionsSet);
    const selectedDatasetsArr = [];
    chartData.datasets.forEach((data) => {
      if (selectedOptionsSet.has(data.label)) selectedDatasetsArr.push(data);
    });
    // console.log("selectedDatasetsArr.... ", selectedDatasetsArr);
    return { datasets: selectedDatasetsArr, labels: chartData.labels };
  };

  return (
    <>
      <Row
        style={{
          width: "100%",
          border: "1px solid #DFDFDF",
          height: "500px",
          borderRadius: "24px",
          flexDirection: "column",
        }}>
        <NotificationContainer />
        <Col
          lg="12"
          style={{ display: "flex", marginTop: "10px", marginBottom: "10px" }}>
          <Col lg="9" style={{ position: "relative" }}>
            {dateRangeOptions.map((option, index) => {
              const additionClass =
                index === dateRangeOptions.length - 1
                  ? "date-range-last-btn"
                  : index === 0
                  ? "date-range-first-btn"
                  : "";
              return (
                <button
                  className={"date-range-btn " + additionClass}
                  id={"date-range-" + option}
                  onClick={
                    option === "Custom"
                      ? handleCustomButtonClicked
                      : handleDateRangeSelected
                  }>
                  {option}
                </button>
              );
            })}
            <DateRange
              ranges={[selectionDateRange]}
              onChange={handleCustomDateRangeSelect}
              className={customIsOpen}
              maxDate={new Date()}
            />
          </Col>

          <Col
            lg="3"
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}>
            <Select
              components={{
                IndicatorSeparator: () => null,
              }}
              closeMenuOnSelect
              styles={featureTypeSelectStyle}
              value={selectedConsolidator}
              options={consolidatorOptions}
              onChange={handleConsolidateChanged}
              isSearchable={false}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
            />
            <Select
              components={{
                IndicatorSeparator: () => null,
              }}
              closeMenuOnSelect
              styles={featureTypeSelectStyle}
              value={selectedInsightChartType}
              options={insightChartTypeOptions}
              onChange={handleInsightChartTypeChanged}
              isSearchable={false}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
            />
            <label htmlFor="material-switch">
              <Switch
                onChange={handleSwitchChanged}
                checked={switchCheckStatus}
                onColor="#72C8CC"
                onHandleColor="#fffff"
                uncheckedIcon={false}
                checkedIcon={false}
                className="react-switch"
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                // height={20}
                // width={40}
                // handleDiameter={4}
              />
            </label>
          </Col>
        </Col>
        <hr />
        {displayingChart ? (
          selectedInsightChartType.value === "metric" ? (
            <Col
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                height: "420px",
                overflow: "auto",
                justifyContent: "center",
              }}>
              <div
                style={{
                  display: "flex",
                  // background: "#c5c5c5",
                  flexWrap: "wrap",
                  textAlign: "center",
                }}>
                {metricTableData.map((data) => {
                  // console.log("metric table data updated...........");
                  // console.log(".........metricTableData - ", metricTableData);
                  if (data.selected) {
                    // console.log("..... data - ", data);
                    return (
                      <div
                        style={{
                          flex: 1,
                          border: "2px solid rgba(96, 93, 95, 0.16)",
                          padding: "18px",
                          margin: "5px",
                          borderRadius: "12px",
                          display: "inline-block",
                        }}>
                        {!data["breakdown"] ? (
                          <>
                            <span
                              style={{
                                display: "block",
                                color: "rgba(52, 49, 51, 0.79)",
                                width: "max-content",
                                // display: "coinntents",
                                fontWeight: 700,
                              }}>
                              <span
                                style={{
                                  display: "inline-block",
                                  height: "13px",
                                  width: "13px",
                                  borderRadius: "3px",
                                  marginRight: "6px",
                                  background:
                                    StringToColor(
                                      data.eventName.toLowerCase()
                                    ) + "B3",
                                }}></span>
                              {data.eventName}
                            </span>
                            <span
                              style={{
                                display: "block",
                                color: "rgba(52, 49, 51, 0.79)",
                                width: "max-content",
                                // display: "contents",
                                fontSize: "2em",
                                fontWeight: 800,
                              }}>
                              {Number(data.eventValue).toLocaleString("en", {
                                minimumFractionDigits: 0,
                              })}
                            </span>
                            <span
                              style={{
                                display: "block",
                                color: "rgba(52, 49, 51, 0.79)",
                                width: "max-content",
                                fontWeight: "600",
                              }}>
                              events
                            </span>
                          </>
                        ) : (
                          <>
                            <span
                              style={{
                                display: "block",
                                color: "rgba(52, 49, 51, 0.79)",
                                width: "max-content",
                                // display: "contents",

                                fontWeight: 700,
                              }}>
                              <span
                                style={{
                                  display: "inline-block",
                                  height: "13px",
                                  width: "13px",
                                  borderRadius: "3px",
                                  marginRight: "6px",
                                  background:
                                    StringToColor(
                                      (
                                        data.eventName +
                                        "/" +
                                        data.breakdownKey
                                      ).toLowerCase()
                                    ) + "B3",
                                }}></span>
                              {data.eventName} / {data.breakdownKey}
                            </span>
                            <span
                              style={{
                                display: "block",
                                color: "rgba(52, 49, 51, 0.79)",
                                width: "max-content",
                                fontSize: "2em",
                                // fontFamily: "Advent Pro",
                                // display: "contents",
                                fontWeight: 800,
                              }}>
                              {/* {data.breakdownValue} */}
                              {Number(data.breakdownValue).toLocaleString(
                                "en",
                                {
                                  minimumFractionDigits: 0,
                                }
                              )}
                            </span>
                            <span
                              style={{
                                display: "block",
                                color: "rgba(52, 49, 51, 0.79)",
                                width: "max-content",
                                fontWeight: "600",
                              }}>
                              events
                            </span>
                          </>
                        )}
                      </div>
                    );
                  }
                })}
              </div>
              <SyncLoader
                color="#bb6bd9"
                id={"panel-loader"}
                loading={loading}
                css={override}
                size={18}
                margin={8}
              />
            </Col>
          ) : (
            <Col
              lg="12"
              style={{
                height: "410px",
              }}>
              <ChartPanel2
                ref={chartRef}
                id={nanoid()}
                data={chartDataRef.current}
                // dataPoints={datapoints}
                chartType={chartType}
              />
              <SyncLoader
                color="#bb6bd9"
                id={"panel-loader"}
                loading={loading}
                css={override}
                size={18}
                margin={8}
              />
            </Col>
          )
        ) : (
          <Col
            lg="12"
            style={{
              height: "410px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <img src={ReportEmptyIcon} alt="" />

            <SyncLoader
              color="#bb6bd9"
              id={"panel-loader"}
              loading={loading}
              css={override}
              size={18}
              margin={8}
            />
          </Col>
        )}
      </Row>

      {/* dataset selection  */}
      {displayingChart ? (
        <Row
          style={{
            maxHeight: "400px",
            border: "1px solid rgb(223, 223, 223)",
            borderRadius: "24px",
            marginTop: "2vh",
            marginBottom: "4vh",
            marginRight: "1vh",
            overflow: "auto",
            top: 0,
          }}>
          <table
            cellPadding="12px"
            style={{
              borderCollapse: "collapse",
              width: "100%",
              fontSize: "14px",
            }}>
            <thead
              style={{
                borderBottom: "1px solid rgb(223, 223, 223)",
                background: "#BF87CD",
                color: "white",
                position: "sticky",
                top: 0,
              }}>
              <tr style={{ boxSizing: "border-box", fontSize: "15px" }}>
                <th>
                  Event
                  <SiEventstore style={{ marginLeft: "10px" }} />
                  {!metricTableData[0]["breakdown"]
                    ? " ( " + totalSelectedCheckboxes + " selected)"
                    : ""}
                </th>
                {metricTableData[0]["breakdown"] ? (
                  <th
                    style={{
                      position: "sticky",
                      top: 0,
                    }}>
                    {metricTableData[0].breakdown.split("_").join(" ")}
                    {" ("} {totalSelectedCheckboxes} {" selected)"}
                  </th>
                ) : (
                  ""
                )}
                <th
                  style={{
                    position: "sticky",
                    top: 0,
                  }}>
                  Value
                  <FaSortAmountDown style={{ marginLeft: "10px" }} />
                </th>
              </tr>
            </thead>
            <tbody>
              {metricTableData.map((data) => {
                // console.log("each entry - ", data);

                if (!data["breakdown"]) {
                  return (
                    <tr
                      style={{
                        borderBottom: "1px solid rgba(190, 172, 181, 0.29)",
                      }}>
                      <td
                        style={{
                          fontWeight: "500",
                          color: "rgba(52, 49, 51, 0.79)",
                        }}
                        onClick={() =>
                          selectedInsightChartType.value === "metric"
                            ? handleMetricListChecked(data.id, metricTableData)
                            : handleChartLegendChecked(data.id, metricTableData)
                        }>
                        <input
                          type="checkbox"
                          style={{ marginRight: "15px", zIndex: "-10" }}
                          checked={data.selected}
                          // className="form-check-input"
                          id={"rowcheck-" + data.id}
                        />
                        {"  "}
                        {data.eventName}{" "}
                      </td>
                      <td
                        style={{
                          fontWeight: "600",
                          color: "rgba(52, 49, 51, 0.79)",
                        }}>
                        {data.eventValue}{" "}
                      </td>
                    </tr>
                  );
                } else {
                  return (
                    <tr
                      style={{
                        borderBottom: "1px solid rgba(190, 172, 181, 0.29)",
                      }}>
                      <td
                        style={{
                          fontWeight: "500",
                          color: "rgba(52, 49, 51, 0.79)",
                        }}>
                        {" "}
                        {data.eventName}{" "}
                      </td>
                      <td
                        style={{
                          fontWeight: "500",
                          color: "rgba(52, 49, 51, 0.79)",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          selectedInsightChartType.value === "metric"
                            ? handleMetricListChecked(data.id, metricTableData)
                            : handleChartLegendChecked(data.id, metricTableData)
                        }>
                        {" "}
                        <input
                          type="checkbox"
                          style={{ marginRight: "15px", zIndex: "-10" }}
                          checked={data.selected}
                          // className="form-check-input"
                          id={"rowcheck-" + data.id}
                        />
                        {data.breakdownKey}{" "}
                      </td>
                      <td
                        style={{
                          fontWeight: "600",
                        }}>
                        {data.breakdownValue}{" "}
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </Row>
      ) : (
        ""
      )}
    </>
  );
};

export default RightPanel;
