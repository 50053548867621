import React, { useEffect, useState, useRef } from "react";
import MainHeader from "./MainHeader/MainHeader";
import MainPanel from "./MainPanel/MainPanel";
import "./MainScreen.scss";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import DashPanel from "./DashPanel";
import { Row, Col, Container } from "react-bootstrap";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import Sidebar from "./Sidebar/Sidebar";

const MainScreen = ({}) => {
  const { currentUser, logout } = useAuth();
  const history = useHistory();
  const [error, setError] = useState("");
  const [mainPanels, setMainPanels] = useState([0, 1]);
  const [dashPanels, setDashPanels] = useState([0, 1]);
  const mainRef = useRef([]);
  return (
    <>
      <div
        // className="main-screen"
        style={{ display: "flex", boxSizing: "border-box" }}>
        {/* <MainHeader /> */}
        {/* style={{ border:"2px solid red" }} */}
        {/* <Container lg="12" xl="12" fluid> */}
        <div
          style={{
            borderRight: "2px solid #DDE7F1",
            width: "14%",
            position: "fixed",
            top: "0",
            bottom: "0",
            backgroundColor: "white",
            zIndex: "99",
            padding: 0,
            margin: 0,
            boxSizing: "border-box",
          }}>
          <Sidebar />
        </div>
        <div
          style={{
            marginLeft: "16vw",
            height: "100vh",
            // width: "86vw",
            display: "flex",
            // flexDirection: "column",
            boxSizing: "border-box",
            alignItems: "center",
            marginTop: "4vh",
            flexWrap: "wrap",
            // border: "2px solid",
            justifyContent: "flex-start",
          }}>
          {/* <MainHeader /> */}
          {dashPanels.map((m) => (
            <Col
              style={{ marginBottom: "20px" }}
              // lg="5"
              xl="5"
              xxl="6"
              className="dashboard-panels"
              style={{
                // width: "71px",
                marginBottom: "2vh",
                marginRight: "1.5vw",
                // maxWidth: "720px",
                // minWidth: "580px",
              }}>
              <DashPanel
                ref={(el) => (mainRef.current[m] = el)}
                key={m}
                id={m}
              />
            </Col>
          ))}
          <Col
            style={{ marginBottom: "20px" }}
            // lg="6"
            xl="5"
            xxl="6"
            style={
              {
                // maxWidth: "720px",
                // minWidth: "580px",
              }
            }>
            <Col
              className="add-panel-container dashboard-panels"
              style={{ marginBottom: "2vh" }}
              onClick={() => setDashPanels([...dashPanels, dashPanels.length])}>
              <AddCircleOutlineRoundedIcon
                id={"add-panel-icon"}
                style={{
                  width: "30px",
                  height: "30px",
                  //   marginTop: "10px",
                  cursor: "pointer",
                  color: "##828282",
                }}
              />{" "}
              <span className="add-panel-text"> Add New</span>
            </Col>
          </Col>
        </div>
      </div>
    </>
  );
};

export default MainScreen;
