import React, {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import "./select-search.css";
import Select, { components } from "react-select";
import Badge from "react-bootstrap/Badge";
import { makeStyles } from "@material-ui/core/styles";
import FilterIcon from "../../../assets/filter_icon.png";
import { cloneDeep, filter } from "lodash";
import MUISelect from "@mui/material/Select";
import createClass from "create-react-class";
import axios from "axios";
import SyncLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import { autoType } from "d3-dsv";
import FilterIconColor from "../../../assets/filterColor.svg";
import DuplicatIcon from "../../../assets/duplicate_icon.svg";
import CustomFilterIcon from "../../../assets/custom_filter_icon.svg";
import ConfigIcon from "../../../assets/config_icon.svg";
const useStyles = makeStyles({
  root: {
    width: 132,
    borderRadius: 10,
    color: "grey",
  },
  select: {
    maxHeight: 48 * 4.5 + 1,
    fontSize: "8px",
    // width: 150,
    "& ul": {
      // backgroundColor: "#cccccc",
    },
    "& li": {
      fontSize: "8px",
    },
  },
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

const DropdownSelection = forwardRef(
  (
    {
      remove,
      id,
      data,
      index,
      selectedValue = "",
      kit,
      featureType = "Funnel",
      triggerLoading,
      sendDuplicatedEvent,
      duplicateEventData,
    },
    ref
  ) => {
    const [filterDisplay, setFilterDisplay] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedStep, setSelectedStep] = useState(null);
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [propertyPlaceholder, setPropertyPlaceholder] = useState("property");
    const [singleFilterInput, setSingleFilterInput] = useState("");
    const [isDisabledFilterSelector, setDisabledFilterSelector] =
      useState(true);
    const [isDisabledStepSelector, setDisabledStepSelector] = useState(false);
    const [isDisabledPropertySelector, setDisabledPropertySelector] =
      useState(false);
    const [selectedPropertyOption, setSelectedPropertyOption] = useState({
      label: "",
      value: "",
    });
    const [operatorOptions, setOperatorOptions] = useState([
      { label: "=", value: "is" },
      { label: "≠", value: "is not" },
      { label: "∈", value: "contains" },
      { label: "∉", value: "does not contains" },
    ]);
    const allFilterDataRef = useRef("");
    const [propertyOptions, setPropertyOptions] = useState("");
    const [defaultDropdownValue, setDefaultDropdownValue] = useState({
      label: "=",
      value: "is",
    });
    const configOptions = [
      { label: "Duplicate", value: "Duplicate" },
      { label: "Inline Filter", value: "Inline Filter" },
    ];

    const configData = [
      {
        value: "Duplicate",
        text: "Duplicate",
        icon: <img src={DuplicatIcon} />,
      },
      {
        value: "Inline Filter",
        text: "Inline Filter",
        icon: <img src={CustomFilterIcon} />,
      },
    ];
    const [personName, setPersonName] = React.useState();
    const [filterOptions, setFilterOptions] = useState([]);
    const [selectedDataType, setSelectedDataType] = useState({
      value: "string",
      label: "Aa",
    });
    const [selectedOperator, setSelectedOperator] = useState({
      label: "=",
      value: "is",
    });

    const [selectedOption, setSelectedOption] = useState({
      label: "Count Total",
      value: "Total",
    });
    const [displayOptionProperties, setDisplayOptionProperties] =
      useState(false);
    const optionTypeList = [
      { label: "Count Total", value: "Total" },
      { label: "Count Unique", value: "Unique" },
      { label: "Sum - Agg. per user", value: "sum" },
      { label: "Min - Agg. per user", value: "min" },
      { label: "Max - Agg. per user", value: "max" },
      { label: "Average - Agg. per user", value: "avg" },
      { label: "Distinct - Agg. per user", value: "distinct count" },
    ];

    document.addEventListener("mouseup", function (e) {
      var container = document.getElementById("config-main-dropdown-" + id);
      if (container && !container.contains(e.target)) {
        container.style.display = "none";
      }
    });

    const [autoFillingData, setAutoFillingData] = useState(false);
    const stepsOptions = data.steps;

    useImperativeHandle(ref, () => ({
      getSelectedData() {
        // if theres is not filter selected, filterArr will be ["Filters"]
        let selectedFilterArray = [];
        let propertiesArray = [];
        let filtersArray = [];
        let agg_per_user = {};
        const selectPropOption = {};

        let events_performed_type = {};
        if (featureType === "Insight") {
          if (
            selectedOption.value !== "Total" &&
            selectedOption.value !== "Unique"
          ) {
            agg_per_user[selectedPropertyOption.value] = {
              agg1: selectedOption.value,
              agg2: "avg",
            };
            events_performed_type = "Aggregate property per user";
          } else {
            events_performed_type = selectedOption.value;
          }
        }

        if (filterDisplay) {
          propertyOptions.forEach((data) => {
            propertiesArray.push(data.value);
          });

          // if equalor is = or != , (multiple selections)
          if (selectedOperator.value.includes("is")) {
            filterOptions.forEach((data) => {
              filtersArray.push(data.value);
            });

            if (personName) {
              personName.forEach((person) => {
                selectedFilterArray.push(person.value);
              });
            }
            const selectedData = {
              id: id,
              step: selectedStep.value,
              property: selectedProperty,
              datatype: selectedDataType.value,
              operator: selectedOperator.value,
              filter:
                selectedFilterArray.length == 0 ? "" : selectedFilterArray,
              allProperties: propertiesArray,
              allFilters: filtersArray,
              selectedFilters: personName,
              isFilter: filterDisplay,
              agg_per_user,
              events_performed_type,
              selectedStep: selectedStep && selectedStep.value,
              selectedProperty: selectedProperty && selectedProperty.value,
            };
            return selectedData;
          } else {
            console.log("taking filter from inputbox");
            const selectedValue = document.getElementById(
              "select-filter-contains-" + id
            ).value;
            console.log("selected value : " + selectedValue);
            setSingleFilterInput(selectedValue);
            const selectedData = {
              id: id,
              step: selectedStep.value,
              property: selectedProperty,
              datatype: selectedDataType.value,
              operator: selectedOperator.value,
              filter: selectedValue,
              agg_per_user,
              events_performed_type,
              allProperties: propertiesArray,
              isFilter: filterDisplay,
              selectedStep: selectedStep && selectedStep.value,
              selectedProperty: selectedProperty && selectedProperty.value,
            };
            return selectedData;
          }
        } else {
          const selectedData = {
            id: id,
            step: selectedStep.value,
            isFilter: filterDisplay,
            agg_per_user,
            events_performed_type,
            selectedStep: selectedStep && selectedStep.value,
          };
          return selectedData;
        }
      },
      notifyKitChange(kit) {
        console.log("notify kit change called!!");
        setPropertyOptions("");
        setFilterOptions("");
        setSelectedProperty("");
        setSelectedStep("");
        setPersonName([]);
        setFilterDisplay(false);
      },
    }));

    useEffect(() => {
      // for each panel will check if filter is applied or not
      // if applied, autofills all the properties when clicked on the back button(gear icon)

      console.log("------>  inside use effect <----------");
      console.log(data);
      if (data.isFilterAppliedList) {
        data.isFilterAppliedList.forEach((currentPanel) => {
          if (currentPanel.id === id && currentPanel.isFilterApplied == true) {
            setFilterDisplay(true);
          }
        });
        if (data.allFilters) {
          console.log("reached tlll here.......");
          console.log(data);
          data.allFilters.forEach((currentData) => {
            if (
              currentData.id === id &&
              currentData.filters &&
              currentData.filters.length > 0
            ) {
              console.log("found!!!");
              const filters = [];

              currentData.filters.forEach((filter) => {
                // console.log("~~~ each filter - ", filter);
                filters.push({ label: filter, value: filter });
              });
              // console.log("generated filter options");
              // console.log(filters);
              setFilterOptions(filters);
            }
          });

          if (data.allProperties) {
            data.allProperties.forEach((currentData) => {
              if (
                currentData.id === id &&
                currentData.properties &&
                currentData.properties.length > 0
              ) {
                const properties = [];
                currentData.properties.forEach((filter) => {
                  properties.push({ label: filter, value: filter });
                });
                // console.log("generated property options");
                // console.log(properties);
                // console.log("setting properties here.......");
                setPropertyOptions(properties);
              }
            });
          }
        }
      }

      if (data.allSelectedValues && data.allSelectedValues.length > 0) {
        data.allSelectedValues.forEach((currentData) => {
          if (currentData.id === id) {
            console.log("currentData for selected values");
            console.log(currentData);
            setAutoFillingData(true);
            setSelectedStep({
              label: currentData.selectedStep,
              value: currentData.selectedStep,
            });
            if (currentData.selectedProperty) {
              setSelectedProperty({
                label: currentData.selectedProperty,
                value: currentData.selectedProperty,
              });
              setPersonName(currentData.selectedFilters);
            }
          }
        });
      }
    }, [data]);

    useEffect(() => {
      console.log("feteched duplicated event data");
      if (duplicateEventData && duplicateEventData.id === id) {
        if (duplicateEventData && "selectedStep" in duplicateEventData)
          setSelectedStep(duplicateEventData.selectedStep);
        if (
          duplicateEventData &&
          "isFilter" in duplicateEventData &&
          duplicateEventData.isFilter === true
        ) {
          setFilterDisplay(true);
        }
        if (duplicateEventData && "selectedProperty" in duplicateEventData) {
          setSelectedProperty(duplicateEventData.selectedProperty);
        }
        if (duplicateEventData && "propertyOptions" in duplicateEventData)
          setPropertyOptions(duplicateEventData.propertyOptions);
        if (duplicateEventData && "operatorOptions" in duplicateEventData)
          setOperatorOptions(duplicateEventData.operatorOptions);
        if (duplicateEventData && "selectedOperator" in duplicateEventData)
          setSelectedOperator(duplicateEventData.selectedOperator);
        if (duplicateEventData && "selectedDataType" in duplicateEventData)
          setSelectedDataType(duplicateEventData.selectedDataType);
        if (duplicateEventData && "filterOptions" in duplicateEventData) {
          setFilterOptions(duplicateEventData.filterOptions);
          setDisabledFilterSelector(false);
        }

        if (duplicateEventData && "selectedFilters" in duplicateEventData)
          setPersonName(duplicateEventData.selectedFilters);
      }
    }, [duplicateEventData]);

    const override = css`
      position: relative;
      top: -25%;
      left: 60%;
      z-index: 999;
    `;

    const optionStyle = {
      control: (styles) => ({
        ...styles,
        backgroundColor: "white",
        width: "120px",
        border: "1px solid #D3D3D3",
        borderRadius: "10px",
        marginLeft: "2rem",
        height: "20px",
        overflow: "wrap ",
        fontSize: "10px",
        marginRight: "3px",
        marginTop: "0.30rem",
        textAlign: "center",
        fontWeight: "500",
      }),
      menu: (base) => ({
        ...base,
        zIndex: 10,
        overflow: "wrap",
        fontSize: "13px",
        width: "200px",
        borderRadius: "20px",
        padding: "10px",
      }),

      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isSelected ? "#bb6bd9" : "white",
          color: isSelected ? "white" : "black",
          borderRadius: "10px",
          marginBottom: "5px",
          padding: "8px",
          fontSize: "12px",
          fontWeight: isSelected ? 700 : 500,
        };
      },
    };

    const dataTypeOptions = [
      { value: "string", label: "Aa" },
      { value: "number", label: "#" },
      { value: "boolean", label: "0/1" },
    ];
    const classes = useStyles();
    const handleChange = (data) => {
      console.log("handle change called: data");
      console.log(data);
      setPersonName(data);
    };

    const propertyStyle = {
      control: (styles) => ({
        ...styles,
        backgroundColor: "white",
        width: "110px",
        borderRight: "0px solid",
        borderRadius: "10px 0px 0px 10px",
        marginLeft: "5px",
        height: "30px",
        overflow: "wrap ",
        zIndex: 1,
        fontSize: "12px",
      }),

      menu: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          width: "320px",
          borderRadius: "20px",
          border: "1px solid #D3D3D3",
          padding: "8px",
          marginLeft: "-50px",
          zIndex: 10,
        };
      },

      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isSelected ? "#bb6bd9" : "white",
          fontSize: "12px",
          padding: "8px",
          marginBottom: "5px",
          borderRadius: "10px",
          color: "black",
          // borderBottom:"1px solid #C5C5C5C5"
        };
      },
    };

    const optionPropertyStyle = {
      control: (styles) => ({
        ...styles,
        backgroundColor: "white",
        width: "130px",
        border: "1px solid #D3D3D3",
        borderRadius: "10px",
        marginLeft: "0.8rem",
        height: "20px",
        overflow: "wrap ",
        fontSize: "10px",
        marginRight: "3px",
        marginTop: "0.30rem",
        textAlign: "center",
        fontWeight: "500",
      }),
      menu: (base) => ({
        ...base,
        zIndex: 10,
        overflow: "wrap",
        fontSize: "13px",
        width: "200px",
        borderRadius: "20px",
        padding: "10px",
      }),

      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isSelected ? "#bb6bd9" : "white",
          color: isSelected ? "white" : "black",
          borderRadius: "10px",
          marginBottom: "5px",
          padding: "8px",
          fontSize: "12px",
          fontWeight: isSelected ? 700 : 500,
        };
      },
    };

    const dataTypeStyles = {
      control: (styles) => ({
        ...styles,
        backgroundColor: "white",
        width: "45px",
        borderLeft: "1px solid #D3D3D3",
        height: "30px",
        overflow: "wrap ",
        marginLeft: "0px",
        fontSize: "12px",
        borderRadius: "0px 10px 10px 0px",
        textAlignLast: "center",
        borderRight: "1px solid #D3D3D3",
        borderTop: "1px solid #D3D3D3",
      }),

      menu: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          width: "60px",
          borderRadius: "8px",
          padding: "4px",
        };
      },
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          borderRadius: "10px",
          padding: "6px",
          marginBottom: "4px",
          textAlign: "center",
        };
      },
    };
    const operatorTypeSelect = {
      control: (styles) => ({
        ...styles,
        backgroundColor: "white",
        width: "45px",
        borderLeft: "1px solid #D3D3D3",
        marginRight: "5px",
        height: "30px",
        overflow: "wrap ",
        marginLeft: "0px",
        fontSize: "12px",
        borderRadius: "10px",
        textAlignLast: "center",
        borderRight: "1px solid #D3D3D3",
        borderTop: "1px solid #D3D3D3",
      }),

      menu: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          width: "80px",
          borderRadius: "10px",
          padding: "10px",
        };
      },

      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          borderRadius: "10px",
          padding: "6px",
          marginBottom: "4px",
          textAlign: "center",
        };
      },
    };

    const configTypeStyle = {
      menuPortal: (base) => ({ ...base, zIndex: 20 }),
      control: (styles) => ({
        ...styles,
        // backgroundColor: "white",
        width: "35px",
        borderLeft: "1px solid #D3D3D3",
        marginRight: "5px",
        height: "30px",
        overflow: "wrap ",
        marginLeft: "5px",
        fontSize: "12px",
        borderRadius: "10px",
        textAlignLast: "center",
        borderRight: "1px solid #D3D3D3",
        borderTop: "1px solid #D3D3D3",
        color: "black",
      }),

      menu: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          width: "170px",
          borderRadius: "4px",
          padding: "10px",
          backgroundColor: "#F9F9F9",
          zIndex: 9999,
        };
      },

      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          borderRadius: "10px",
          paddingTop: "8px",
          paddingBottom: "4px",
          marginBottom: "4px",
          textAlign: "center",
          // backgroundColor: isFocused ? "#BB6BD9" : "white",
          color: isFocused ? "#BB6BD9" : "#828282",
          fontWeight: isFocused ? 500 : 400,
          zIndex: 999,
        };
      },
    };
    const stepStyle = {
      control: (styles) => ({
        ...styles,
        backgroundColor: "white",
        width: "160px",
        border: "1px solid #D3D3D3",
        borderRadius: "10px",
        marginLeft: "8px",
        height: "30px",
        overflow: "wrap ",
        fontSize: "12px",
        marginRight: "3px",
      }),
      menu: (base) => ({
        ...base,
        zIndex: 10,
        overflow: "wrap",
        fontSize: "13px",
        width: "250px",
        borderRadius: "20px",
        padding: "10px",
      }),

      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isSelected ? "#bb6bd9" : "white",
          color: "black",
          borderRadius: "10px",
          marginBottom: "5px",
          padding: "8px",
          fontSize: "12px",
        };
      },
    };

    const filterStyle = {
      control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
        ...styles,
        backgroundColor: "white",
        width: isFocused ? "350px" : "145px",
        zIndex: 1,
        borderLeft: "1px solid #D3D3D3",
        marginRight: "5px",
        height: "30px",
        overflow: "auto ",
        marginLeft: isFocused ? "-220px" : "10px",
        fontSize: "12px",
        borderRadius: "10px",
        textAlignLast: "center",
        borderRight: "1px solid #D3D3D3",
        borderTop: "1px solid #D3D3D3",
      }),

      placeholder: (
        defaultStyles,
        { data, isDisabled, isFocused, isSelected }
      ) => ({
        ...defaultStyles,
        lineHeight: "25px",
        color: isFocused ? "white" : "#D5D5D5",
      }),

      menu: (base) => ({
        ...base,
        zIndex: 3,
        overflow: "wrap",
        fontSize: "13px",
        width: "380px",
        height: "300px",
        overflow: "wrap",
        marginLeft: "-220px",
        borderRadius: "20px",
        border: "1px solid #D3D3D3",
        padding: "8px",
      }),

      valueContainer: (provided, state) => ({
        ...provided,
        textOverflow: "ellipsis",
        maxWidth: "90%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        display: "initial",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }),
    };

    const ValueContainer = ({ children, ...props }) => {
      return (
        components.ValueContainer && (
          <components.ValueContainer {...props}>
            {!!children && (
              <img
                src={ConfigIcon}
                style={{ marginTop: "-16px", padding: "6px" }}
              />
            )}
            {children}
          </components.ValueContainer>
        )
      );
    };

    const handleOptionPropertyChange = (e) => {
      setSelectedPropertyOption(e);
    };
    const handleOptionTypeChange = (e) => {
      console.log("e - ", e);
      setSelectedOption(e);
      if (e.value == "Total" || e.value == "Unique") {
        setDisplayOptionProperties(false);
      } else {
        setDisplayOptionProperties(true);
        generatePropertiesOptions();
      }
    };

    const dataTypeOptionChanged = (e) => {
      const selectedValue = e.value;
      if (selectedValue === "string") {
        const operators = [
          { label: "=", value: "is" },
          { label: "≠", value: "is not" },
          { label: "∈", value: "contains" },
          { label: "∉", value: "does not contains" },
        ];
        setSelectedOperator({ label: "=", value: "is" });
        setOperatorOptions(operators);
        setSelectedDataType({ label: "Aa", value: "string" });
      } else if (selectedValue === "number") {
        const operators = [
          { label: "=", value: "equals" },
          { label: "≠", value: "not equals" },
          { label: ">", value: "greater than" },
          { label: "<", value: "less than" },
          // {label:"∈", value:"between"}, {label:"∉", value:"not between"}
        ];

        setSelectedDataType({ label: "#", value: "number" });
        setSelectedOperator({ label: "=", value: "equals" });
        setOperatorOptions(operators);
      } else if (selectedValue === "boolean") {
        const operators = [
          { label: "true", value: "0" },
          { label: "false", value: "1" },
        ];
        setSelectedOperator({ label: "true", value: "0" });
        setSelectedDataType({ label: "0/1", value: "boolean" });
        setOperatorOptions(operators);
      }
    };
    const handleStepSelectionChange = (selectedOption) => {
      generatePropertiesOptions(selectedOption.value);
      if (filterDisplay) {
        setSelectedStep(selectedOption);
      } else {
        setSelectedStep(selectedOption);
      }
    };

    // useEffect(() => {
    //   if (autoFillingData) {
    //     console.log("only autofiiling");
    //     setAutoFillingData(false);
    //   } else if (selectedStep) {
    //     setPersonName([]);
    //     setFilterOptions("");
    //     setSelectedProperty("");
    //     setPropertyOptions("");
    //     generatePropertiesOptions();
    //   }
    // }, [selectedStep]);
    const operatorChanged = (selected) => {
      setSelectedOperator(selected);
    };

    const filterChanged = (selected) => {
      // console.log("filter changed...");
      // console.log(selected);
      setPersonName(selected);
    };
    const propertyOptionChanged = (selectedOption) => {
      console.log("property option is chaneged!!!");
      setDisabledFilterSelector(true);
      setPersonName([]);
      setSelectedProperty(selectedOption);
      // console.log("new selected property is : " + selectedOption.value);
      // const allData = cloneDeep(allFilterDataRef.current);
      triggerLoading(true);
      const InlineFilterAPI =
        "https://7mbhokze6g.execute-api.us-east-2.amazonaws.com/valueFunnel/value?event=" +
        selectedStep.value +
        "&kit=" +
        kit +
        "&property=" +
        selectedOption.value;
      axios({
        method: "get",
        url: InlineFilterAPI,
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => {
          triggerLoading(false);
          setDisabledFilterSelector(false);
          const datatype = cloneDeep(response.data.result.datatype);
          const filterList = cloneDeep(response.data.result.value);
          const allFilters = [];
          // console.log(response.data);
          // console.log("filter list");
          // console.log(filterList);
          // console.log("data type: ");
          // console.log(datatype);
          filterList.forEach((data) => {
            allFilters.push({ label: data, value: data });
          });
          setFilterOptions(allFilters);

          if (datatype === "String") {
            setSelectedDataType({ value: "string", label: "Aa" });
            dataTypeOptionChanged({ value: "string", label: "Aa" });
          } else if (datatype === "Number") {
            setSelectedDataType({ value: "number", label: "#" });
            dataTypeOptionChanged({ value: "number", label: "#" });
          }
          if (datatype === "Boolean") {
            setSelectedDataType({ value: "boolean", label: "0/1" });
            dataTypeOptionChanged({ value: "boolean", label: "0/1" });
          }
        })
        .catch((err) => {
          triggerLoading(false);
          console.log("error in getting inline filters");
          console.log(err);
        });
    };

    const generatePropertiesOptions = (selectedEvent = null) => {
      let currentSelectedStepValue = selectedStep?.value;
      if (selectedEvent !== null) {
        currentSelectedStepValue = selectedEvent;
      }
      let selectedStepValue = selectedStep?.value;
      setPropertyPlaceholder("loading...");
      setDisabledPropertySelector(true);
      setOperatorOptions("");
      setFilterOptions("");
      setPersonName([]);
      setOperatorOptions("");
      const InlineFilterAPI =
        "https://7mbhokze6g.execute-api.us-east-2.amazonaws.com/PropertyFunnel?event=" +
        currentSelectedStepValue +
        "&kit=" +
        kit;
      axios({
        method: "get",
        url: InlineFilterAPI,
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => {
          // triggerLoading(false);
          setDisabledPropertySelector(false);
          setPropertyPlaceholder("properties");
          // console.log("inline filter response: ");
          // console.log(response.data.result);
          const dataArray = cloneDeep(response.data.result.properties);
          // allFilterDataRef.current = dataArray;
          const allProperties = [];
          console.log(dataArray);
          dataArray.forEach((data) => {
            allProperties.push({ label: data, value: data });
          });
          // console.log("all properties: ");
          // console.log(allProperties);
          setPropertyOptions(allProperties);
        })
        .catch((err) => {
          triggerLoading(false);
          console.log("error in getting inline filters");
          console.log(err);
        });
    };
    const filterIconClicked = () => {
      if (filterDisplay === true) {
        console.log("filter is already true");
        // getSelectedData();
        setFilterDisplay(!filterDisplay);
      } else {
        // console.log("selected step: " + selectedStep + " , kit: " + kit);
        setFilterDisplay(!filterDisplay);
        generatePropertiesOptions();
        // triggerLoading(true)
      }
    };

    const loadConfigOptions = (configName) => {
      const configIcon =
        configName === "Duplicate" ? DuplicatIcon : CustomFilterIcon;
      return (
        <li
          className="dropdown-submenu dropdown-submenu-settings"
          id={"config-option-" + configName + "-" + id}
          style={{ paddingBottom: "8px" }}>
          <div
            style={{
              display: "flex",
              border: "1px solid #d5d1d145",
              padding: "",
            }}>
            <div style={{ width: "158px", padding: "4px" }}>
              <img
                src={configIcon}
                alt=""
                style={{
                  marginRight: "8px",
                  marginLeft: "2px",
                  fontWeight: "500",
                }}
              />
              <a
                style={{
                  fontStyle: "none",
                  textDecoration: "none",
                  fontSize: "14px",
                  color: "#6e6e6e",
                  fontWeight: "500",
                }}
                id={"config-item-" + configName + "-" + id}
                tabindex="-1"
                href="#">
                {configName}
              </a>
            </div>
          </div>
        </li>
      );
    };
    const toggelConfigOptions = () => {
      const mainDropdown = document.getElementById(
        "config-main-dropdown-" + id
      );
      if (mainDropdown) {
        const currentStyle = mainDropdown.style.display;
        mainDropdown.style.display =
          currentStyle === "block" ? "none" : "block";
      }
    };
    const multiValueContainer = ({ selectProps, data }) => {
      const label = data.label;
      const allSelected = selectProps.value;
      const allLabels = [];
      const index = allSelected.findIndex(
        (selected) => selected.label === label
      );
      const isLastSelected = index === allSelected.length - 1;
      const labelSuffix = isLastSelected ? ` (${allSelected.length})` : ", ";
      const val = `${label}${labelSuffix}`;
      return val;
    };

    const configOptionChanged = (option) => {
      if (option.value.includes("Filter")) {
        filterDisplay ? setFilterDisplay(false) : setFilterDisplay(true);
        generatePropertiesOptions();
      } else {
        sendDuplicatedEvent({
          selectedStep,
          propertyOptions,
          selectedDataType,
          selectedOperator,
          selectedProperty,
          operatorOptions,
          selectedOperator,
          dataTypeOptions,
          selectedDataType,
          filterOptions,
          isFilter: filterDisplay,
          selectedFilters: personName,
        });
      }
    };
    return (
      <>
        {featureType === "Insight" ? (
          <div style={{ display: "flex" }}>
            <Select
              id={"option-type-selector-" + id}
              closeMenuOnSelect={false}
              components={{
                // DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              closeMenuOnSelect
              // placeholder="select step"
              styles={optionStyle}
              value={selectedOption}
              options={optionTypeList}
              onChange={handleOptionTypeChange}
              menuPlacement="auto"
              menuPortalTarget={document.body}
              menuPosition={"fixed"}

              // menuPosition="fixed"
            />
            {displayOptionProperties ? (
              <Select
                id={"option-type-selector-" + id}
                closeMenuOnSelect={false}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                closeMenuOnSelect
                // placeholder="select step"
                styles={optionPropertyStyle}
                value={selectedPropertyOption}
                options={propertyOptions}
                onChange={handleOptionPropertyChange}
                menuPlacement="auto"
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                placeholder="property"
                isLoading={propertyOptions.length === 0}
                isDisabled={propertyOptions.length === 0}
                // menuPosition="fixed"
              />
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "5px",
            marginBottom: "15px",
            flexWrap: "wrap",
            marginRight: "8px",
          }}>
          <Badge bg="secondary" className="badge-class">
            {index + 1}
          </Badge>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            {/* steps selector dropdown */}
            <Select
              id={"steps-selector-" + id}
              closeMenuOnSelect={false}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              closeMenuOnSelect
              placeholder="select step"
              styles={stepStyle}
              value={selectedStep}
              options={stepsOptions}
              onChange={handleStepSelectionChange}
              menuPlacement="auto"
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              isDisabled={isDisabledStepSelector}

              // menuPosition="fixed"
            />
          </div>

          {filterDisplay ? (
            <>
              <div style={{ display: "flex", width: "170px" }}>
                {/* property selector dropdown */}
                <Select
                  closeMenuOnSelect={false}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  closeMenuOnSelect
                  placeholder={propertyPlaceholder}
                  styles={propertyStyle}
                  value={selectedProperty}
                  options={propertyOptions}
                  onChange={propertyOptionChanged}
                  className="filter-selection-select"
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  isDisabled={isDisabledPropertySelector}
                />

                {/* data type selector dropdown */}
                <Select
                  closeMenuOnSelect={false}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  closeMenuOnSelect
                  placeholder="Aa"
                  value={selectedDataType}
                  menuPlacement="auto"
                  menuPosition="fixed"
                  onChange={dataTypeOptionChanged}
                  styles={dataTypeStyles}
                  options={dataTypeOptions}
                  className="filter-selection-select"
                />
              </div>

              {/* operator dropdown ex, = / AND-OR */}
              <Select
                closeMenuOnSelect={false}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                closeMenuOnSelect
                menuPlacement="auto"
                menuPosition="fixed"
                defaultValue={defaultDropdownValue}
                styles={operatorTypeSelect}
                options={operatorOptions}
                value={selectedOperator}
                onChange={operatorChanged}
                className="filter-selection-select"
              />

              {/* filter checkbox */}
              {/* <MUISelect
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={personName}
            displayEmpty
            onChange={handleChange}
            input={<OutlinedInput id="filter-multiple" label="" style={{color:"grey", marginLeft:"10px", height:"41px", fontSize:"12px"}} />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={{ classes: { paper: classes.select } }}
            className={classes.root}
          >
            {filterOptions.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={personName.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </MUISelect> */}

              {selectedOperator != null &&
              selectedOperator.value.includes("is") ? (
                <Select
                  isMulti
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    MultiValueContainer: multiValueContainer,
                  }}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  styles={filterStyle}
                  options={filterOptions}
                  isSearchable={true}
                  value={personName}
                  onChange={filterChanged}
                  className="filter-selection-select"
                  isDisabled={isDisabledFilterSelector}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                />
              ) : (
                <input
                  type="text"
                  name="select-filter"
                  id={"select-filter-contains-" + id}
                  placeholder={selectedOperator.value}
                  style={{
                    width: "142px",
                    borderRadius: "20px",
                    padding: "12px",
                    color: "#4c4c4c",
                    fontSize: "12px",
                    height: "40px",
                    border: "1px solid #D3D3D3",
                  }}
                />
              )}
              <Select
                closeMenuOnSelect={false}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                  ValueContainer,
                }}
                closeMenuOnSelect
                menuPlacement="auto"
                menuPosition="fixed"
                styles={configTypeStyle}
                options={configData}
                getOptionLabel={(e) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {e.icon}
                    <span style={{ marginLeft: 5 }}>{e.text}</span>
                  </div>
                )}
                isSearchable={false}
                onChange={configOptionChanged}
                className="filter-selection-select"
                value={{ lebel: "~", value: "~" }}
              />
              <RemoveCircleOutlineRoundedIcon
                onClick={() => remove(id)}
                style={{
                  marginLeft: "1vw",
                  fontWeight: "bold",
                  color: "rgb(203 142 85)",
                  cursor: "pointer",
                }}
              />
            </>
          ) : (
            <>
              <Select
                closeMenuOnSelect={false}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                  ValueContainer,
                }}
                closeMenuOnSelect
                menuPlacement="auto"
                menuPosition="fixed"
                styles={configTypeStyle}
                options={configData}
                getOptionLabel={(e) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {e.icon}
                    <span style={{ marginLeft: 5 }}>{e.text}</span>
                  </div>
                )}
                isSearchable={false}
                onChange={configOptionChanged}
                className="filter-selection-select"
                value={{ lebel: "~", value: "~" }}
              />
              <RemoveCircleOutlineRoundedIcon
                onClick={() => remove(id)}
                style={{
                  marginLeft: "1vw",
                  fontWeight: "bold",
                  color: "rgb(203 142 85)",
                  cursor: "pointer",
                }}
              />
            </>
          )}
        </div>
        <SyncLoader
          color="#e09e53"
          id={"panel-" + id + "-loader"}
          loading={loading}
          css={override}
          size={15}
        />
      </>
    );
  }
);

export default DropdownSelection;
