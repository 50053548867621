import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import "./DropOff.scss";
import { nanoid } from "nanoid";
import SyncLoader from "react-spinners/SyncLoader";
import { cloneDeep } from "lodash";
import axios from "axios";
import { css } from "@emotion/react";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import DropdownSelection from "../MainPanel/SelectionPanel/DropdownSelection";
import SettingsIcon from "@mui/icons-material/Settings";
import Tooltip from "@mui/material/Tooltip";
import TodayIcon from "@mui/icons-material/Today";
import ChartPanel2 from "../MainPanel/ChartPanel2/ChartPanel2";
import LegendToggleIcon from "@mui/icons-material/LegendToggle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import StringToColor from "string-to-color";
import forwardArrow from "../../assets/forward-arrow.svg";
import BucketIcon from "../../assets/bucket_icon.svg";
import Modal from "react-modal";

const DropOff = (
  { id, previousStateData, featureTypeChanged, gameKits },
  ref
) => {
  const override = css`
    position: relative;
    left: 45%;
    top: -45%;
    z-index: 999;
  `;
  const [feaureOptions, setFeatureOptions] = useState([
    "Date Range",
    "User Type",
  ]);

  // only show 20 data points
  Modal.setAppElement("#root");
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "30px",
      height: "220px",
      width: "400px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      border: "3px solid #F2F2F2",
    },
  };
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const savedPreviousStateDataRef = useRef({});
  const dataPointsLimit = 20;
  const chartRef = useRef();
  const dropOffSelectedDataRef = useRef("");
  const dataFormatRef = useRef("Linear");
  const dropOffResponseRef = useRef("");
  const breakdownAppliedRef = useRef(true);
  const bucketDropdownOptions = [{ Bucket: [1, 2, 5, 10, 20, 50] }];
  const [selectedBucket, setSelectedBucket] = useState(1);
  const [dataFormatLabel, setDataFormatLabel] = useState("Linear");
  const userTypeOptions = [
    "All Users",
    "Specific Email",
    "One Time Users",
    "Non One Time Users",
    "Power User",
  ];
  const xAxisOptions = [
    "Active Days",
    "Levels Completed",
    "Sessions Played",
    "Time Spent",
    "Calendar Days",
  ];
  const breakdownOptions = ["Breakdown", "App Version", "Grade", "Platform"];
  const dateRangeOptions = [
    "Last 7 Days",
    "Last 30 Days",
    "This Month",
    "Last 3 Months",
    "Last 6 Months",
    "Lifetime",
  ];

  const bucketSizeRef = useRef(1);
  const [selectedEmail, setSelectedEmail] = useState("");
  const chartDataFormatRef = useRef("Linear");
  const [headerLine1, setHeaderLine1] = useState("");
  const [headerLine2, setHeaderLine2] = useState("");
  const [selectedBreakdown, setSelectedBreakdown] = useState("");
  const selectedBreakdownRef = useRef("");
  const [isBreakdownApplied, setBreakdownApplied] = useState(true);
  const [featureTypeOptions, setFeatureTypeOptions] = useState([
    "Insight",
    "Distribution",
    "Funnel",
  ]);
  const bucketOptions = [1, 2, 5, 10, 15, 20, 30, 40, 50];
  const kitOptions = [
    { Plugo: ["Plugo"] },
    { Tacto: ["Tacto"] },
    { Orboot: ["Orboot Earth", "Orboot Mars"] },
  ];
  const [gameOptions, setGameOptions] = useState(["Games"]);
  const [displayingChart, setDisplayingChart] = useState(false);
  const [chartData, setChartData] = useState("");
  const [chartType, setChartType] = useState("bar");
  const [dropdownPanels, setDropdownPanels] = useState([
    { id: nanoid(), value: "" },
  ]);
  const allAvailableEventsRef = useRef(
    previousStateData ? previousStateData.all_available_events : ""
  );
  const stepListRef = useRef([]);
  const [eventsData, setEventsData] = useState([]);
  const [selectedKit, setSelectedKit] = useState("");
  let [loading, setLoading] = useState(false);

  document.addEventListener("mouseup", function (e) {
    var container = document.getElementById("game-main-dropdown-dropoff-" + id);
    if (container && !container.contains(e.target)) {
      container.style.display = "none";
    }
  });

  async function gameOptionSelected(platform, kit, game) {
    const gameAnchor = document.getElementById("games-div-lable-" + id);
    gameAnchor.text = kit;
    Object.entries(gameKits).forEach((gameKit) => {
      const [platformKey, platformValue] = gameKit;
      if (platform === platformKey) {
        Object.entries(platformValue).forEach((kits) => {
          const [kitKey, kitValue] = kits;
          if (kitKey === kit) setGameOptions(kitValue);
        });
      }
    });
    document.getElementById("games-platformdata-" + id).value = platform;
    document.getElementById("games-kitdata-" + id).value = kit;
  }

  const fillFeatureData = (option) => {
    if (option === "Date Range") {
      return (
        <select
          className="selection-modifier-insight"
          id={"dropoff-date-" + id}
          key={"dropoff-date-" + id}>
          {dateRangeOptions.map((date) => (
            <option value={date}>{date}</option>
          ))}
        </select>
      );
    } else if (option === "Breakdown") {
      return (
        <select
          className="selection-modifier-insight"
          id={"dropoff-breakdown-" + id}
          key={"dropoff-breakdown-" + id}>
          {breakdownOptions.map((breakdown) =>
            breakdown === selectedBreakdown ? (
              <option value={breakdown} selected>
                {breakdown}
              </option>
            ) : (
              <option value={breakdown}>{breakdown}</option>
            )
          )}
        </select>
      );
    } else if (option === "Kit") {
      return (
        <div
          id={"games-div-" + id}
          className="selection-modifier-insight"
          style={{ width: "135px", padding: "4px" }}
          onClick={() => toggleclasses()}>
          <a
            id={"games-div-lable-" + id}
            role="button"
            selected="true"
            className="game-title-a"
            style={{ verticalAlign: "sub" }}>
            Kits
          </a>
          <input type="hidden" id={"games-platformdata-" + id}></input>
          <input type="hidden" id={"games-kitdata-" + id}></input>
          <ul
            className="dropdown-menu"
            id={"game-main-dropdown-" + id}
            role="menu"
            style={{ display: "none" }}>
            {kitOptions.map((kitOption) => {
              const platform = Object.keys(kitOption)[0];
              const kits = Object.values(kitOption)[0];
              return generatePlatformAndKits(platform, kits);
            })}
          </ul>
        </div>
      );
    } else if (option === "User Type") {
      return (
        <select
          id={"dropOff-usertype" + id}
          className="dropoff-main-select"
          onChange={onUserTypeChange}
          style={{ width: "150px" }}>
          {userTypeOptions.map((option) => (
            <option value={option}>{option}</option>
          ))}
        </select>
      );
    }
  };

  const onUserTypeChange = (e) => {
    // console.log("user type cahnge - ", e.target.value);
    const userType = e.target.value;
    if (userType === "Specific Email") {
      setIsOpen(true);
    } else {
      setSelectedEmail("");
    }
  };
  const generatePlatformAndKits = (platform) => {
    if (!gameKits) return;
    let kits = Object.keys(gameKits[platform]);
    return (
      <li
        className="dropdown-submenu"
        id={"game-option-" + platform + "-" + id}>
        <a
          id={"level-1-" + platform + "-" + id}
          className="dropdown-item"
          tabindex="-1"
          href="#">
          {platform}
        </a>
        <ul
          className="dropdown-menu dropdown-menu-right-open"
          id={"option-level2-" + platform + "-" + id}>
          <div id={"div-level2-" + platform + "-" + id}>
            {kits.map((kit) => {
              const identifier = platform + "-" + kit + "-" + id;
              return (
                <li
                  id={"li-option-level2-" + identifier}
                  onClick={() => gameOptionSelected(platform, kit)}>
                  <a
                    id={"level-2-" + platform + identifier}
                    className="dropdown-item"
                    href="#">
                    {" "}
                    {kit.split("_").join(" ")}{" "}
                  </a>
                </li>
              );
            })}
          </div>
        </ul>
      </li>
    );
  };

  const toggleclasses = () => {
    const mainDropdown = document.getElementById("game-main-dropdown-" + id);
    if (mainDropdown) {
      const currentStyle = mainDropdown.style.display;
      mainDropdown.style.display = currentStyle === "block" ? "none" : "block";
    }
  };

  function formatDate(date) {
    let fromDate = new Date();
    let toDate = new Date();
    if (date.includes("7 Days")) {
      fromDate.setDate(fromDate.getDate() - 7);
    } else if (date.includes("30 Days")) {
      fromDate.setMonth(fromDate.getMonth() - 1);
    } else if (date.includes("This Month")) {
      fromDate.setDate(1);
    } else if (date.includes("3 Months")) {
      fromDate.setMonth(fromDate.getMonth() - 3);
    } else if (date.includes("6 Months")) {
      fromDate.setMonth(fromDate.getMonth() - 6);
    } else if (date.includes("Lifetime")) {
      fromDate = "";
      toDate = "";
    } else {
      fromDate = "";
      toDate = "";
    }
    if (fromDate !== "")
      return [
        fromDate.getFullYear() +
          "-" +
          String(fromDate.getMonth() + 1).padStart(2, "0") +
          "-" +
          String(fromDate.getDate()).padStart(2, "0"),
        toDate.getFullYear() +
          "-" +
          String(toDate.getMonth() + 1).padStart(2, "0") +
          "-" +
          String(toDate.getDate()).padStart(2, "0"),
      ];
    else return ["", ""];
  }

  const validateSelectionBeforeSubmit = async () => {
    // do the validation before submit
    let breakdownApplied = true;
    breakdownAppliedRef.current = true;
    const selectedXAxis = formatRequest(
      document.getElementById("dropOff-xAxis" + id).value
    );
    const selectedDate = document.getElementById("dropoff-date-" + id).value;
    /*let selectedBreakdown = document.getElementById(
      "dropoff-breakdown-" + id
    ).value;*/
    const selectedUserType = formatRequest(
      document.getElementById("dropOff-usertype" + id).value
    );
    const selectedPlatform = document
      .getElementById("games-platformdata-" + id)
      .value.split(" ")
      .join("_");
    const selectedKit = document
      .getElementById("games-kitdata-" + id)
      .value.split(" ")
      .join("_");
    const selectedGame = document.getElementById("dropOff-games" + id).value;
    const [from_date, to_date] = formatDate(selectedDate);
    /*if (selectedBreakdown.includes("Grade")) selectedBreakdown = "where_grade";
    else if (selectedBreakdown.includes("Version"))
      selectedBreakdown = "where_app";
    else if (selectedBreakdown.includes("Platform"))
      selectedBreakdown = "where_platform";
    else {
      breakdownAppliedRef.current = false;
      selectedBreakdown = "";
    })*/
    breakdownAppliedRef.current = false;
    let selectedBreakdown = "";
    // save state data for autifill when going back to selection
    savedPreviousStateDataRef.current = {
      XAxis: document.getElementById("dropOff-xAxis" + id).value,
      selectedDate,
      games: selectedGame,
      userType: document.getElementById("dropOff-usertype" + id).value,
      breakdown: selectedBreakdown,
      platform: document.getElementById("games-platformdata-" + id).value,
      kit: document.getElementById("games-kitdata-" + id).value,
      from_date: from_date,
      to_date: to_date,
    };
    const data = {
      filter1: selectedUserType,
      main: selectedXAxis,
      platform: selectedPlatform,
      kit: selectedKit,
      from_val: selectedGame,
      from_date,
      to_date,
      email_id: selectedEmail,
      grouper: selectedBreakdown,
    };

    dropOffSelectedDataRef.current = cloneDeep(data);

    console.log("Dropoff payload data: ", data);
    const dropOffResponse = await getDropOffResponse(data);
    if (dropOffResponse) {
      setHeaderLine1(
        document.getElementById("dropOff-usertype" + id).value +
          " Drop-off vs " +
          document.getElementById("dropOff-xAxis" + id).value
      );
      setHeaderLine2(
        document
          .getElementById("games-kitdata-" + id)
          .value.split("_")
          .join(" ") +
          " " +
          document
            .getElementById("dropOff-games" + id)
            .value.split("_")
            .join(" ")
      );
      dropOffResponseRef.current = cloneDeep(dropOffResponse);
      generateChartForDropOff();
      setDisplayingChart(true);
    }

    // submitSelection(selectionData);
  };

  const serializeData = (dataReceived, bucketSize) => {
    const data = cloneDeep(dataReceived);
    const newData = { x: [], y: [] };
    let iter = 1;
    if (data.x[0] === 0) iter = 0;
    let idx = 0;
    console.log("inside serialize,", data);
    while (iter <= dataPointsLimit * bucketSize && idx < data.x.length) {
      if (data.x[idx] === iter) {
        newData.x.push(iter);
        newData.y.push(data.y[idx]);
        iter++;
        idx++;
      } else {
        newData.x.push(iter);
        newData.y.push(0);
        iter++;
      }
    }
    console.log("inside serialize,", data);
    return newData;
  };
  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
  function getRandomColor() {
    const colors = ["#4287f5", "#8be640", "#0f120d", "#939492"];
    const random = getRandomInt(colors.length);
    return colors[random];
  }
  // const stringToColour = (str) => {
  //   let hash = 0;
  //   for (let i = 0; i < str.length; i++) {
  //     hash = str.charCodeAt(i) + ((hash << 5) - hash);
  //   }
  //   var colour = "#";
  //   for (var i = 0; i < 3; i++) {
  //     var value = (hash >> (i * 8)) & 0xff;
  //     colour += ("00" + value.toString(16)).substr(-2);
  //   }

  //   return colour;
  // };

  const onBreakdownChange = async (val) => {
    let breakdownVal = val.target.value;
    breakdownAppliedRef.current = true;
    if (breakdownVal.includes("Grade")) breakdownVal = "where_grade";
    else if (breakdownVal.includes("Version")) breakdownVal = "where_app";
    else if (breakdownVal.includes("Platform")) breakdownVal = "where_platform";
    else {
      breakdownAppliedRef.current = false;
      breakdownVal = "";
    }
    setSelectedBreakdown(breakdownVal);
    selectedBreakdownRef.current = breakdownVal;

    savedPreviousStateDataRef.current["breakdown"] = breakdownVal;
    const data = {
      filter1: savedPreviousStateDataRef.current.userType
        .toLowerCase()
        .split(" ")
        .join("_"),
      main: savedPreviousStateDataRef.current.XAxis.toLowerCase()
        .split(" ")
        .join("_"),
      platform: savedPreviousStateDataRef.current.platform,
      kit: savedPreviousStateDataRef.current.kit,
      from_val: savedPreviousStateDataRef.current.games,
      from_date: savedPreviousStateDataRef.current.from_date,
      to_date: savedPreviousStateDataRef.current.to_date,
      grouper: breakdownVal,
    };

    dropOffSelectedDataRef.current = cloneDeep(data);
    console.log("Dropoff payload data: ", data);
    setLoading(true);
    const dropOffResponse = await getDropOffResponse(data);
    setLoading(false);
    dropOffResponseRef.current = cloneDeep(dropOffResponse);
    generateChartForDropOff();
  };

  const generateChartForDropOff = () => {
    console.log("generate chart for drop offf is called!!!");
    let bucketSize = bucketSizeRef.current;
    let data = cloneDeep(dropOffResponseRef.current);

    if (breakdownAppliedRef.current) {
      // line chart
      const backgroundColor = "rgba(100, 194, 245,0.7)";
      const borderColor = "rgba(100, 194, 245,0.7)";
      const borderWidth = 1;
      const hoverBackgroundColor = "rgba(100, 194, 245,0.9)";
      const hoverBorderColor = "rgba(100, 194, 245,0.7)";
      let labels = [];
      const datasets = [];
      const storeLabels = [];
      let dsCount = 0;
      Object.entries(data).forEach((dsEntries) => {
        let [dataset, data] = dsEntries;
        let sumOfYAxes = 0;
        data.y.forEach((d) => (sumOfYAxes += d));
        data.x = data.x.splice(0, dataPointsLimit * bucketSize);
        data.y = data.y.splice(0, dataPointsLimit * bucketSize);
        data = serializeData(data, bucketSize);
        console.log("after serialization - ", data.x);
        let cummulative = 0;
        let iter = 1;
        console.log("data[x] = ", data.x);
        if (data.x[0] === 0) iter = 0;
        // bucket logic
        let newChartData = { x: [], y: [] };
        for (let index = 0; index < data.y.length; index++) {
          const bucketPoint = bucketSize * iter;
          if (data.x[index] <= bucketPoint) {
            cummulative += data.y[index];
          } else {
            index--;
            newChartData.x.push(bucketPoint);
            newChartData.y.push(cummulative);
            cummulative = 0;
            iter++;
          }
          if (index === data.y.length - 1) {
            newChartData.x.push(bucketPoint);
            newChartData.y.push(cummulative);
          }
        }
        data = cloneDeep(newChartData);
        let yAxisTotal = 0;
        const yAxis = [];
        data.y.forEach((point) => (yAxisTotal += point));
        data.x.forEach((x) => {
          if (!isNaN(x)) storeLabels.push(x);
        });
        const dataPoints = [];
        if (dataFormatRef.current === "Cummulative") {
          for (let index = 0; index < data.y.length; index++) {
            data.y[index] += cummulative;
            cummulative = data.y[index];
          }
          const lastElem = data.y[data.y.length - 1];
          for (let index = 0; index < data.x.length; index++) {
            const pointX = data.x[index];
            const pointY = Math.floor((data.y[index] * 100) / lastElem);
            dataPoints.push({ x: pointX, y: pointY });
          }
        } else {
          for (let index = 0; index < data.x.length; index++) {
            const pointX = data.x[index];
            const pointY = Math.floor((data.y[index] * 100) / sumOfYAxes);
            dataPoints.push({ x: pointX, y: pointY });
          }
        }

        // const randomColor = getRandomColor();

        // const randomColor = stringToColour(dataset);
        // alert("string to color - " + dataset);
        const randomColor = StringToColor(dataset.toLowerCase());
        let isHidden = true;
        if (dsCount < 3 && dataPoints.length > 2) {
          dsCount++;
          isHidden = false;
        }
        datasets.push({
          label: dataset,
          borderColor: randomColor,
          borderWidth: 2,
          hoverBackgroundColor: randomColor,
          hoverBorderColor: randomColor,
          tension: 0.2,
          data: dataPoints,
          hidden: isHidden,
        });
        function onlyUnique(value, index, self) {
          return self.indexOf(value) === index;
        }
        let uniqueLabels2 = storeLabels.filter(onlyUnique);
        uniqueLabels2.sort(function (a, b) {
          return a - b;
        });
        labels = [...uniqueLabels2];

        const ds = { labels, datasets };
        console.log("final dataset generated");
        console.log(ds);
        setChartData(ds);
        setChartType("line");
        // alert("tye also updated!");
        chartRef.current?.updateChartAndType(ds, "line");
      });
    } else {
      // bar chart
      const yAxis = [];
      let yAxisTotal = 0;
      let cummulative = 0;
      let iter = 1;
      console.log("data[x] = ", data.x);
      if (data.x[0] === 0) {
        iter = 0;
      }
      let sumOfYAxes = 0;
      data.y.forEach((d) => (sumOfYAxes += d));
      // console.log("sum of y axes ", sumOfYAxes);

      data.x = data.x.splice(0, dataPointsLimit * bucketSize);
      data.y = data.y.splice(0, dataPointsLimit * bucketSize);
      // console.log("before serialize data: ", data.y);
      data = serializeData(data, bucketSize);
      // console.log("serialize data: ", data);
      let newChartData = { x: [], y: [] };
      console.log("after serialize - ", data);
      // combine multiple bar's values based on bucket size
      for (let index = 0; index < data.y.length; index++) {
        const bucketPoint = bucketSize * iter;
        if (data.x[index] <= bucketPoint) {
          cummulative += data.y[index];
        } else {
          index--;
          newChartData.x.push(bucketPoint);
          newChartData.y.push(cummulative);
          cummulative = 0;
          iter++;
        }
        if (index === data.y.length - 1) {
          newChartData.x.push(bucketPoint);
          newChartData.y.push(cummulative);
        }
      }
      data = cloneDeep(newChartData);
      console.log("after bucketing", data);
      // console.log("y before linear - ", data.y);
      if (dataFormatRef.current === "Linear") {
        // this is for linear data
        data.y.forEach((point) => (yAxisTotal += point));
        data.y.forEach((point) => yAxis.push((point * 100) / sumOfYAxes));
      } else {
        // this is for cummulative data
        for (let index = 0; index < data.y.length; index++) {
          data.y[index] += cummulative;
          cummulative = data.y[index];
        }
        const lastElem = data.y[data.y.length - 1];
        data.y.forEach((point) => yAxis.push((point * 100) / sumOfYAxes));
      }

      // console.log("y after linear - ", yAxis);
      const ds = {
        labels: data.x,
        datasets: [
          {
            label: "drop-off%",
            backgroundColor: "rgba(100, 194, 245,0.7)",
            borderColor: "rgba(100, 194, 245,0.7)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(100, 194, 245,0.9)",
            hoverBorderColor: "rgba(100, 194, 245,0.7)",
            borderRadius: 5,
            data: yAxis,
          },
        ],
      };
      console.log("final dataset generated");
      console.log(ds);
      setChartData(ds);
      setChartType("bar");
      // alert("type updated to bar");
      chartRef.current?.updateChartAndType(ds, "bar");
    }
  };
  const formatRequest = (data) => {
    // this function will convert request data to lowecase and underscore fromat
    // ex Active Days -> active_days
    const newData = data.split(" ").map((d) => d.toLowerCase());
    return newData.join("_");
  };
  const getDropOffResponse = async (payloadData) => {
    return new Promise((resolve, reject) => {
      setLoading(true);
      const dropOffQuery =
        "https://5nrevmwo7b.execute-api.us-east-2.amazonaws.com/dropoffQuery/v2";
      axios({
        method: "post",
        url: dropOffQuery,
        data: payloadData, //qs.stringify(selectionData),
        headers: { "Content-Type": "application/json" },
      })
        .then(function (response) {
          setLoading(false);
          // mainPanel.classed("loading-div",false);
          console.log("response received");
          response = response.data;
          console.log(response);
          if (response.success === 0) {
            alert("error in drop off query! please validate data");
            reject(null);
          } else resolve(response.from);
        })
        .catch(function (error) {
          console.log("error in drop off query!");
          console.log(error);
          alert("Error in drop off query, please validate selected options");
          setLoading(false);
          reject(error);
        });
    });
  };

  const onBackToSelectionClicked = () => {
    setDisplayingChart(false);
  };
  useEffect(() => {
    // filling up the previous state data if went back from chat to selection
    if (!displayingChart) {
      if (
        savedPreviousStateDataRef.current.XAxis &&
        document.getElementById("dropOff-xAxis" + id)
      ) {
        document.getElementById("dropOff-xAxis" + id).value =
          savedPreviousStateDataRef.current.XAxis;
      }
      if (savedPreviousStateDataRef.current.platform) {
        gameOptionSelected(
          savedPreviousStateDataRef.current.platform,
          savedPreviousStateDataRef.current.kit
        );
      }
      if (
        savedPreviousStateDataRef.current.games &&
        document.getElementById("dropOff-games" + id)
      ) {
        document.getElementById("dropOff-games" + id).value =
          savedPreviousStateDataRef.current.games;
      }

      if (
        savedPreviousStateDataRef.current.selectedDate &&
        document.getElementById("dropoff-date-" + id)
      ) {
        document.getElementById("dropoff-date-" + id).value =
          savedPreviousStateDataRef.current.selectedDate;
      }
      if (
        savedPreviousStateDataRef.current.userType &&
        document.getElementById("dropOff-usertype" + id)
      ) {
        document.getElementById("dropOff-usertype" + id).value =
          savedPreviousStateDataRef.current.userType;
      }

      if (
        savedPreviousStateDataRef.current.breakdown &&
        document.getElementById("dropoff-breakdown-" + id)
      ) {
        document.getElementById("dropoff-breakdown-" + id).value =
          savedPreviousStateDataRef.current.breakdown;
      }
    } else {
      // when going back to displaying chart
      if (bucketSizeRef.current) {
        // document.getElementById("bucket-select-" + id).value =
        //   bucketSizeRef.current;
        setSelectedBucket(bucketSizeRef.current);
      }
      if (
        savedPreviousStateDataRef.current.breakdown &&
        document.getElementById("dropoff-breakdown-" + id)
      ) {
        let val = "Breakdown";
        if (savedPreviousStateDataRef.current.breakdown === "where_app")
          val = "App Version";
        else if (
          savedPreviousStateDataRef.current.breakdown === "where_platform"
        )
          val = "Platform";
        else if (savedPreviousStateDataRef.current.breakdown === "where_grade")
          val = "Grade";
        document.getElementById("dropoff-breakdown-" + id).value = val;
      }
    }
  }, [displayingChart]);

  const updateChartDataFormat = () => {
    // toggeling button
    if (dataFormatLabel === "Linear") {
      setDataFormatLabel("Cummulative");
      dataFormatRef.current = "Cummulative";
    } else {
      setDataFormatLabel("Linear");
      dataFormatRef.current = "Linear";
    }
    generateChartForDropOff();
  };
  const handleBucketOptionSelected = (bucket) => {
    bucketSizeRef.current = bucket;
    setSelectedBucket(bucket);
    generateChartForDropOff();
  };

  const generateBucketOptions = (platform, kits) => {
    // console.log("kits received ---- ", kits);
    const openClass =
      id % 2 === 0 ? "dropdown-menu-right-open" : "dropdown-menu-left-open";
    return (
      <li
        className="dropdown-submenu dropdown-submenu-settings"
        id={"game-option-" + platform + "-" + id}>
        <div
          style={{
            display: "flex",
            border: "1px solid #d5d1d145",
            padding: "4px",
          }}>
          <div style={{ width: "158px", padding: "4px" }}>
            <img
              src={BucketIcon}
              alt=""
              style={{ marginRight: "8px", marginLeft: "2px" }}
            />
            <a
              style={{
                fontStyle: "none",
                textDecoration: "none",
                fontSize: "14px",
                color: "#6e6e6e",
              }}
              id={"level-1-" + platform + "-" + id}
              tabindex="-1"
              href="#">
              {platform + " - " + selectedBucket}
            </a>
          </div>
          <div xl="2" style={{ padding: "4px" }}>
            <img src={forwardArrow} alt="" style={{ marginRight: "8px" }} />
          </div>
        </div>

        <ul
          className={"dropdown-menu " + openClass}
          id={"option-level2-" + platform + "-" + id}>
          <div id={"div-level2-" + platform + "-" + id}>
            {kits.map((kit) => {
              const identifier = platform + "-" + kit + "-" + id;
              return (
                <li
                  id={"li-option-level2-" + identifier}
                  onClick={() => handleBucketOptionSelected(kit)}>
                  <a
                    id={"level-2-" + platform + identifier}
                    className="dropdown-item"
                    href="#">
                    {" "}
                    {kit}{" "}
                  </a>
                </li>
              );
            })}
          </div>
        </ul>
      </li>
    );
  };

  const toggleSettingclasses = () => {
    const mainDropdown = document.getElementById(
      "game-main-dropdown-dropoff-" + id
    );
    if (mainDropdown) {
      const currentStyle = mainDropdown.style.display;
      mainDropdown.style.display = currentStyle === "block" ? "none" : "block";
    }
  };

  return (
    <>
      {/* header part */}
      {displayingChart ? (
        //   on displaying chart panel

        <>
          <Row style={{ height: "74px", alignItems: "center" }}>
            <Col lg="1">
              <Tooltip title="Back to selection">
                <ArrowBackIcon
                  style={{
                    fontSize: "28px",
                    color: "#C27ADD",
                  }}
                  // onClick={onBackToSelectionClicked}
                  onClick={onBackToSelectionClicked}
                />
              </Tooltip>
            </Col>
            <Col
              lg="7"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}>
              <span
                className="chart-header-span"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}>
                {headerLine1}
              </span>
              <span
                className="chart-header-span"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}>
                {headerLine2}
              </span>
            </Col>
            <Col lg="3">
              <select
                className="selection-modifier-insight"
                id={"dropoff-breakdown-" + id}
                key={"dropoff-breakdown-" + id}
                onChange={onBreakdownChange}>
                {breakdownOptions.map((breakdown) => (
                  <option value={breakdown}>{breakdown}</option>
                ))}
              </select>
            </Col>
            <Col
              lg="1"
              style={{
                display: "flex",
                // justifyContent: "flex-end",
                alignItems: "center",
                padding: "0",
              }}>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "10px",
                }}>
                <ul
                  className="dropdown-menu dropdown-menu-settings"
                  id={"game-main-dropdown-dropoff-" + id}
                  role="menu"
                  style={{ display: "none" }}>
                  {bucketDropdownOptions.map((kitOption) => {
                    const platform = Object.keys(kitOption)[0];
                    const kits = Object.values(kitOption)[0];
                    return generateBucketOptions(platform, kits);
                  })}

                  <li
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}>
                    <span
                      className="dropdown-item dropdown-item-settings"
                      style={{
                        padding: "0.55rem 0.4rem 0rem 1rem",
                        backgroundColor: "transparent",
                        border: "none",
                        marginBottom: "0",
                        fontSize: "14px",
                      }}>
                      <label
                        id={"data-format-lable-text-" + id}
                        style={{ fontWeight: "500" }}>
                        {dataFormatLabel}
                      </label>
                      <label
                        className="switch"
                        id={"data-format-lable-" + id}
                        style={{ marginLeft: "auto", right: "0" }}>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          onClick={() => updateChartDataFormat()}
                        />
                        <span className="slider round"></span>
                      </label>
                    </span>
                  </li>
                </ul>
                <Tooltip title="Settings">
                  <SettingsIcon
                    style={{
                      fontSize: "28px",
                      color: "#9d9d9dbf",
                      alignSelf: "center",
                      cursor: "pointer",
                    }}
                    // onClick={onBackToSelectionClicked}
                    onClick={toggleSettingclasses}
                  />
                </Tooltip>
              </Col>
            </Col>
          </Row>

          <Row>
            <hr />
          </Row>

          <Row
            style={{
              //   border: "2px solid",
              height: "335px",
              width: "auto",
              marginTop: "-14px",
            }}>
            {chartData ? (
              <ChartPanel2
                ref={chartRef}
                id={id}
                data={chartData}
                chartType={chartType}
              />
            ) : (
              ""
            )}
          </Row>
          <Row>
            <Col style={{ marginTop: "8px" }}>
              <span
                style={{
                  border: "2px solid rgba(207, 204, 204, 0.47)",
                  borderRadius: "10px",
                  fontWeight: "500",
                  padding: "5px",
                  fontSize: "0.75rem",
                  color: "#828282",
                  backgroundColor: "#F6F6F6",
                }}>
                <TodayIcon style={{ color: "#6c757da6", height: "16px" }} />
                {dropOffSelectedDataRef.current?.from_date} {" ~ "}
                {dropOffSelectedDataRef.current?.to_date} {"  "}
              </span>
            </Col>
          </Row>
          <SyncLoader
            color="#e09e53"
            id={"panel-" + id + "-loader"}
            loading={loading}
            css={override}
            size={15}
          />
        </>
      ) : (
        //   on displying selection panel
        <>
          <Row
            style={{
              display: "flex",
              flexDirection: "row",
            }}>
            <Modal
              isOpen={modalIsOpen}
              // onAfterOpen={afterOpenModal}
              // onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal">
              <span
                className="label label-default"
                style={{ color: "black", fontFamily: "Montserrat" }}>
                Specific email id
              </span>
              <br />
              <a
                href="#"
                style={{
                  position: "absolute",
                  right: "0",
                  top: "0",
                  margin: "14px",
                  textDecoration: "none",
                  color: "black",
                }}
                onClick={() => setIsOpen(false)}>
                X
              </a>
              <input
                type="text"
                className="form-control"
                name="user_email"
                id={"selection-input-user-email-" + id}
                placeholder="email address"
              />
              <br /> <br />
              <button
                className="btn "
                style={{
                  alignSelf: "center",
                  width: "35%",
                  backgroundColor: "#72C8CC",
                  color: "white",
                  fontFamily: "Montserrat",
                }}
                onClick={() => {
                  setSelectedEmail(
                    document.getElementById("selection-input-user-email-" + id)
                      .value
                  );
                  console.log(
                    document.getElementById("selection-input-user-email-" + id)
                      .value
                  );
                  setIsOpen(false);
                }}>
                Apply
              </button>
            </Modal>
            <Col
              style={{
                display: "flex",
                flexDirection: "row",
              }}>
              {feaureOptions.map(fillFeatureData)}
            </Col>
            <Col style={{ display: "flex", justifyContent: "flex-end" }}>
              <select
                name=""
                id=""
                className="featureType-selection"
                onChange={(e) => featureTypeChanged(e.target.value)}>
                {featureTypeOptions.map((feautureType) => {
                  return feautureType === "Distribution" ? (
                    <option value={feautureType} selected>
                      {feautureType}
                    </option>
                  ) : (
                    <option value={feautureType}>{feautureType}</option>
                  );
                })}
              </select>
            </Col>
          </Row>
          <Row>
            <hr />
          </Row>
          {/* body part */}
          <Row
            style={{
              display: "flex",
              flexDirection: "column",
              height: "265px",
              marginTop: "0px",
              marginBottom: "30px",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Col
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <div
                id={"games-div-" + id}
                className="selection-modifier-insight"
                style={{
                  // width: "135px",
                  padding: "4px",
                  width: "10.5vw",
                  maxWidth: "10.5vw",
                }}
                onClick={() => toggleclasses()}>
                <a
                  id={"games-div-lable-" + id}
                  role="button"
                  selected="true"
                  className="game-title-a"
                  style={{ verticalAlign: "sub" }}>
                  Kits
                </a>
                <input type="hidden" id={"games-platformdata-" + id}></input>
                <input type="hidden" id={"games-kitdata-" + id}></input>
                <ul
                  className="dropdown-menu"
                  id={"game-main-dropdown-" + id}
                  role="menu"
                  style={{ display: "none" }}>
                  {kitOptions.map((kitOption) => {
                    const platform = Object.keys(kitOption)[0];
                    const kits = Object.values(kitOption)[0];
                    return generatePlatformAndKits(platform, kits);
                  })}
                </ul>
              </div>
            </Col>

            <Col
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <select id={"dropOff-games" + id} className="dropoff-main-select">
                {gameOptions.map((option) =>
                  option.includes("All") ? (
                    <option value={option} selected>
                      {option.split("_").join(" ")}
                    </option>
                  ) : (
                    <option value={option}>
                      {option.split("_").join(" ")}
                    </option>
                  )
                )}
              </select>
            </Col>
            <Col
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <select id={"dropOff-xAxis" + id} className="dropoff-main-select">
                {xAxisOptions.map((option) => (
                  <option value={option}>{option}</option>
                ))}
              </select>
            </Col>
          </Row>
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <button
              className="go-button-funnel"
              onClick={validateSelectionBeforeSubmit}>
              Go
            </button>
          </Row>
          <Row>
            {chartData ? (
              <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                <Tooltip title="Back to chart!">
                  <LegendToggleIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => setDisplayingChart(true)}
                  />
                </Tooltip>
              </Col>
            ) : (
              ""
            )}
          </Row>
          <SyncLoader
            color="#e09e53"
            id={"panel-" + id + "-loader"}
            loading={loading}
            css={override}
            size={15}
          />
        </>
      )}
    </>
  );
};
export default DropOff;
