import "./App.css";
import { BrowserRouter as Router, Route, Switch  } from "react-router-dom";
import { useLocation } from "react-router";
import queryString from 'query-string';
import TableDictionary from "./TableDictionary/TableDictionary";
import LiveView from "./LiveView/MainScreen";
import AppDashboard from "./AppDashboard";
import { AuthProvider } from "./contexts/AuthContext";
import Login from "./TableDictionary/Login/Login";
import PrivateRoute from "./PrivateRoute";
import React from "react";
import InsightFeature from "./FeaturePage/InsightFeature/InsightFeature";
import FunnelFeature from "./FeaturePage/FunnelFeature/FunnelFeature";
import DistributionFeature from './FeaturePage/DistributionFeature/DistributionFeature';


function App() {

	return (
		<Router>
			<AuthProvider>
				<Switch>
					<PrivateRoute exact path="/TableDictionary" component={TableDictionary} />
					<PrivateRoute exact path="/liveview" component={LiveView} />
					<Route exact path="/login" component={Login} />
					<PrivateRoute exact path="/" component={AppDashboard} />
					<PrivateRoute exact path="/Reports/Insight" component={InsightFeature} />
					<PrivateRoute exact path="/Reports/Funnel" component={FunnelFeature} />
					<PrivateRoute exact path="/Reports/Distribution" component={DistributionFeature} />
				</Switch>
			</AuthProvider>
		</Router>
	);
}

export default App;
