import React from "react";
import furnace from "../../assets/furnace.png";

import "./Header.css";
const Header = () => {
	return (
		<>
			<div className="main-header">
				<img className="header-image" src={furnace}></img>
				{/* <span class="header-title">Furnance</span> */}
				{/* <img className="header-text" src={furnanceText}></img> */}
			</div>
		</>
	);
};

export default Header;
