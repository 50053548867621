import logo from "./logo.svg";
import "./App.css";
import Sidebar from "./Dashboard/Sidebar/Sidebar";
import MainScreen from "./Dashboard/MainScreen";
import { useEffect, useState } from "react";

function AppDashboard() {
	
	const [globalFilters, setGlobalFilters] = useState({});
	//const [mainScreenOpen, setMainScrenOpen] = useState(false);
	function setFilters(allFilters) {
		setGlobalFilters(allFilters);
		console.log(globalFilters);	
	}
	
	return (
            <div >
            <MainScreen  globalFilter={globalFilters}/>
            </div>
		
	);
}

export default AppDashboard;
