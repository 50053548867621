import React, { useEffect, useState, useRef } from "react";
import QueriesPanel from "./QueriesPanel/QueriesPanel";
import "./RightPanel.scss";
import {
  FormGroup,
  Row,
  Col,
  Form,
  Table,
  Modal,
  Button,
  InputGroup,
  FormControl,
  Badge,
} from "react-bootstrap";
import SearchIcon from "../../assets/search-icon.png";
import axios from "axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import SyncLoader from "react-spinners/SyncLoader";
import { css } from "@emotion/react";
import { useAuth } from "../../contexts/AuthContext";
import AceEditor from "react-ace";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import "ace-builds/src-noconflict/mode-mysql";
import "ace-builds/src-noconflict/theme-textmate";
import "ace-builds/src-noconflict/ext-language_tools";
import cloneDeep from "lodash/cloneDeep";
import * as d3 from "d3";
import { CSVLink, CSVDownload } from "react-csv";
import Play from "../../assets/play.png";
import Csv from "../../assets/csv.png";
import Save from "../../assets/save.png";
import SaveAs from "../../assets/save-as.png";
const RightPanel = () => {
  let [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [allQueries, setAllQueries] = React.useState("");
  const [inpublicQuery, setInpublicQuery] = useState(true);
  const [count, setCount] = useState(0);
  const { currentUser, logout } = useAuth();
  const myQueryStatusRef = useRef(false);
  const allDataRef = useRef("");
  const [showUp, setshowUp] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const fillAllData = (newData) => {
    console.log("in fillAllData");
    if (newData.length <= 0) return;
    const data = newData.map(function (el) {
      var o = Object.assign({}, el);
      o.name = el.title;
      return o;
    });
    allDataRef.current = [...data];
    console.log("allDataRef.current  - ", allDataRef.current);
    //   console.log("all data ref is now...");
    //   console.log(allDataRef.current);
  };

  document.addEventListener("mouseup", function (e) {
    var container = document.getElementById("table-main-dropdown");
    if (container && !container.contains(e.target)) {
      container.style.display = "none";
    }
  });

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results);
  };

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result);
  };

  const handleOnSelect = (item) => {
    // the item selected
    console.log("item selected.....");
    console.log(item);
    const body = item.query_body;
    const bodyId = item.id;

    console.log(body);
    aceRef.current.editor.setValue(body);
    aceIdRef.current = bodyId;
  };

  const handleOnFocus = () => {
    // console.log('Focused')
  };

  const formatResult = (item) => {
    return item;
    // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
  };
  const aceIdRef = useRef();
  const aceRef = useRef();
  const querySelected = (queryBody, queryId) => {
    aceRef.current.editor.setValue(queryBody);
    aceIdRef.current = queryId;
  };
  const hideQuery = (queryData) => {
    console.log("hide query", queryData);
    axios
      .delete(
        `https://419st4b2i4.execute-api.us-east-2.amazonaws.com/Queries?id=${queryData.id}`
      )
      .then((ele) => {
        getAllQueries();
        if (inpublicQuery) publicQuerySelected();
        myQuerySelected();
      });
  };
  const override = css`
    position: absolute;
    top: 42%;
    left: 43%;
    z-index: 999;
  `;
  function createNotification(type) {
    if (type === "success")
      return NotificationManager.success("Query saved", "success");
    else if (type === "error")
      return NotificationManager.warning("Query Execution Failed", "Error");
  }

  const getAllQueries = async (user = "all") => {
    console.log("in getAll");
    const getQueryAPI =
      "https://419st4b2i4.execute-api.us-east-2.amazonaws.com/Queries";
    setLoading(true);
    axios({
      method: "get",
      url: getQueryAPI,
      headers: { "Content-Type": "application/json" },
    })
      .then(function (response) {
        setLoading(false);
        console.log("response", response.data.body);
        if (!myQueryStatusRef.current) {
          setAllQueries(response.data.body);
          fillAllData(response.data.body);
        } else {
          const data = response.data.body;
          const newData = data.filter((doc) => doc.created_by === user);
          setAllQueries(newData);
          fillAllData(newData);
        }
        return response;
      })
      .catch(function (error) {
        createNotification("error");
        return error;
      });
  };

  function getTableNames() {
    const tableNamesAPI =
      "https://rhw8rv1tr9.execute-api.us-east-2.amazonaws.com/tableNames";
    // setLoading(true);
    axios({
      method: "get",
      url: tableNamesAPI,
      headers: { "Content-Type": "application/json" },
    })
      .then(function (response) {
        // setLoading(false);
        fillTableDropdown(response.data.result);
      })
      .catch(function (error) {
        console.log("error in get table name api!!!");
        console.log(error);
        return error;
      });
  }
  function fillTableDropdown(tableData) {
    Object.entries(tableData).forEach((d) => {
      const [key, tables] = d;
      const platformName = key;
      d3.select("#table-main-dropdown")
        .append("li")
        .attr("class", "dropdown-submenu")
        .attr("id", "table-option-" + platformName);
      d3.select("#table-option-" + platformName)
        .append("a")
        .attr("class", "dropdown-item")
        .attr("id", "level-1" + platformName)
        .text(platformName);
      d3.select("#table-option-" + platformName)
        .append("ul")
        .attr("class", "dropdown-menu dropdown-menu-right-open")
        .attr("id", "ul-level2-" + platformName);
      d3.select("#ul-level2-" + platformName)
        .append("div")
        .attr("id", "div-level2-" + platformName);
      Object.entries(tables).forEach((table) => {
        const [tableKey, tableValue] = table;
        const tableName = tableValue.TableName;
        const address = tableValue.TableAddress;
        d3.select("#div-level2-" + platformName)
          .append("li")
          .attr("id", "table-option-level2-" + tableName)
          .on("click", () =>
            tableOptionSelected(platformName, tableName, address)
          );

        d3.select("#table-option-level2-" + tableName)
          .append("a")
          .attr("class", "dropdown-item")
          .attr("id", "level2-" + platformName + "-" + tableName)
          .text(tableName);
      });
    });
    d3.select("#tables-div-lable").text("- Insert Table - ");
  }
  const myQuerySelected = () => {
    setInpublicQuery(false);
    var publicQuery = document.getElementById("header-public_query");
    publicQuery.classList.remove("span-selected");
    var myQuery = document.getElementById("header-my_query");
    myQuery.classList.add("span-selected");
    myQueryStatusRef.current = true;
    getAllQueries(currentUser.email);
  };

  const publicQuerySelected = () => {
    setInpublicQuery(true);

    var myQuery = document.getElementById("header-my_query");
    myQuery.classList.remove("span-selected");
    var publicQuery = document.getElementById("header-public_query");
    publicQuery.classList.add("span-selected");
    myQueryStatusRef.current = false;
    getAllQueries();
  };
  const addQuery = async () => {
    console.log("aceRef", aceRef.current && aceRef.current.editor.getValue());
    const getQueryAPI =
      "https://419st4b2i4.execute-api.us-east-2.amazonaws.com/Queries";
    const title = document.querySelector("#query-title").value;
    const description = document.querySelector("#query-description").value;
    const queryBody = document.querySelector("#query-body").value;

    const date = new Date().toDateString();
    const data = {
      created_by: currentUser.email,
      created_at: date,
      title,
      query_body: queryBody,
      description,
    };
    console.log("data passing");
    console.log(data);
    setModalShow(false);
    setLoading(true);
    axios({
      method: "post",
      url: getQueryAPI,
      data,
      headers: { "Content-Type": "application/json" },
    })
      .then(function (response) {
        setLoading(false);

        createNotification("success");
        console.log(response);
        getAllQueries();
        setCount(count + 1);
        return response;
      })
      .catch(function (error) {
        console.log("error occured in insertion");
        alert("error in saving the query!");
        return error;
      });
  };

  // useEffect(() => {

  //     console.log("all queries");
  //     console.log(allQueries);
  // },[allQueries]);
  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Save Query
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">Query Title*</InputGroup.Text>
            <FormControl
              // placeholder="ex.. get plugo all users"
              id="query-title"
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">Description</InputGroup.Text>
            <FormControl
              // placeholder="give description"
              aria-label="Username"
              id="query-description"
              aria-describedby="basic-addon1"
            />
          </InputGroup>

          <InputGroup>
            <InputGroup.Text>Your query</InputGroup.Text>
            <FormControl
              as="textarea"
              id="query-body"
              aria-label="With textarea"
              style={{ resize: "none", readOnly: "true" }}
              readOnly={true}
              value={aceRef.current && aceRef.current.editor.getValue()}
              rows={3}></FormControl>
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => addQuery()}>Save</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  useEffect(async () => {
    await getAllQueries();

    await getTableNames();
    if (inpublicQuery) {
      await publicQuerySelected();
    } else if (!inpublicQuery) {
      await myQuerySelected();
    }
    // await fillAllData();
    // var arr = document.querySelector(".arrow-table");
    // arr.addEventListener("click", function (event) {
    // 	event.target.classList.toggle("down");
    // 	var div = document.getElementById("table-builder");
    // 	div.classList.toggle("closed");
    // });
    // var arr = document.querySelector(".arrow-query");
    // arr.addEventListener("click", function (event) {
    // 	event.target.classList.toggle("down");
    // 	var div = document.getElementById("query-builder");
    // 	div.classList.toggle("closed");
    // });
  }, [count]);

  const saveOrResaveQuery = () => {
    if (inpublicQuery) {
      setModalShow(true);
    } else {
      let searchQuery = String(aceRef.current.editor.getValue()).toLowerCase();
      let allQueriesDup = allQueries;
      allQueriesDup = allQueriesDup.filter(
        (ele) => ele.query_body === searchQuery
      );
      console.log("match found", allQueriesDup);
      if (allQueriesDup.length === 0) {
        setModalShow(true);
      } else {
        allQueriesDup.forEach(function (ele) {
          ele.created_at = new Date().toDateString();
        });
        let newAllQueriesData = allQueries;

        let index = 0;
        newAllQueriesData.forEach(function (ele, idx) {
          if (ele.id == aceIdRef.current) {
            index = idx;
          }
        });

        console.log("the data", newAllQueriesData);
        axios
          .post(
            "https://419st4b2i4.execute-api.us-east-2.amazonaws.com/Queries",
            newAllQueriesData
          )
          .then((response) => {
            console.log("the data posted", response);
          });
      }
    }
  };

  const saveQuery = () => {
    // const data = document.querySelector('.query-area-textarea').value;
    // aceRef.editor.getValue()
    setModalShow(true);
  };

  function tableOptionSelected(platformName, tableName, address) {
    d3.select("#table-main-dropdown").style("display", "none");
    // d3.select("#tables-div-lable").text(tableName);
    aceRef.current.editor.setValue(
      aceRef.current.editor.getValue() + " " + address
    );
  }
  function onChange(newValue) {
    // console.log("change", newValue);
  }

  function getTableData() {
    const getQueryResponse =
      "https://txrpwwtdzc.execute-api.us-east-2.amazonaws.com/executeBigquery";

    const queryData = {
      query: aceRef.current.editor.getValue(),
    };

    setLoading(true);
    axios({
      method: "post",
      url: getQueryResponse,
      data: queryData,
      headers: { "Content-Type": "application/json" },
    })
      .then(function (response) {
        setLoading(false);
        console.log("response received");
        const data = cloneDeep(response.data.response);
        console.log(data);
        let allUniqueHeaders = Object.keys(
          data.reduce(function (result, obj) {
            return Object.assign(result, obj);
          }, {})
        );
        setTableHeaders(allUniqueHeaders);
        let newData = cloneDeep(data);
        newData.forEach((item, i) => {
          item.id = i + 1;
        });

        // console.log("new data is ");
        // console.log(newData);
        setTableData(newData);
      })
      .catch(function (error) {
        setLoading(false);
        console.log("error occured");
        console.log(error);
        createNotification("error");
      });
  }
  const runQuery = async () => {
    setshowUp(true);
    await getTableData();
  };
  const getTableEntry = (tableRow, index) => {
    // console.log(tableRow);
    let data = "";
    const tableItems = tableHeaders.map((header) => (
      <td style={{ fontWeight: "500" }}>{tableRow[header]}</td>
    ));
    // console.log(tableItems);
    return (
      <tr key={index}>
        <td style={{ fontWeight: "500" }}>{index + 1}</td>
        {tableItems}
      </tr>
    );
  };
  const getTableHeader = (header, index) => {
    return (
      <th
        style={{
          position: "sticky",
          top: "0",
          background: "#5e5e5e",
          color: "white",
        }}
        key={header}>
        {header}
      </th>
    );
  };
  function toggleDropdown() {
    const currentSelection = d3.select("#table-main-dropdown").style("display");
    d3.select("#table-main-dropdown").style(
      "display",
      currentSelection === "block" ? "none" : "block"
    );
  }
  return (
    <>
      <Row>
        {/* main screen section */}
        <NotificationContainer />

        <Col sm={9} lg={9} xl={10} style={{ width: "80%" }}>
          {/* search bar section */}
          <Row style={{ justifyContent: "center" }}>
            {/* <input type="text" name="" id="" placeholder={"Search Tables, Query name, Terms"} className="search-input" /> */}
            <div style={{ width: "50%", zIndex: "99" }}>
              <ReactSearchAutocomplete
                items={allDataRef.current}
                onSearch={handleOnSearch}
                onHover={handleOnHover}
                onSelect={handleOnSelect}
                onFocus={handleOnFocus}
                placeholder="Search saved Queries here.."
                autoFocus
                formatResult={formatResult}
              />
            </div>
          </Row>

          {/* Query builder */}

          <Row style={{ marginTop: "6vh" }}>
            <Col>
              <Row style={{ display: "flex", width: "80%", marginLeft: "7vw" }}>
                <Col>
                  <span
                    className="arrow-query"
                    style={{
                      color: "black",
                      fontWeight: "600",
                      cursor: "pointer",
                    }}>
                    {"</> Query Builder"}
                  </span>
                </Col>
                <Col>
                  <div
                    className="tables-div-parent"
                    style={{ display: "flex", justifyContent: "flex-end" }}>
                    <a
                      href="#"
                      role="button"
                      selected="true"
                      id="tables-div-lable"
                      onClick={toggleDropdown}>
                      {" "}
                      - Loading... -{" "}
                    </a>
                    <ul
                      id="table-main-dropdown"
                      role="menu"
                      className="dropdown-menu multi-level"></ul>
                  </div>
                </Col>
              </Row>
              {/* Outer Div for query builder */}
              <div style={{ width: "80%", margin: "auto" }}>
                <div
                  className="query-builder-div"
                  id="query-builder"
                  style={{ height: "34vh" }}>
                  <div className="query-area-parent">
                    <AceEditor
                      placeholder="SELECT * FROM ..."
                      mode="mysql"
                      theme="textmate"
                      name="editor"
                      onChange={onChange}
                      fontSize={14}
                      height="25vh"
                      width="100%"
                      style={{
                        borderRadius: "25px",
                        border: "1px solid #c5c5c5",
                      }}
                      ref={aceRef}
                      showPrintMargin={false}
                      showGutter={true}
                      highlightActiveLine={true}
                      setOptions={{
                        enableBasicAutocompletion: false,
                        enableLiveAutocompletion: true,
                        enableSnippets: false,
                        showLineNumbers: true,
                        tabSize: 2,
                      }}
                    />
                  </div>

                  <div style={{ marginTop: "1.8vh" }}>
                    <button
                      className="querypanel-button"
                      onClick={() => runQuery()}>
                      <img
                        src={Play}
                        alt="play"
                        loading="lazy"
                        className="play-icon"
                      />
                      Run Query
                    </button>

                    <button
                      className="querypanel-button"
                      onClick={() => saveOrResaveQuery()}>
                      <img
                        src={Save}
                        alt="play"
                        loading="lazy"
                        className="save-icon"
                      />
                      Save
                    </button>
                    <button
                      className="querypanel-button"
                      onClick={() => saveQuery()}>
                      <img
                        src={SaveAs}
                        alt="play"
                        loading="lazy"
                        className="save-as-icon"
                      />
                      Save As
                    </button>
                  </div>

                  <hr />
                </div>
              </div>

              {/* outer div for table view */}
              <Row style={{ marginTop: "5vh", marginBottom: "100px" }}>
                <div
                  style={{
                    width: "80%",
                    margin: "auto",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  <div
                    style={{
                      width: "100%",
                      margin: "auto",
                      display: "flex",
                      justifyContent: "space-between",
                    }}>
                    <span
                      className={showUp ? "arrow-table down" : "arrow-table"}
                      onClick={() => setshowUp(!showUp)}
                      style={{
                        color: "black",
                        fontWeight: "600",
                        cursor: "pointer",
                      }}>
                      {"Table"}
                    </span>

                    <div
                      className="tableview-table "
                      id="table-builder"
                      style={{
                        overflow: "auto",
                        height: "50vh",
                        marginTop: "10vh",
                        width: "56vw",
                        maxWidth: "56vw",
                      }}>
                      <Table striped bordered={"true"} hover>
                        {tableData.length > 0 ? (
                          <>
                            <thead>
                              <tr>
                                <th
                                  style={{
                                    position: "sticky",
                                    top: "0",
                                    background: "#5e5e5e",
                                    color: "white",
                                  }}>
                                  #
                                </th>
                                {tableHeaders.map(getTableHeader)}
                              </tr>
                            </thead>
                          </>
                        ) : (
                          ""
                        )}

                        <tbody>{tableData.map(getTableEntry)}</tbody>
                      </Table>
                    </div>
                    <CSVLink data={tableData} filename={"my-file.csv"}>
                      <button className="download-csv-button">
                        <img
                          src={Csv}
                          alt="play"
                          loading="lazy"
                          className="csv-icon"
                        />
                      </button>
                    </CSVLink>
                  </div>
                </div>
              </Row>
            </Col>
          </Row>
        </Col>

        {/* display all queries section */}
        <Col
          sm={3}
          lg={3}
          xl={2}
          style={{
            backgroundImage:
              "linear-gradient(to top, rgb(217 191 226 / 33%) 0%, rgb(226, 235, 240) 100%)",
            borderRadius: "30px 0px 0px 30px",
            height: "82vh",
            overflow: "auto",
            width: "20%",
          }}>
          <Row
            style={{
              alignItems: "center",
              textAlign: "center",
              marginTop: "2vh",
              marginBottom: "1vh",
              justifyContent: "center",
            }}>
            <Col>
              <span
                id="header-my_query"
                style={{ fontSize: "0.85rem" }}
                className="queries-span"
                onClick={() => myQuerySelected()}>
                My Queries
              </span>
            </Col>
            <Col>
              <span
                id="header-public_query"
                style={{ fontSize: "0.85rem" }}
                className="queries-span span-selected"
                onClick={() => publicQuerySelected()}>
                Public Queries
              </span>
            </Col>
            <hr style={{ maxWidth: "92%", align: "auto", marginTop: "1vh" }} />
          </Row>
          {allQueries.length > 0 ? (
            <QueriesPanel
              data={allQueries}
              onHide={hideQuery}
              inpublicQuery={inpublicQuery}
              onQuerySelect={querySelected}
            />
          ) : (
            ""
          )}
        </Col>

        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
        <SyncLoader
          color="#e09e53"
          id={"panel-loader"}
          loading={loading}
          css={override}
          size={15}
        />
      </Row>
    </>
  );
};

export default RightPanel;
