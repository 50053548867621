import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { nanoid } from "nanoid";
import { cloneDeep } from "lodash";
import axios from "axios";
import FilterDropdown from "./FilterDropdown";
const FilterQuerySelector = forwardRef(
  ({ kitName, event, propertyOptions, savedEventData }, ref) => {
    useImperativeHandle(ref, () => ({
      getGlobalFilterData() {
        let filterObject = {};
        if (filterDropdownRef.current.length === 0) {
          filterObject["0"] = {
            filter: "",
            val: "",
            datatype: "",
            equator: "",
          };
          return filterObject;
        }
        for (let index = 0; index < filterDropdownRef.current.length; index++) {
          if (filterDropdownRef.current[index] == null) continue;
          filterObject[index] =
            filterDropdownRef.current[index].getSelectedFilterData();
        }
        // console.log("in global Filter Query selector  ", filterObject);
        return filterObject;
      },
    }));
    const [dropdownPanels, setDropdownPanels] = useState([]);
    const panelsRef = useRef([...dropdownPanels]);
    const filterDropdownRef = useRef([]);
    function handleRemoveSelection(id) {
      console.log("remove clicked - " + id);
      console.log(dropdownPanels);
      const newArr = dropdownPanels.filter((d) => d.id !== id);
      panelsRef.current = cloneDeep(newArr);
      setDropdownPanels(newArr);
    }

    const allAvailableEventsRef = useRef("");
    function addFilterDropdown() {
      const uniqueId = nanoid();
      const newArr = [...panelsRef.current, { id: uniqueId, value: "" }];
      setDropdownPanels(newArr);
      panelsRef.current = cloneDeep(newArr);
      console.log("add called, new panels - ", panelsRef.current);
      return uniqueId;
    }

    return (
      <>
        <span
          style={{
            width: "18rem",
            fontWeight: 700,
            marginBottom: "4px",
            marginTop: "32px",
          }}>
          Filter
        </span>
        {dropdownPanels.map((m, index) => {
          return (
            <FilterDropdown
              ref={(el) => (filterDropdownRef.current[index] = el)}
              event={event}
              index={index}
              id={m.id}
              kitName={kitName}
              propertyOptions={propertyOptions}
              removeFilter={handleRemoveSelection}
            />
          );
        })}

        <button
          className="querySelector-parent-btn"
          onClick={addFilterDropdown}>
          {" "}
          + Add
        </button>
      </>
    );
  }
);

export default FilterQuerySelector;
