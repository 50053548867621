import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import Select, { components } from "react-select";
import Badge from "react-bootstrap/Badge";
import ClearIcon from "@mui/icons-material/Clear";
import Tooltip from "@mui/material/Tooltip";
import CustomFilterIcon from "../../../../../assets/custom_filter_icon.svg";
import { nanoid } from "nanoid";
import axios from "axios";
import { cloneDeep, filter } from "lodash";
import InlineFilterDropdown from "./InlineFilterDropdown";
const EventDropdown = forwardRef(
  (
    {
      id,
      index,
      events,
      removeEvent,
      eventIsLoadingProp,
      kitName,
      sendSelectedEvent,
      savedData,
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      getSelectedEventData() {
        let filterObject = {};
        let platform = "";
        let kit = kitName.value;
        if (kit === "Plugo") platform = "Plugo";
        if (kit === "Tacto") platform = "Tacto";
        if (kit === "OrbootE") {
          kit = "Orboot_Earth";
          platform = "Orboot";
        } else if (kit === "OrbootD") {
          kit = "Orboot_Dino";
          platform = "Orboot";
        }
        // const insightType = document.getElementById("insighttype" + id)?.value;
        if (inlineFilterDropdownRef.current.length === 0) {
          filterObject["0"] = {
            filter: "",
            val: "",
            datatype: "",
            equator: "",
          };
          let eventObject = {};
          const stepObj = {};
          stepObj[selectedEvent?.value] = filterObject;
          eventObject["event_data"] = stepObj;
          eventObject["kit"] = kit;
          eventObject["did_not"] = false;
          eventObject["platform"] = platform;
          return eventObject;
        }
        for (
          let index = 0;
          index < inlineFilterDropdownRef.current.length;
          index++
        ) {
          if (inlineFilterDropdownRef.current[index] == null) continue;
          filterObject[index] =
            inlineFilterDropdownRef.current[index].getSelectedFilterData();
        }
        // console.log("in event dropdown  ", filterObject);
        let eventObject = {};
        const stepObj = {};
        stepObj[selectedEvent?.value] = filterObject;
        eventObject["event_data"] = stepObj;
        // eventObject["insighttype"] = insightType;
        eventObject["kit"] = kit;
        eventObject["platform"] = platform;
        return eventObject;
      },
    }));
    const [selectedEvent, setSelectedEvent] = useState("");

    // to autofill saved report data
    useEffect(() => {
      console.log("saved data received for index ", index, " - ", savedData);
      const filterPanelsArr = [];
      if (savedData) {
        const eventName = Object.keys(savedData)[0];
        setSelectedEvent({
          value: eventName,
          label: eventName,
        });
        sendSelectedEvent({ index, eventName: eventName });
        // create filterpanels
        // console.log("filters data - ", savedData[eventName]);
        const filterKeys = Object.keys(savedData[eventName]);
        filterKeys.forEach((filterKey) => {
          const uniqueId = nanoid();
          if (savedData[eventName][filterKey]["filter"] !== "") {
            filterPanelsArr.push({
              id: uniqueId,
              value: "",
              filterData: savedData[eventName][filterKey],
            });
          }
        });
        setFilterPanels(filterPanelsArr);
        filterPanelsRef.current = cloneDeep(filterPanelsArr);
      }
    }, [savedData]);

    const inlineFilterDropdownRef = useRef([]);
    const [filterPanels, setFilterPanels] = useState([]);
    const filterPanelsRef = useRef([...filterPanels]);
    const [eventsOptions, setEventsOptions] = useState([]);

    const [propertyOptions, setPropertyOptions] = useState([]);
    function handleRemoveFilter(id) {
      // console.log("remove clicked - " + id);
      console.log(filterPanels);
      const newArr = filterPanels.filter((d) => d.id !== id);
      filterPanelsRef.current = cloneDeep(newArr);
      setFilterPanels(newArr);
    }
    function addFilterDropdown() {
      const uniqueId = nanoid();
      const newArr = [
        ...filterPanelsRef.current,
        { id: uniqueId, value: "", filterData: "" },
      ];
      setFilterPanels(newArr);
      filterPanelsRef.current = cloneDeep(newArr);
      // console.log("add called, new panels - ", filterPanelsRef.current);
      return uniqueId;
    }
    const eventStyle = {
      control: (styles, { isFocused }) => ({
        ...styles,
        backgroundColor: "white",
        width: "10rem",
        border: "0px solid #D3D3D3",
        borderRadius: "10px",
        height: "30px",
        overflow: "wrap ",
        fontSize: "12px",
        marginRight: "3px",
        "&:hover": {
          backgroundColor: isFocused ? "white" : "#fbf4f9",
        },
      }),
      loadingIndicator: (styles) => ({
        ...styles,
        color: "#bb6bd9",
      }),
      menu: (base) => ({
        ...base,
        zIndex: 10,
        overflow: "wrap",
        fontSize: "12px",
        width: "20rem",
        borderRadius: "20px",
        padding: "10px",
      }),

      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isSelected ? "#bb6bd9" : "white",
          color: isSelected ? "white" : "black",
          borderRadius: "10px",
          marginBottom: "5px",
          padding: "8px",
          fontSize: "13px",
          fontWeight: "500",
          "&:hover": {
            backgroundColor: isSelected ? "#bb6bd9" : "#f7f7f7",
          },
        };
      },
    };

    const handleEventSelectionChange = (e) => {
      setSelectedEvent(e);
      sendSelectedEvent({ index, eventName: e.value });
    };

    const generatePropertiesOptions = () => {
      const InlineFilterAPI =
        "https://7mbhokze6g.execute-api.us-east-2.amazonaws.com/PropertyFunnel?event=" +
        selectedEvent.value +
        "&kit=" +
        kitName.value;
      axios({
        method: "get",
        url: InlineFilterAPI,
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => {
          const dataArray = response.data.result.properties;
          const allProperties = [];
          console.log(dataArray);
          dataArray.forEach((data) => {
            if (
              !data.startsWith("_") &&
              !data.toLowerCase().startsWith("sdc") &&
              !data.toLowerCase().startsWith("firebase")
            ) {
              let label = data.split("_").join(" ");

              label = label.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                letter.toUpperCase()
              );
              label = label.replaceAll("Mp Reserved", "");
              label = label.replaceAll("Mp", "");
              // if (
              //   !label.split(" ")[0] === "Sdc" &&
              //   !label.split(" ")[0] === "Firebase"
              // )
              allProperties.push({ label: label, value: data });
            }
          });
          setPropertyOptions(allProperties);
        })
        .catch((err) => {
          console.log("error in getting inline filters");
          console.log(err);
        });
    };

    useEffect(() => {
      setEventsOptions(events);
      // setSelectedEvent("");
      // console.log("events - ", events);
    }, [events]);
    useEffect(() => {
      generatePropertiesOptions();
    }, [selectedEvent]);

    return (
      <div
        style={{
          border: "1px solid rgb(173 169 169 / 63%)",
          borderRadius: "10px",
          padding: "8px 8px 8px 16px",
          marginBottom: "8px",
        }}>
        <div
          style={{
            border: "0px solid black",
            display: "flex",
            alignItems: "center",
            marginBottom: "4px",
          }}>
          <Badge
            bg="secondary"
            className="badge-class"
            style={{ marginRight: "10px" }}>
            {index + 1}
          </Badge>
          <Select
            id={"event-selector-" + id}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            isLoading={eventIsLoadingProp}
            closeMenuOnSelect
            placeholder="select step"
            styles={eventStyle}
            value={selectedEvent}
            options={eventsOptions}
            onChange={handleEventSelectionChange}
            menuPlacement="auto"
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            isDisabled={eventIsLoadingProp}
            // menuPosition="fixed"
          />
          <img
            src={CustomFilterIcon}
            onClick={addFilterDropdown}
            className="filter-icon-btn"
          />
          <ClearIcon
            className="clear-icon-btn"
            onClick={() => removeEvent(id)}
          />
        </div>
        <div
          style={{
            border: "0px solid black",
            display: "flex",
            alignItems: "flex-start",
            marginBottom: "4px",
            width: "10rem",
            marginLeft: "2rem",
          }}>
          {/* <select
            name=""
            id={"insighttype" + id}
            style={{
              border: "none",
              padding: "2px",
              borderRadius: "6px",
              fontSize: "0.7rem",
              backgroundColor: "white",
            }}>
            <option value="total">Count Total</option>
            <option value="unique">Count Unique</option>
          </select> */}
        </div>
        <div>
          {filterPanels.map((m, index) => {
            return (
              <>
                <hr />
                <InlineFilterDropdown
                  ref={(el) => (inlineFilterDropdownRef.current[index] = el)}
                  index={index}
                  id={m.id}
                  filterData={m.filterData}
                  removeFilter={handleRemoveFilter}
                  event={selectedEvent}
                  kitName={kitName}
                  propertyOptions={propertyOptions}
                />
              </>
            );
          })}
        </div>
      </div>
    );
  }
);

export default EventDropdown;
