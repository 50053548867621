import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { nanoid } from "nanoid";
import { cloneDeep } from "lodash";
import axios from "axios";
import EventDropdown from "./EventDropdown";
const EventQuerySelector = forwardRef(
  ({ kitName, sendSelectedEventToParent, savedEventData }, ref) => {
    useImperativeHandle(ref, () => ({
      getAllEventsData() {
        let fullEventObject = [];
        for (
          let index = 0;
          index < eventDropdownListRef.current.length;
          index++
        ) {
          if (eventDropdownListRef.current[index] == null) continue;
          fullEventObject[index] =
            eventDropdownListRef.current[
              index
            ].getSelectedEventData().event_data;
          console.log(
            "eventDropdownListRef.current[index].getSelectedEventData() - ",
            eventDropdownListRef.current[index].getSelectedEventData()
          );
        }
        // console.log("in event query selector ", fullEventObject);
        return fullEventObject;
      },
    }));

    const selectedEventsObjectRef = useRef({});
    // if report data found
    useEffect(() => {
      if (savedEventData) {
        // empty existing selection
        console.log(
          "saved event data in event query selector - ",
          savedEventData
        );
        const newArr = [];
        panelsRef.current = cloneDeep(newArr);
        setDropdownPanels(newArr);
        const keys = Object.keys(savedEventData);
        keys.forEach((key) => {
          console.log("event data - ", savedEventData[key]);
          newArr.push({
            id: nanoid(),
            value: "",
            savedData: savedEventData[key]["event_data"],
          });
        });
        panelsRef.current = cloneDeep(newArr);
        setDropdownPanels(newArr);
      }
    }, [savedEventData]);

    const [dropdownPanels, setDropdownPanels] = useState([
      { id: nanoid(), value: "", savedData: "" },
    ]);
    const eventDropdownListRef = useRef([]);
    const [eventLoading, setEventLoading] = useState(false);
    const [eventsData, setEventsData] = useState([]);
    const allAvailableEventsRef = useRef("");
    const panelsRef = useRef([...dropdownPanels]);
    function handleRemoveSelection(id) {
      console.log("remove clicked - " + id);
      console.log(dropdownPanels);
      const newArr = dropdownPanels.filter((d) => d.id !== id);
      panelsRef.current = cloneDeep(newArr);
      setDropdownPanels(newArr);
    }
    function addEventDropdown() {
      const uniqueId = nanoid();
      const newArr = [
        ...panelsRef.current,
        { id: uniqueId, value: "", savedData: "" },
      ];
      setDropdownPanels(newArr);
      panelsRef.current = cloneDeep(newArr);
      console.log("add called, new panels - ", panelsRef.current);
      return uniqueId;
    }
    function getInsightEventsData() {
      setEventLoading(true);
      let kit = kitName.value;
      // setSelectedKit(kit);

      const getEventsAPI =
        "https://5nbim5vr4j.execute-api.us-west-1.amazonaws.com/prod/events-all-games?kit=" +
        kit;
      // setLoading(true);
      axios({
        method: "get",
        url: getEventsAPI,
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => {
          setEventLoading(false);
          // setLoading(false);
          const data = cloneDeep(response.data);
          const selectionObjects = data.map(function (val) {
            return { value: val, label: val };
          });
          setEventsData(selectionObjects);
          allAvailableEventsRef.current = selectionObjects;
        })
        .catch((err) => {
          setEventLoading(false);
          // setLoading(false);
          console.log("error in get events!!");
          console.log(err);
        });
    }

    const handleEventSelected = (eventData) => {
      console.log("index ", eventData.index, " , event: ", eventData.eventName);
      selectedEventsObjectRef.current[eventData.index] = eventData.eventName;
      sendSelectedEventToParent(selectedEventsObjectRef.current);
    };
    useEffect(() => {
      getInsightEventsData(kitName);
    }, [kitName]);
    return (
      <>
        <span style={{ width: "18rem", fontWeight: 700, marginBottom: "4px" }}>
          Events
        </span>
        {dropdownPanels.map((m, index) => {
          return (
            <EventDropdown
              events={eventsData}
              ref={(el) => (eventDropdownListRef.current[index] = el)}
              index={index}
              id={m.id}
              savedData={m.savedData}
              eventIsLoadingProp={eventLoading}
              removeEvent={handleRemoveSelection}
              kitName={kitName}
              sendSelectedEvent={handleEventSelected}
              savedEventData={savedEventData}
            />
          );
        })}
        <button className="querySelector-parent-btn" onClick={addEventDropdown}>
          {" "}
          + Add metric
        </button>
      </>
    );
  }
);

export default EventQuerySelector;
