import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import Badge from "react-bootstrap/Badge";
import Select, { components } from "react-select";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import { cloneDeep, filter } from "lodash";

const FilterDropdown = forwardRef(
  ({ id, index, removeFilter, event, kitName, propertyOptions }, ref) => {
    useImperativeHandle(ref, () => ({
      getSelectedFilterData() {
        const filterVals = selectedFilters.map((filter) => filter.value);
        const filterData = createFilterDataSendObject(filterVals);
        // console.log("in global filter dropdown - ", filterData);
        return filterData;
      },
    }));
    const propertyStyle = {
      control: (styles, { isFocused }) => ({
        ...styles,
        backgroundColor: "white",
        width: "11rem",
        border: "0px solid #D3D3D3",
        borderRadius: "10px",
        height: "30px",
        overflow: "wrap ",
        fontSize: "12px",
        marginRight: "3px",
        "&:hover": {
          backgroundColor: isFocused ? "white" : "#fcf5ff",
        },
      }),
      loadingIndicator: (styles) => ({
        ...styles,
        color: "#bb6bd9",
      }),
      menu: (base) => ({
        ...base,
        zIndex: 10,
        overflow: "wrap",
        fontSize: "13px",
        width: "20rem",
        borderRadius: "20px",
        padding: "10px",
      }),

      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isSelected ? "#bb6bd9" : "white",
          color: "black",
          borderRadius: "10px",
          marginBottom: "5px",
          padding: "8px",
          fontSize: "13px",
          fontWeight: "500",
        };
      },
    };
    const dataTypeStyle = {
      control: (styles, { isFocused }) => ({
        ...styles,
        backgroundColor: "#bb6bd9",
        width: "2.1rem",
        border: "0px solid #D3D3D3",
        borderRadius: "8px",
        overflow: "wrap ",
        fontSize: "8px",
        marginRight: "5px",
        textAlignLast: "center",
      }),

      singleValue: (provided) => ({
        ...provided,
        color: "white",
        fontWeight: "700",
      }),

      menu: (base) => ({
        ...base,
        zIndex: 10,
        overflow: "wrap",
        fontSize: "13px",
        width: "4rem",
        borderRadius: "8px",
        padding: "4px",
        textAlignLast: "center",
      }),

      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isSelected ? "#bb6bd9" : "white",
          color: isSelected ? "white" : "black",
          borderRadius: "5px",
          marginBottom: "5px",
          padding: "4px",
          fontSize: "12px",
        };
      },
    };
    const operatorStyle = {
      control: (styles, { isFocused }) => ({
        ...styles,
        backgroundColor: "#efefef7d",
        width: "2.1rem",
        border: "0px solid #D3D3D3",
        borderRadius: "8px",
        overflow: "wrap ",
        fontSize: "11px",
        marginRight: "5px",
        marginLeft: "1rem",
        textAlignLast: "center",
      }),

      singleValue: (provided) => ({
        ...provided,
        color: "black",
        fontWeight: "600",
      }),

      menu: (base) => ({
        ...base,
        zIndex: 10,
        overflow: "wrap",
        fontSize: "14px",
        width: "4rem",
        borderRadius: "8px",
        padding: "4px",
        textAlignLast: "center",
      }),

      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isSelected ? "#bb6bd9" : "white",
          color: isSelected ? "white" : "black",
          borderRadius: "5px",
          marginBottom: "5px",
          padding: "4px",
          fontSize: "12px",
        };
      },
    };
    const filterStyle = {
      control: (styles, { isFocused }) => ({
        ...styles,
        backgroundColor: "#efefef7d",
        width: "11rem",
        border: "0px solid #D3D3D3",
        borderRadius: "8px",
        overflow: "wrap ",
        fontSize: "11px",
        marginRight: "5px",
        marginLeft: "0.2rem",
        textAlignLast: "center",
      }),

      singleValue: (provided) => ({
        ...provided,
        color: "black",
        fontWeight: "600",
      }),

      menu: (base) => ({
        ...base,
        zIndex: 10,
        overflow: "wrap",
        fontSize: "14px",
        width: "18rem",
        borderRadius: "8px",
        padding: "4px",
      }),
      loadingIndicator: (styles) => ({
        ...styles,
        color: "#bb6bd9",
      }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isSelected ? "#bb6bd9" : "white",
          color: isSelected ? "white" : "black",
          borderRadius: "5px",
          marginBottom: "5px",
          padding: "4px",
          fontSize: "13px",
          marginLeft: "30px",
          fontWeight: "500",
        };
      },
    };
    const [selectedDataType, setSelectedDataType] = useState({
      value: "string",
      label: "Aa",
    });
    // console.log("property options - ", propertyOptions);
    const dataTypeOptions = [
      { value: "string", label: "Aa" },
      { value: "number", label: "#" },
      { value: "boolean", label: "0/1" },
    ];
    const [selectedOperator, setSelectedOperator] = useState({
      label: "=",
      value: "is",
    });
    const [operatorOptions, setOperatorOptions] = useState([
      { label: "=", value: "is" },
      { label: "≠", value: "is not" },
      { label: "∈", value: "contains" },
      { label: "∉", value: "does not contains" },
    ]);

    const [filterOptions, setFilterOptions] = useState([]);

    const [selectedProperty, setSelectedProperty] = useState("");
    const [selectedFilters, setSelectedFilters] = useState([]);
    const handlDataTypeChange = (e) => {
      setSelectedDataType(e);
      setSelectedFilters([]);
    };

    const handleOperatorChange = (e) => {
      setSelectedOperator(e);
      let filters = selectedFilters;
      if (!Array.isArray(filters)) {
        filters = [{ label: e.target.value, value: e.target.value }];
      }
    };

    const handlePropertyOptionChanged = (e) => {
      setSelectedProperty(e);
      setSelectedFilters([]);
      setFilterOptions([]);
      console.log("event - ", event, " kitname - ", kitName);
      const InlineFilterAPI =
        "https://7mbhokze6g.execute-api.us-east-2.amazonaws.com/valueFunnel/value?event=" +
        event +
        "&kit=" +
        kitName.value +
        "&property=" +
        e.value;
      axios({
        method: "get",
        url: InlineFilterAPI,
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => {
          const datatype = cloneDeep(response.data.result.datatype);
          const filterList = cloneDeep(response.data.result.value);
          const allFilters = [];
          filterList.forEach((data) => {
            allFilters.push({ label: data, value: data });
          });
          setFilterOptions(allFilters);

          if (datatype === "String") {
            setSelectedDataType({ value: "string", label: "Aa" });
            dataTypeOptionChanged({ value: "string", label: "Aa" });
          } else if (datatype === "Number") {
            setSelectedDataType({ value: "number", label: "#" });
            dataTypeOptionChanged({ value: "number", label: "#" });
          }
          if (datatype === "Boolean") {
            setSelectedDataType({ value: "boolean", label: "0/1" });
            dataTypeOptionChanged({ value: "boolean", label: "0/1" });
          }
        })
        .catch((err) => {
          console.log("error in getting inline filters");
          console.log(err);
        });
    };
    const handleFilterChanged = (e) => {
      let filters = e;
      if (!Array.isArray(filters)) {
        filters = [{ label: e.target.value, value: e.target.value }];
      }
      setSelectedFilters(filters);
    };
    const createFilterDataSendObject = (filters) => {
      if (filter.length === "0") {
        return {
          filter: "",
          val: "",
          datatype: "",
          equator: "",
        };
      } else {
        let formattedFilterVal = filters;
        let filterObj = {};
        if (selectedOperator.value.includes("is")) {
          if (
            selectedDataType &&
            selectedDataType.value === "string" &&
            selectedOperator &&
            selectedOperator.value.includes("is")
          ) {
            formattedFilterVal = "'" + filters.join("','") + "'";
          } else if (selectedDataType && selectedDataType.value === "number") {
            if (selectedOperator && selectedOperator.value.includes("between"))
              formattedFilterVal = '"' + filters.join(",") + '"';
            else formattedFilterVal = filters.join(",");
          }
          filterObj = {
            filter: selectedProperty ? selectedProperty.value : "",
            val: formattedFilterVal ? formattedFilterVal : "",
            datatype: selectedDataType ? selectedDataType.value : "",
            equator: selectedOperator ? selectedOperator.value : "",
          };
        } else {
          //   console.log("correct path... value found in between");
          if (selectedDataType.value === "number") {
            // check if it is whole number or decimal
            if (filters % 1 != 0) formattedFilterVal = parseFloat(filters[0]);
            else formattedFilterVal = parseInt(filters[0]);
          }

          filterObj = {
            filter: selectedProperty ? selectedProperty.value : "",
            val: formattedFilterVal[0],
            datatype: selectedDataType ? selectedDataType.value : "",
            equator: selectedOperator ? selectedOperator.value : "",
          };
        }

        return filterObj;
      }
    };
    const dataTypeOptionChanged = (e) => {
      const selectedValue = e.value;
      if (selectedValue === "string") {
        const operators = [
          { label: "=", value: "is" },
          { label: "≠", value: "is not" },
          { label: "∈", value: "contains" },
          { label: "∉", value: "does not contains" },
        ];
        setSelectedOperator({ label: "=", value: "is" });
        setOperatorOptions(operators);
        setSelectedDataType({ label: "Aa", value: "string" });
      } else if (selectedValue === "number") {
        const operators = [
          { label: "=", value: "equals" },
          { label: "≠", value: "not equals" },
          { label: ">", value: "greater than" },
          { label: "<", value: "less than" },
          // {label:"∈", value:"between"}, {label:"∉", value:"not between"}
        ];

        setSelectedDataType({ label: "#", value: "number" });
        setSelectedOperator({ label: "=", value: "equals" });
        setOperatorOptions(operators);
      } else if (selectedValue === "boolean") {
        const operators = [
          { label: "true", value: "0" },
          { label: "false", value: "1" },
        ];
        setSelectedOperator({ label: "true", value: "0" });
        setSelectedDataType({ label: "0/1", value: "boolean" });
        setOperatorOptions(operators);
      }
    };
    return (
      <div
        style={{
          border: "1px solid rgba(202,202,202,0.47)",
          borderRadius: "10px",
          padding: "6px 6px 6px 12px",
          marginBottom: "8px",
        }}>
        <div
          style={{
            border: "0px solid black",
            display: "flex",
            alignItems: "center",
            marginBottom: "4px",
          }}>
          <Select
            id={"dataType-selector-" + id}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            closeMenuOnSelect
            styles={dataTypeStyle}
            value={selectedDataType}
            options={dataTypeOptions}
            onChange={handlDataTypeChange}
            menuPlacement="auto"
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            isSearchable={false}
            // isDisabled={isDisabledStepSelector}
            // menuPosition="fixed"
          />
          <Select
            id={"filter-selector-" + id}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            closeMenuOnSelect
            placeholder="select a property"
            styles={propertyStyle}
            value={selectedProperty}
            options={propertyOptions}
            isLoading={propertyOptions.length === 0}
            isDisabled={propertyOptions.length === 0}
            onChange={handlePropertyOptionChanged}
            menuPlacement="auto"
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
          />
          <ClearIcon
            className="clear-icon-btn"
            onClick={() => removeFilter(id)}
          />
        </div>
        <hr style={{ margin: "13px 13px 8px 13px" }} />
        <div style={{ display: "flex" }}>
          <Select
            id={"operator-selector-" + id}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            closeMenuOnSelect
            styles={operatorStyle}
            value={selectedOperator}
            options={operatorOptions}
            isSearchable={false}
            onChange={handleOperatorChange}
            menuPlacement="auto"
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
          />
          {selectedOperator.value.includes("is") ? (
            <>
              <Select
                id={"filter-selector-" + id}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                isMulti
                closeMenuOnSelect={false}
                styles={filterStyle}
                value={selectedFilters}
                options={filterOptions}
                onChange={handleFilterChanged}
                menuPlacement="auto"
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                isDisabled={filterOptions.length === 0}
                isLoading={filterOptions.length === 0}
              />
            </>
          ) : (
            <input
              type="text"
              name=""
              id=""
              className="filter-selector-input"
              onChange={handleFilterChanged}
            />
          )}
        </div>
      </div>
    );
  }
);

export default FilterDropdown;
