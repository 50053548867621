import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  InputGroup,
  FormControl,
  Modal,
  Button,
} from "react-bootstrap";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import copy from "copy-text-to-clipboard";

import SyncLoader from "react-spinners/PulseLoader";
import axios from "axios";
import FurnaceLogo from "../../../assets/reports/FrameNew.svg";
import FeatureHeaderIcon from "../../../assets/reports/feature-header-icon.svg";
import FeatureOptionsIcon from "../../../assets/reports/feature-options-icon.svg";
import SaveIcon from "../../../assets/reports/save_icon.svg";
import "./header.scss";
import { Link, useHistory } from "react-router-dom";
import Select, { components } from "react-select";
import moment from "moment";
import { css } from "@emotion/react";
import queryString from "query-string";
const Header = ({
  savedReportNameFromDB,
  lastUpdatedAt,
  savedReportIdFromDB,
}) => {
  const [modalShow, setModalShow] = React.useState(false);
  const history = useHistory();
  const [shareModalShow, setShareModalShow] = React.useState(false);
  const [reportId, setReportId] = useState(savedReportIdFromDB);
  const featureTypeOptions = [
    { label: "Insights", value: "Insights" },
    { label: "Funnel", value: "Funnel" },
    { label: "Distribution", value: "Distribution" },
  ];
  const override = css`
    position: absolute;
    left: 58%;
    top: 40%;
    z-index: 999;
  `;
  const [savedReportName, setSavedReportName] = useState("");
  const [lastSaveTime, setLastSavedTime] = useState(lastUpdatedAt);
  let [loading, setLoading] = useState(false);
  const [displayLink, setDisplayLink] = useState(false);
  function createNotification(type) {
    if (type === "success")
      return NotificationManager.success("Report Saved", "success");
    else if (type === "error")
      return NotificationManager.warning(
        "Unable to save, please validate input",
        "Error"
      );
  }

  const featureTypeSelectStyle = {
    control: (styles, { isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: "transparent",
      width: "180px",
      overflow: "wrap ",
      fontSize: "20px",
      textAlignLast: "center",
      border: isFocused ? "none" : "none",
      color: "black",
      fontWeight: "700",
      marginRight: "90px",
    }),

    menu: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        width: "145px",
        borderRadius: "5px",
        padding: "5px",
      };
    },
    dropdownIndicator: (base) => ({
      ...base,
      color: "black",
      marginLeft: "-6px",
    }),

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        borderRadius: "10px",
        marginBottom: "4px",
        textAlign: "center",
        fontWeight: isSelected ? 600 : 500,
        backgroundColor: isSelected
          ? "#BB6BD9"
          : isFocused
          ? "#ddedf2"
          : "white",
      };
    },
  };

  useEffect(() => {
    setSavedReportName(savedReportNameFromDB);
    setLastSavedTime(lastUpdatedAt);
    setReportId(savedReportIdFromDB);
  }, [savedReportNameFromDB]);
  const handleReportSave = () => {
    console.log("save button clicked");
    console.log("local storage - ");
    const reportData = JSON.parse(localStorage.getItem("reportData"));
    console.log(reportData);
    setModalShow(true);
  };
  const handleReportShare = () => {
    setShareModalShow(true);
  };

  const saveCurrentReport = () => {
    const reportType = "Funnel";
    const reportName = document.getElementById("report-title").value;
    console.log("report name - ", reportName);
    if (!reportName || reportName === "") {
      alert("Report title is required");
      return;
    }
    console.log("save current report called");
    const reportData = JSON.parse(localStorage.getItem("reportData"));
    const createdAt = moment().format("MMMM Do YYYY, h:mm:ss a");
    const payloadData = {
      reportType: reportType,
      reportName: reportName,
      reportData: reportData.queryData,
      createdAt: createdAt,
    };

    setModalShow(false);
    console.log("payload data ", payloadData);
    setLoading(true);
    // const saveReportAPI =
    //   "http://localhost:5001/mws-sales-data/us-central1/FurnaceSaveReport";

    const saveReportAPI =
      "https://us-central1-mws-sales-data.cloudfunctions.net/FurnaceSaveReport";
    axios({
      method: "POST",
      url: saveReportAPI,
      data: payloadData,
      headers: { "Content-Type": "application/json" },
    })
      .then(function (response) {
        setLoading(false);
        console.log("response - ", response);

        setSavedReportName(reportName);
        setLastSavedTime(createdAt);
        if (!response.data.body) {
          createNotification("error");
          return;
        }
        createNotification("success");
        setReportId(response.data.body);
        console.log("save report response received: ");
      })
      .catch((error) => {
        setLoading(false);
        createNotification("error");
        console.log("error in funnel query!");
        createNotification("success");
        console.log(error);
        alert("Error in save report");
      });
  };

  function SavePopupModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        style={{ padding: "0px 4px 0px 4px", borderRadius: "15px" }}
        centered>
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ color: "rgb(91 91 91)", fontWeight: "600" }}>
            Save Distribution Report
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">Report Type</InputGroup.Text>
            <FormControl
              // placeholder="ex.. get plugo all users"
              id="report-type"
              aria-label="Username"
              aria-describedby="basic-addon1"
              value="Distribution"
              disabled
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">Report Title*</InputGroup.Text>
            {savedReportName !== "" ? (
              <FormControl
                // placeholder="ex.. get plugo all users"
                id="report-title"
                aria-label="Username"
                aria-describedby="basic-addon1"
                required
                defaultValue={savedReportName}
              />
            ) : (
              <FormControl
                // placeholder="ex.. get plugo all users"
                id="report-title"
                aria-label="Username"
                aria-describedby="basic-addon1"
                required
              />
            )}
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            style={{ width: "80px", padding: "8px", fontWeight: "600" }}
            onClick={() => {
              setModalShow(false);
              setDisplayLink(false);
            }}>
            Cancel
          </Button>
          <Button
            style={{ width: "80px", padding: "8px", fontWeight: "600" }}
            variant="success"
            onClick={() => saveCurrentReport()}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  function SharePopupModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        style={{ padding: "0px 4px 0px 4px", borderRadius: "15px" }}
        centered>
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ color: "rgb(91 91 91)", fontWeight: "600" }}>
            Share Distribution Report
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {savedReportName !== "" ? (
            <>
              <InputGroup className="mb-3" style={{ marginTop: "10px" }}>
                <span style={{ fontWeight: "500", color: "rgb(91 91 91)" }}>
                  Report title - {savedReportName}
                </span>
              </InputGroup>
              <InputGroup className="mb-3" style={{ marginTop: "20px" }}>
                <span style={{ fontWeight: "500", color: "rgb(91 91 91)" }}>
                  {" "}
                  Last saved at - {lastSaveTime}
                </span>
              </InputGroup>

              <InputGroup className="mb-3" style={{ marginTop: "20px" }}>
                <InputGroup.Text
                  id="basic-addon1"
                  style={{
                    borderRadius: "10px",
                    border: "2px solid #3f3a3e6b",
                    marginRight: "9px",
                    fontWeight: "500",
                    color: "rgb(91 91 91)",
                  }}>
                  Report link
                </InputGroup.Text>
                <FormControl
                  // placeholder="ex.. get plugo all users"
                  id="report-link"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  disabled
                  style={{
                    borderRadius: "10px",
                    border: "2px solid #3f3a3e6b",
                    fontWeight: "500",
                    color: "rgb(91 91 91)",
                  }}
                  value={
                    "https://furnace.playshifu.com/Reports/Distribution?id=" +
                    reportId
                  }
                />

                <button
                  style={{
                    background: "white",
                    border: "2px solid rgb(183 96 199)",
                    borderRadius: "6px",
                    padding: "8px",
                    marginLeft: "8px",
                    color: "rgb(169 87 213)",
                    fontWeight: "600",
                  }}
                  onClick={() =>
                    copy(
                      "https://furnace.playshifu.com/Reports/Distribution?id=" +
                        reportId
                    )
                  }>
                  Copy
                </button>
              </InputGroup>
            </>
          ) : (
            <span>Report not saved yet</span>
          )}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
  const handleFeatureTypeChanged = (e) => {
    if (e.value === "Insights") history.push("/Reports/Insight");
    if (e.value === "Funnel") history.push("/Reports/Funnel");
  };
  const selectedFeatureType = { label: "Distribution", value: "Distribution" };

  return (
    <>
      <Row style={{ marginTop: "20px" }}>
        <SyncLoader
          color="#bb6bd9"
          id={"panel-loader"}
          loading={loading}
          css={override}
          size={18}
          margin={8}
        />
        <NotificationContainer />
        <Col lg="4">
          <Link to="/">
            <img
              src={FurnaceLogo}
              alt=""
              style={{ marginLeft: "28px", width: "160px", cursor: "pointer" }}
            />
          </Link>
          <span className="report-span">/ Reports</span>
          {savedReportName !== "" ? (
            <span className="report-title-span"> / {savedReportName}</span>
          ) : (
            ""
          )}
        </Col>
        <Col
          lg="6"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <img
            src={FeatureHeaderIcon}
            alt=""
            style={{ width: "28px", marginRight: "5px" }}
          />
          <Select
            components={{
              IndicatorSeparator: () => null,
            }}
            closeMenuOnSelect
            styles={featureTypeSelectStyle}
            value={selectedFeatureType}
            options={featureTypeOptions}
            onChange={handleFeatureTypeChanged}
            isSearchable={false}
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
          />
        </Col>
        <Col
          lg="2"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}>
          <button className="header-btn" onClick={handleReportShare}>
            Share
          </button>
          <button className="header-btn" onClick={handleReportSave}>
            <img src={SaveIcon} alt="" style={{ marginRight: "8px" }} />
            Save
          </button>
          <img src={FeatureOptionsIcon} alt="" style={{ width: "28px" }} />
        </Col>
      </Row>
      <hr />

      <SavePopupModal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
      />
      <SharePopupModal
        show={shareModalShow}
        onHide={() => {
          setShareModalShow(false);
        }}
      />
    </>
  );
};

export default Header;
