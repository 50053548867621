import React, { useEffect, useRef, useState } from "react";
import ReportEmptyIcon from "../../../assets/reports/report-empty-icon.svg";
import { Row, Col } from "react-bootstrap";
import Select, { components } from "react-select";
import Switch from "react-switch";
import "./rightPanel.scss";
import ChartPanel2 from "../../../Dashboard/MainPanel/ChartPanel2/ChartPanel2";
import axios from "axios";
import StringToColor from "string-to-color";
import { nanoid } from "nanoid";
import SyncLoader from "react-spinners/PulseLoader";
import { cloneDeep } from "lodash";
import { css } from "@emotion/react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import { DateRange } from "react-date-range";
const RightPanel = ({ queryData, savedEventData }) => {
  const bucketStyle = {
    control: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: "white",
      width: "110px",
      border: "0px solid rgba(0, 0, 0, 0.3)",
      borderRadius: "10px",
      overflow: "wrap ",
      fontSize: "12px",
      height: "0.9vh",
      marginTop: "1vh",
      marginBottom: "32px",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 10,
      overflow: "wrap",
      fontSize: "13px",
      width: "110px",
      borderRadius: "20px",
      padding: "10px",
    }),

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#bb6bd9" : "white",
        color: isSelected ? "white" : "black",
        borderRadius: "10px",
        marginBottom: "5px",
        padding: "8px",
        fontSize: "12px",
        fontWeight: "500",
      };
    },
  };
  const override = css`
    position: absolute;
    left: 58%;
    top: 40%;
    z-index: 999;
  `;
  let [loading, setLoading] = useState(false);
  const bucketOptions = [
    { label: "Bucket 1", value: "1" },
    { label: "Bucket 2", value: "2" },
    { label: "Bucket 5", value: "5" },
    { label: "Bucket 10", value: "10" },
    { label: "Bucket 20", value: "20" },
  ];
  const [selectedBucket, setSelectedBucket] = useState({
    label: "Bucket 1",
    value: "1",
  });
  const [customSelectionState, setCustomSelectionState] = useState("Custom");
  const [customIsOpen, setCustomIsOpen] = useState("hiddenDateRange");
  const [selectionDateRange, setSelectionDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const insightResponseRef = useRef();
  const dropOffResponseRef = useRef();
  const dataPointsLimit = 20;
  const bucketSizeRef = useRef(1);
  const breakdownAppliedRef = useRef(false);
  const dataFormatRef = useRef("Linear");
  const switchStatusRef = useRef(false);
  const [chartType, setChartType] = useState("bar");
  const [chartData, setChartData] = useState();
  const [displayingChart, setDisplayingChart] = useState(false);
  const chartRef = useRef();
  const [finalQueryData, setFinalQueryData] = useState({});

  const selectedDateIdRef = useRef("date-range-7D");

  const [switchCheckStatus, setSwitchCheckStatus] = useState(false);

  const dateRangeOptions = [
    // "Today",
    "Yesterday",
    "7D",
    "30D",
    "3M",
    "6M",
    "12M",
    "24M",
    "Custom",
  ];

  const handleSwitchChanged = (checked) => {
    setSwitchCheckStatus(checked);
    switchStatusRef.current = checked;
    let lablesArr = [];
    let datasetsArr = [];
    generateChartForDropOff();
    setDisplayingChart(true);
  };

  const formatQueryDataForInsight = () => {
    const [from_date, to_date] = formatDate(selectedDateIdRef.current);
    let chartyQueryData = !queryData ? savedEventData : queryData;
    let finalQueryData = chartyQueryData.map((eventQueryData) => {
      eventQueryData["from_date"] = from_date;
      eventQueryData["to_date"] = to_date;
      return eventQueryData;
    });
    setFinalQueryData(finalQueryData);
    let reportData = {};
    reportData["reportType"] = "Funnel";
    reportData["queryData"] = finalQueryData;
    localStorage.setItem("reportData", JSON.stringify(reportData));
    return finalQueryData;
  };

  const getInsightResponse = async (eventInsightQuery) => {
    return new Promise((resolve, reject) => {
      const insightQueryAPI =
        "https://asuse3algl.execute-api.us-east-2.amazonaws.com/insightFurnace/v2";
      axios({
        method: "post",
        url: insightQueryAPI,
        data: eventInsightQuery, //qs.stringify(selectionData),
        headers: { "Content-Type": "application/json" },
      })
        .then(function (response) {
          // setLoading(false);
          console.log("insight query response received: ");
          console.log(response);
          if (response.status == 200) {
            console.log("RESOLVING - ", {
              response: response.data.response,
            });
            resolve(response.data.response);
          } else {
            alert("insight api request failed!");
            reject("error");
          }
        })
        .catch((error) => {
          // setLoading(false);
          console.log("error in insight query!");
          console.log(error);
          alert("Error in insight query, please validate selected options");
          reject(error);
        });
    });
  };
  useEffect(() => {
    if (savedEventData) {
      const fetchFromSaved = true;
      // generateInsightChart(fetchFromSaved);
    }
  }, [savedEventData]);
  useEffect(async () => {
    if (queryData) {
      // generateInsightChart();
      const [from_date, to_date] = formatDate(selectedDateIdRef.current);
      queryData["from_date"] = from_date;
      queryData["to_date"] = to_date;
      breakdownAppliedRef.current = queryData.grouper === "" ? false : true;
      const dropOffResponse = await getDropOffResponse(queryData);
      console.log("drop off response - ", dropOffResponse);
      dropOffResponseRef.current = cloneDeep(dropOffResponse);

      generateChartForDropOff();
      setDisplayingChart(true);
      /* const allEvents = [];
      Object.entries(queryData["event_data"]).forEach((event) => {
        const [key, value] = event;
        allEvents.push(Object.keys(value)[0]);
      });
      const chartData = prepareChartDataForFunnel(funnelResponse, allEvents);
      console.log("chart data - ", chartData);
      setDisplayingChart(false);
      setChartData(chartData);
      setDisplayingChart(true); */
    }
  }, [queryData]);

  const serializeData = (dataReceived, bucketSize) => {
    const data = dataReceived;
    const newData = { x: [], y: [] };
    let iter = 1;
    if (data.x[0] === 0) iter = 0;
    let idx = 0;
    console.log("inside serialize,", data);
    while (iter <= dataPointsLimit * bucketSize && idx < data.x.length) {
      if (data.x[idx] === iter) {
        newData.x.push(iter);
        newData.y.push(data.y[idx]);
        iter++;
        idx++;
      } else {
        newData.x.push(iter);
        newData.y.push(0);
        iter++;
      }
    }
    console.log("inside serialize,", data);
    return newData;
  };

  const generateChartForDropOff = () => {
    console.log("generate chart for drop offf is called!!!");
    let bucketSize = bucketSizeRef.current;
    let data = dropOffResponseRef.current;
    console.log("data - ", data);

    if (breakdownAppliedRef.current) {
      // line chart
      const backgroundColor = "rgba(100, 194, 245,0.7)";
      const borderColor = "rgba(100, 194, 245,0.7)";
      const borderWidth = 1;
      const hoverBackgroundColor = "rgba(100, 194, 245,0.9)";
      const hoverBorderColor = "rgba(100, 194, 245,0.7)";
      let labels = [];
      const datasets = [];
      const storeLabels = [];
      let dsCount = 0;
      Object.entries(data).forEach((dsEntries) => {
        let [dataset, data] = dsEntries;
        let sumOfYAxes = 0;
        data.y.forEach((d) => (sumOfYAxes += d));
        data.x = data.x.splice(0, dataPointsLimit * bucketSize);
        data.y = data.y.splice(0, dataPointsLimit * bucketSize);
        data = serializeData(data, bucketSize);
        console.log("after serialization - ", data.x);
        let cummulative = 0;
        let iter = 1;
        console.log("data[x] = ", data.x);
        if (data.x[0] === 0) iter = 0;
        // bucket logic
        let newChartData = { x: [], y: [] };
        for (let index = 0; index < data.y.length; index++) {
          const bucketPoint = bucketSize * iter;
          if (data.x[index] <= bucketPoint) {
            cummulative += data.y[index];
          } else {
            index--;
            newChartData.x.push(bucketPoint);
            newChartData.y.push(cummulative);
            cummulative = 0;
            iter++;
          }
          if (index === data.y.length - 1) {
            newChartData.x.push(bucketPoint);
            newChartData.y.push(cummulative);
          }
        }
        data = newChartData;
        let yAxisTotal = 0;
        const yAxis = [];
        data.y.forEach((point) => (yAxisTotal += point));
        data.x.forEach((x) => {
          if (!isNaN(x)) storeLabels.push(x);
        });
        const dataPoints = [];
        if (switchStatusRef.current) {
          for (let index = 0; index < data.y.length; index++) {
            data.y[index] += cummulative;
            cummulative = data.y[index];
          }
          const lastElem = data.y[data.y.length - 1];
          for (let index = 0; index < data.x.length; index++) {
            const pointX = data.x[index];
            const pointY = Math.floor((data.y[index] * 100) / lastElem);
            dataPoints.push({ x: pointX, y: pointY });
          }
        } else {
          for (let index = 0; index < data.x.length; index++) {
            const pointX = data.x[index];
            const pointY = Math.floor((data.y[index] * 100) / sumOfYAxes);
            dataPoints.push({ x: pointX, y: pointY });
          }
        }

        // const randomColor = getRandomColor();

        // const randomColor = stringToColour(dataset);
        // alert("string to color - " + dataset);
        const randomColor = StringToColor(dataset.toLowerCase());
        let isHidden = true;
        if (dsCount < 3 && dataPoints.length > 2) {
          dsCount++;
          isHidden = false;
        }
        datasets.push({
          label: dataset,
          borderColor: randomColor,
          borderWidth: 2,
          hoverBackgroundColor: randomColor,
          hoverBorderColor: randomColor,
          tension: 0.2,
          data: dataPoints,
          hidden: isHidden,
        });
        function onlyUnique(value, index, self) {
          return self.indexOf(value) === index;
        }
        let uniqueLabels2 = storeLabels.filter(onlyUnique);
        uniqueLabels2.sort(function (a, b) {
          return a - b;
        });
        labels = [...uniqueLabels2];

        const ds = { labels, datasets };
        console.log("final dataset generated");
        console.log(ds);
        setChartData(ds);

        setChartType("line");
        // alert("tye also updated!");
        chartRef.current?.updateChartAndType(ds, "line");
      });
    } else {
      // bar chart
      const yAxis = [];
      let yAxisTotal = 0;
      let cummulative = 0;
      let iter = 1;
      console.log("data[x] = ", data.x);
      if (data.x[0] === 0) {
        iter = 0;
      }
      let sumOfYAxes = 0;
      data.y.forEach((d) => (sumOfYAxes += d));
      // console.log("sum of y axes ", sumOfYAxes);

      data.x = data.x.splice(0, dataPointsLimit * bucketSize);
      data.y = data.y.splice(0, dataPointsLimit * bucketSize);
      // console.log("before serialize data: ", data.y);
      data = serializeData(data, bucketSize);
      // console.log("serialize data: ", data);
      let newChartData = { x: [], y: [] };
      console.log("after serialize - ", data);
      // combine multiple bar's values based on bucket size
      for (let index = 0; index < data.y.length; index++) {
        const bucketPoint = bucketSize * iter;
        if (data.x[index] <= bucketPoint) {
          cummulative += data.y[index];
        } else {
          index--;
          newChartData.x.push(bucketPoint);
          newChartData.y.push(cummulative);
          cummulative = 0;
          iter++;
        }
        if (index === data.y.length - 1) {
          newChartData.x.push(bucketPoint);
          newChartData.y.push(cummulative);
        }
      }
      data = newChartData;
      console.log("after bucketing", data);
      // console.log("y before linear - ", data.y);
      if (!switchStatusRef.current) {
        // this is for linear data
        data.y.forEach((point) => (yAxisTotal += point));
        data.y.forEach((point) => yAxis.push((point * 100) / sumOfYAxes));
      } else {
        // this is for cummulative data
        for (let index = 0; index < data.y.length; index++) {
          data.y[index] += cummulative;
          cummulative = data.y[index];
        }
        const lastElem = data.y[data.y.length - 1];
        data.y.forEach((point) => yAxis.push((point * 100) / sumOfYAxes));
      }

      // console.log("y after linear - ", yAxis);
      const ds = {
        labels: data.x,
        datasets: [
          {
            label: "drop-off%",
            backgroundColor: "rgba(100, 194, 245,0.7)",
            borderColor: "rgba(100, 194, 245,0.7)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(100, 194, 245,0.9)",
            hoverBorderColor: "rgba(100, 194, 245,0.7)",
            borderRadius: 5,
            data: yAxis,
          },
        ],
      };
      console.log("final dataset generated");
      console.log(ds);
      setChartData(ds);
      setChartType("bar");
      // alert("type updated to bar");
      chartRef.current?.updateChartAndType(ds, "bar");
    }
  };

  const getDropOffResponse = async (payloadData) => {
    return new Promise((resolve, reject) => {
      const allEvents = [];
      setLoading(true);

      const dropOffQuery =
        "https://5nrevmwo7b.execute-api.us-east-2.amazonaws.com/dropoffQuery/v2";
      axios({
        method: "post",
        url: dropOffQuery,
        data: payloadData, //qs.stringify(selectionData),
        headers: { "Content-Type": "application/json" },
      })
        .then(function (response) {
          setLoading(false);
          console.log("funnel query response received: ");
          console.log(response);
          if (response.status == 200) {
            console.log("response.data ", response.data);
            resolve(response.data.from);
          } else {
            alert("distribution api request failed!");

            reject(null);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log("error in distribution query!");
          console.log(error);
          alert(
            "Error in distribution query, please validate selected options"
          );
          reject(null);
        });
    });
  };

  const handleBucketChange = (e) => {
    console.log("bucket - ", e.target.value);
    bucketSizeRef.current = e.target.value;
    if (dropOffResponseRef.current) {
      generateChartForDropOff();
      setDisplayingChart(true);
    }
  };

  useEffect(() => {
    document.getElementById(selectedDateIdRef.current).style.fontWeight = "800";
  }, []);
  const handleDateRangeSelected = async (event) => {
    setCustomIsOpen("hiddenDateRange");
    setCustomSelectionState("Custom");
    document.getElementById("date-range-Custom").textContent = "Custom";
    document.getElementById(selectedDateIdRef.current).style.fontWeight = "500";
    selectedDateIdRef.current = event.target.id;
    document.getElementById(event.target.id).style.fontWeight = "800";
    if (!queryData && savedEventData) {
      // generateInsightChart(true);
    } else {
      // generateInsightChart();
      const [from_date, to_date] = formatDate(selectedDateIdRef.current);
      queryData["from_date"] = from_date;
      queryData["to_date"] = to_date;
      const dropOffResponse = await getDropOffResponse(queryData);
      console.log("drop off response - ", dropOffResponse);
      dropOffResponseRef.current = cloneDeep(dropOffResponse);
      const allEvents = [];
      setDisplayingChart(false);
      console.log("query data - ", queryData);
      breakdownAppliedRef.current = queryData.grouper === "" ? false : true;
      generateChartForDropOff();
      setDisplayingChart(true);
    }
  };

  const formatDate = (date) => {
    let fromDate = new Date();
    let toDate = new Date();
    if (date.includes("7D")) {
      fromDate.setDate(fromDate.getDate() - 7);
    } else if (date.includes("30D")) {
      fromDate.setMonth(fromDate.getMonth() - 1);
    } else if (date.includes("3M")) {
      fromDate.setMonth(fromDate.getMonth() - 3);
    } else if (date.includes("6M")) {
      fromDate.setMonth(fromDate.getMonth() - 6);
    } else if (date.includes("12M")) {
      fromDate.setMonth(fromDate.getMonth() - 12);
    } else if (date.includes("24M")) {
      fromDate.setMonth(fromDate.getMonth() - 24);
    } else if (date.includes("Today")) {
      fromDate = toDate;
    } else if (date.includes("Yesterday")) {
      fromDate.setDate(fromDate.getDate() - 1);
      toDate.setDate(toDate.getDate() - 1);
    } else if (date.includes("Custom")) {
      fromDate = selectionDateRange.startDate.toLocaleString().split(",")[0];
      toDate = selectionDateRange.endDate.toLocaleString().split(",")[0];
      let year = fromDate.split("/")[2];
      let month = fromDate.split("/")[1];
      let day = fromDate.split("/")[0];
      fromDate = year + "-" + month + "-" + day;
      year = toDate.split("/")[2];
      month = toDate.split("/")[1];
      day = toDate.split("/")[0];
      toDate = year + "-" + month + "-" + day;
      return [fromDate, toDate];
    } else {
      fromDate = "";
      toDate = "";
    }
    if (fromDate !== "")
      return [
        fromDate.getFullYear() +
          "-" +
          String(fromDate.getMonth() + 1).padStart(2, "0") +
          "-" +
          String(fromDate.getDate()).padStart(2, "0"),
        toDate.getFullYear() +
          "-" +
          String(toDate.getMonth() + 1).padStart(2, "0") +
          "-" +
          String(toDate.getDate()).padStart(2, "0"),
      ];
    else return ["", ""];
  };

  const handleCustomButtonClicked = async () => {
    document.getElementById(selectedDateIdRef.current).style.fontWeight = "500";
    selectedDateIdRef.current = "date-range-Custom";
    document.getElementById(selectedDateIdRef.current).style.fontWeight = "800";
    if (customSelectionState === "Custom") {
      setCustomSelectionState("Apply Range");
      document.getElementById("date-range-Custom").textContent = "Apply Range";
      setCustomIsOpen("visibleDateRange");
    } else {
      setCustomSelectionState("Custom");

      let fromDate = selectionDateRange.startDate;
      let toDate = selectionDateRange.endDate;
      document.getElementById("date-range-Custom").textContent =
        fromDate.toLocaleString().split(",")[0] +
        " - " +
        toDate.toLocaleString().split(",")[0];
      setCustomIsOpen("hiddenDateRange");
      const [from_date, to_date] = formatDate(selectedDateIdRef.current);
      if (queryData) {
        queryData["from_date"] = from_date;
        queryData["to_date"] = to_date;
        const dropOffResponse = await getDropOffResponse(queryData);
        console.log("drop off response - ", dropOffResponse);
        dropOffResponseRef.current = cloneDeep(dropOffResponse);
        const allEvents = [];
        setDisplayingChart(false);
        console.log("query data - ", queryData);
        breakdownAppliedRef.current = queryData.grouper === "" ? false : true;
        generateChartForDropOff();
        setDisplayingChart(true);
      }
    }
  };
  const handleCustomDateRangeSelect = (ranges) => {
    console.log(ranges);
    if ("range1" in ranges) {
      setSelectionDateRange({
        startDate: ranges.range1.startDate,
        endDate: ranges.range1.endDate,
        // key: "selection",
      });
    } else {
      setSelectionDateRange({
        startDate: ranges.selection.startDate,
        endDate: ranges.selection.endDate,
        // key: "selection",
      });
    }
  };
  return (
    <>
      <Row
        style={{
          width: "100%",
          border: "1px solid #DFDFDF",
          height: "500px",
          borderRadius: "24px",
          flexDirection: "column",
        }}>
        <Col
          lg="12"
          style={{ display: "flex", marginTop: "10px", marginBottom: "10px" }}>
          <Col lg="8">
            {dateRangeOptions.map((option, index) => {
              const additionClass =
                index === dateRangeOptions.length - 1
                  ? "date-range-last-btn"
                  : index === 0
                  ? "date-range-first-btn"
                  : "";
              return (
                <button
                  className={"date-range-btn " + additionClass}
                  id={"date-range-" + option}
                  onClick={
                    option === "Custom"
                      ? handleCustomButtonClicked
                      : handleDateRangeSelected
                  }>
                  {option}
                </button>
              );
            })}
            <DateRange
              ranges={[selectionDateRange]}
              onChange={handleCustomDateRangeSelect}
              className={customIsOpen}
              maxDate={new Date()}
            />
          </Col>
          <Col
            lg="3"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              // height: "10vh",
            }}>
            {/* <Select
              id={"bucket-selector"}
              components={{
                IndicatorSeparator: () => null,
              }}
              closeMenuOnSelect
              // placeholder="Select here"
              styles={bucketStyle}
              value={selectedBucket}
              options={bucketOptions}
              // onChange={handleBucketChange}
              menuPlacement="auto"
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              isSearchable={false}
            /> */}
            <select
              name=""
              id="bucket-selection-select"
              style={{
                border: "1px solid #d0d0d0",
                borderRadius: "8px",
                padding: "5px",
                fontSize: "0.9rem",
                color: "#828282",
              }}
              onChange={handleBucketChange}>
              {bucketOptions.map((bucket) => {
                return <option value={bucket.value}>{bucket.label}</option>;
              })}
            </select>
          </Col>
          <Col
            lg="1"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}>
            <label htmlFor="material-switch">
              <Switch
                onChange={handleSwitchChanged}
                checked={switchCheckStatus}
                onColor="#72C8CC"
                onHandleColor="#fffff"
                uncheckedIcon={false}
                checkedIcon={false}
                className="react-switch"
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                // height={20}
                // width={40}
                // handleDiameter={4}
              />
            </label>
          </Col>
        </Col>
        <hr />
        {displayingChart ? (
          <Col
            lg="12"
            style={{
              height: "410px",
            }}>
            <ChartPanel2
              ref={chartRef}
              id={nanoid()}
              data={chartData}
              chartType={chartType}
            />
            <SyncLoader
              color="#bb6bd9"
              id={"panel-loader"}
              loading={loading}
              css={override}
              size={18}
              margin={8}
            />
          </Col>
        ) : (
          <Col
            lg="12"
            style={{
              height: "410px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <img src={ReportEmptyIcon} alt="" />
            <SyncLoader
              color="#bb6bd9"
              id={"panel-loader"}
              loading={loading}
              css={override}
              size={18}
              margin={8}
            />
          </Col>
        )}
      </Row>
    </>
  );
};

export default RightPanel;
