import React, { useEffect, useState } from "react";
import { Badge, Row, Col, OverlayTrigger, Tooltip, Modal, Button } from "react-bootstrap";
import axios from "axios";
import "./QueriesPanel.scss";
import Delete from "../../../assets/delete.png";
const QueriesPanel = ({ data, onHide, inpublicQuery = true, onQuerySelect }) => {
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const getBadgeColor = (ch) => {
		let color = "success";
		const charCode = ch.charCodeAt(0);
		if (charCode >= 65 && charCode <= 72) {
			color = "success";
		} else if (charCode >= 73 && charCode <= 83) {
			color = "primary";
		} else {
			color = "info";
		}

		return color;
	};
	const handleDeleteClick = (e) => {
		e.preventDefault();
	};
	// const hideQuery = (queryData) => {
	// 	console.log("hide query", queryData);
	// 	axios.delete(
	// 		`https://419st4b2i4.execute-api.us-east-2.amazonaws.com/Queries?id=${queryData.id}`
	// 	);
	// };
	const getQueryData = (queryData, idx) => {
		return (
			<>
				{!queryData.isHidden && (
					<div className="saved-query-parent" key={"parent-" + idx}>
						<div className="saved-query-header" key={"header-" + idx}>
							<OverlayTrigger
								placement="bottom"
								overlay={
									<Tooltip id="button-tooltip-2">{queryData.created_by}</Tooltip>
								}
							>
								<Badge
									bg={getBadgeColor(queryData.created_by.charAt(0).toUpperCase())}
									style={{
										fontSize: "1.55vw",
										marginTop: "1.85vh",
										marginLeft: "1.6vh",
										borderRadius: "18px",
										fontWeight: "100",
									}}
								>
									{queryData.created_by.charAt(0).toUpperCase()}
								</Badge>
							</OverlayTrigger>

							<span className="saved-query-time">{queryData.created_at}</span>
							<span onClick={handleShow}>
								{!inpublicQuery && (
									<img
										className="delete-icon"
										src={Delete}
										onClick={() => onHide(queryData)}
									/>
								)}
							</span>
							{/* <Modal show={show} onHide={handleClose} animation={false} key={"parent-" + idx}>
						<Modal.Header closeButton>
							<Modal.Title>Delete Query</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Are You sure you want to delete {JSON.stringify(queryData)} {idx}?
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={handleClose}>
								Close
							</Button>
							<Button variant="primary" onClick={handleClose}>
								Save Changes
							</Button>
						</Modal.Footer>
					</Modal> */}
						</div>
						<div
							className="saved-query-body"
							style={{ marginTop: "3vh", marginLeft: "1vw" }}
							key={"body-" + idx}
						>
							<span
								style={{
									fontSize: "1.6vh",
									fontWeight: "700",
									cursor: "pointer",
									color: "#686767",
								}}
								onClick={() => onQuerySelect(queryData.query_body, queryData.id)}
							>
								{queryData.title}
							</span>
						</div>
					</div>
				)}
			</>
		);
	};
	return (
		<>
			{/* Header selection */}
			{/* <Row style={{marginTop:"5vh", display:"flex"}} >
                <span>My Queries</span>
                <span>Public Queries</span>
            </Row> */}

			{/* All queries section */}
			<Row>{data.map(getQueryData)}</Row>
		</>
	);
};

export default QueriesPanel;
