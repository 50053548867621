import React, { useState, useRef, useImperativeHandle, forwardRef, useEffect } from "react";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
// import "./select-search.css";
import Select, { components } from "react-select";
import Badge from "react-bootstrap/Badge";
import { makeStyles } from "@material-ui/core/styles";
import FilterIconGrey from "../../../assets/filterGrey.svg";
import FilterIconColor from "../../../assets/filterColor.svg";
import { cloneDeep, filter } from "lodash";
import MUISelect from "@mui/material/Select";
import createClass from "create-react-class";
import axios from "axios";
// import SyncLoader from "react-spinners/SyncLoader";
import { css } from "@emotion/react";
import { autoType } from "d3-dsv";
import styling from "./Filter.module.scss";

const useStyles = makeStyles({
	root: {
		width: 132,
		borderRadius: 10,
		color: "grey",
	},
	select: {
		maxHeight: 48 * 4.5 + 1,
		fontSize: "8px",
		// width: 150,
		"& ul": {
			// backgroundColor: "#cccccc",
		},
		"& li": {
			fontSize: "8px",
		},
	},
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 200,
		},
	},
};

const DropdownSelection = forwardRef(
	({ remove, id, data, index, selectedValue = "", kit }, ref) => {
		const [filterDisplay, setFilterDisplay] = useState(false);
		const [loading, setLoading] = useState(false);
		const [selectedStep, setSelectedStep] = useState(null);
		const [selectedProperty, setSelectedProperty] = useState(null);
		const [propertyPlaceholder, setPropertyPlaceholder] = useState("property");
		const [singleFilterInput, setSingleFilterInput] = useState("");
		const [operatorOptions, setOperatorOptions] = useState([
			{ label: "=", value: "is" },
			{ label: "≠", value: "is not" },
			{ label: "∈", value: "contains" },
			{ label: "∉", value: "does not contains" },
		]);
		const allFilterDataRef = useRef("");
		const [propertyOptions, setPropertyOptions] = useState("");
		const [defaultDropdownValue, setDefaultDropdownValue] = useState({
			label: "=",
			value: "is",
		});
		const [personName, setPersonName] = React.useState();
		const [filterOptions, setFilterOptions] = useState([]);
		const [selectedDataType, setSelectedDataType] = useState({ value: "string", label: "Aa" });
		const [selectedOperator, setSelectedOperator] = useState({ label: "=", value: "is" });
		const [autoFillingData, setAutoFillingData] = useState(false);
		const stepsOptions = data.steps;
		useImperativeHandle(ref, () => ({
			getSelectedData() {
				// if theres is not filter selected, filterArr will be ["Filters"]
				let selectedFilterArray = [];
				let propertiesArray = [];
				let filtersArray = [];
				if (filterDisplay) {
					propertyOptions.forEach((data) => {
						propertiesArray.push(data.value);
					});
					// window.alert("hello");
					// if equalor is = or != , (multiple selections)
					if (selectedOperator.value.includes("is")) {
						filterOptions.forEach((data) => {
							filtersArray.push(data.value);
						});

						if (personName) {
							personName.forEach((person) => {
								selectedFilterArray.push(person.value);
							});
						}
						const selectedData = {
							id: id,
							step: selectedStep.value,
							property: selectedProperty,
							datatype: selectedDataType.value,
							operator: selectedOperator.value,
							filter: selectedFilterArray.length == 0 ? "" : selectedFilterArray,
							allProperties: propertiesArray,
							allFilters: filtersArray,
							selectedFilters: personName,
							isFilter: filterDisplay,
							selectedStep: selectedStep && selectedStep.value,
							selectedProperty: selectedProperty && selectedProperty.value,
						};
						return selectedData;
					} else {
						console.log("taking filter from inputbox");
						const selectedValue = document.getElementById(
							"select-filter-contains-" + id
						).value;
						console.log("selected value : " + selectedValue);
						setSingleFilterInput(selectedValue);
						const selectedData = {
							id: id,
							step: selectedStep,
							property: selectedProperty,
							datatype: selectedDataType.value,
							operator: selectedOperator.value,
							filter: selectedValue,
							allProperties: propertiesArray,
							isFilter: filterDisplay,
							selectedStep: selectedStep && selectedStep.value,
							selectedProperty: selectedProperty && selectedProperty.value,
						};
						return selectedData;
					}
				} else {
					const selectedData = {
						id: id,
						step: selectedStep,
						isFilter: filterDisplay,
						selectedStep: selectedStep && selectedStep.value,
					};
					return selectedData;
				}
			},
			notifyKitChange(kit) {
				console.log("notify kit change called!!");
				setPropertyOptions("");
				setFilterOptions("");
				setSelectedProperty("");
				setSelectedStep("");
				setPersonName([]);
				setFilterDisplay(false);
			},
		}));

		// console.log("ALL THE DATA IS HERE",getSelectedData);

		useEffect(() => {
			// for each panel will check if filter is applied or not
			// if applied, autofills all the properties when clicked on the back button(gear icon)

			console.log("------>  inside use effect <----------");
			console.log(data);
			if (data.isFilterAppliedList) {
				data.isFilterAppliedList.forEach((currentPanel) => {
					if (currentPanel.id === id && currentPanel.isFilterApplied == true) {
						setFilterDisplay(true);
					}
				});
				if (data.allFilters) {
					console.log("reached tlll here.......");
					console.log(data);
					data.allFilters.forEach((currentData) => {
						if (
							currentData.id === id &&
							currentData.filters &&
							currentData.filters.length > 0
						) {
							// console.log("found!!!");
							const filters = [];

							currentData.filters.forEach((filter) => {
								filters.push({ label: filter, value: filter });
							});
							// console.log("generated filter options");
							// console.log(filters);
							setFilterOptions(filters);
						}
					});

					if (data.allProperties) {
						data.allProperties.forEach((currentData) => {
							if (
								currentData.id === id &&
								currentData.properties &&
								currentData.properties.length > 0
							) {
								const properties = [];
								currentData.properties.forEach((filter) => {
									properties.push({ label: filter, value: filter });
								});
								// console.log("generated property options");
								// console.log(properties);
								// console.log("setting properties here.......");
								setPropertyOptions(properties);
							}
						});
					}
				}
			}

			if (data.allSelectedValues && data.allSelectedValues.length > 0) {
				data.allSelectedValues.forEach((currentData) => {
					if (currentData.id === id) {
						console.log("currentData for selected values");
						setAutoFillingData(true);
						setSelectedStep({
							label: currentData.selectedStep,
							value: currentData.selectedStep,
						});
						if (currentData.selectedProperty) {
							setSelectedProperty({
								label: currentData.selectedProperty,
								value: currentData.selectedProperty,
							});
							setPersonName(currentData.selectedFilters);
						}
					}
				});
			}
		}, []);

		const override = css`
			position: absolute;
			top: 42%;
			left: 43%;
			z-index: 999;
		`;

		const dataTypeOptions = [
			{ value: "string", label: "Aa" },
			{ value: "number", label: "#" },
			{ value: "boolean", label: "0/1" },
		];
		const classes = useStyles();
		const handleChange = (data) => {
			console.log("handle change called: data");
			console.log(data);
			setPersonName(data);
		};

		const propertyStyle = {
			control: (styles) => ({
				...styles,
				backgroundColor: "white",
				width: "110px",
				borderRight: "0px solid",
				borderRadius: "10px 0px 0px 10px",
				marginLeft: "18px",
				height: "30px",
				overflow: "wrap ",
				fontSize: "12px",
				"&:hover": {
					boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
				},
			}),

			menu: (styles, { data, isDisabled, isFocused, isSelected }) => {
				return {
					...styles,
					width: "320px",
					borderRadius: "20px",
					border: "1px solid #D3D3D3",
					padding: "8px",
					marginLeft: "-50px",
					zIndex: "3",
				};
			},

			option: (styles, { data, isDisabled, isFocused, isSelected }) => {
				return {
					...styles,
					backgroundColor: isSelected ? "#bb6bd9" : "white",
					fontSize: "12px",
					padding: "8px",
					marginBottom: "5px",
					borderRadius: "10px",
					color: "black",
					"&:hover": {
						backgroundColor: "rgba(187, 107, 217, 0.5)",
					},
					// borderBottom:"1px solid #C5C5C5C5"
				};
			},
		};

		const dataTypeStyles = {
			control: (styles) => ({
				...styles,
				backgroundColor: "white",
				width: "45px",
				borderLeft: "1px solid #D3D3D3",
				height: "30px",
				overflow: "wrap ",
				marginLeft: "0px",
				fontSize: "12px",
				borderRadius: "0px 10px 10px 0px",
				textAlignLast: "center",
				borderRight: "1px solid #D3D3D3",
				borderTop: "1px solid #D3D3D3",
				"&:hover": {
					boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
				},
			}),

			menu: (styles, { data, isDisabled, isFocused, isSelected }) => {
				return {
					...styles,
					width: "60px",
					borderRadius: "8px",
					padding: "4px",
				};
			},
			option: (styles, { data, isDisabled, isFocused, isSelected }) => {
				return {
					...styles,
					borderRadius: "10px",
					padding: "6px",
					marginBottom: "4px",
					textAlign: "center",
					backgroundColor: isSelected ? "#bb6bd9" : "white",
					"&:hover": {
						backgroundColor: "rgba(187, 107, 217, 0.5)",
					},
				};
			},
		};
		const operatorTypeSelect = {
			control: (styles) => ({
				...styles,
				backgroundColor: "white",
				width: "45px",
				borderLeft: "1px solid #D3D3D3",
				marginRight: "5px",
				height: "30px",
				overflow: "wrap ",
				marginLeft: "0px",
				fontSize: "12px",
				borderRadius: "10px",
				textAlignLast: "center",
				borderRight: "1px solid #D3D3D3",
				borderTop: "1px solid #D3D3D3",
				"&:hover": {
					boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
				},
			}),

			menu: (styles, { data, isDisabled, isFocused, isSelected }) => {
				return {
					...styles,
					width: "80px",
					borderRadius: "10px",
					padding: "10px",
				};
			},

			option: (styles, { data, isDisabled, isFocused, isSelected }) => {
				return {
					...styles,
					borderRadius: "10px",
					padding: "6px",
					marginBottom: "4px",
					textAlign: "center",
					backgroundColor: isSelected ? "#bb6bd9" : "white",
					"&:hover": {
						backgroundColor: "rgba(187, 107, 217, 0.5)",
					},
				};
			},
		};
		const stepStyle = {
			control: (styles) => ({
				...styles,
				backgroundColor: "white",
				width: "160px",
				border: "1px solid #D3D3D3",
				borderRadius: "10px",
				marginLeft: "8px",
				height: "30px",
				overflow: "wrap ",
				fontSize: "12px",
				marginRight: "3px",
				"&:hover": {
					boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
				},
			}),
			menu: (base) => ({
				...base,
				zIndex: 10,
				overflow: "wrap",
				fontSize: "13px",
				width: "250px",
				borderRadius: "20px",
				padding: "10px",
				// border: "1px solid red",
			}),
			option: (styles, { data, isDisabled, isFocused, isSelected }) => {
				return {
					...styles,
					color: "black",
					borderRadius: "10px",
					marginBottom: "5px",
					padding: "8px",
					fontSize: "12px",
					// cursor: "pointer",
					backgroundColor: isSelected ? "#bb6bd9" : "white",
					cursor: "pointer",
					"&:hover": {
						backgroundColor: "rgba(187, 107, 217, 0.5)",
					},
				};
			},
		};

		const filterStyle = {
			control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
				...styles,
				backgroundColor: "white",
				width: isFocused ? "350px" : "145px",
				zIndex: isFocused ? 3 : 2,
				borderLeft: "1px solid #D3D3D3",
				marginRight: "5px",
				height: "30px",
				overflow: "auto ",
				// marginLeft: isFocused ? "-220px" : "10px",
				fontSize: "12px",
				borderRadius: "10px",
				textAlignLast: "center",
				borderRight: "1px solid #D3D3D3",
				borderTop: "1px solid #D3D3D3",
				"&:hover": {
					boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
				},
			}),

			placeholder: (defaultStyles, { data, isDisabled, isFocused, isSelected }) => ({
				...defaultStyles,
				lineHeight: "25px",
				color: isFocused ? "white" : "#D5D5D5",
				// border: "5px solid red",
				width: "100%",
				height: "100%",
				marginTop: "15px",
			}),

			menu: (base) => ({
				...base,
				zIndex: 3,
				overflow: "wrap",
				fontSize: "13px",
				width: "380px",
				height: "300px",
				overflow: "wrap",
				marginLeft: "-220px",
				borderRadius: "20px",
				border: "1px solid #D3D3D3",
				padding: "8px",
			}),

			valueContainer: (provided, state) => ({
				...provided,
				textOverflow: "ellipsis",
				maxWidth: "90%",
				whiteSpace: "nowrap",
				overflow: "hidden",
				display: "initial",
				height: "100%",
				justifyContent: "center",
				alignItems: "center",
				textAlign: "center",
				// "&:hover": {
				// 	backgroundColor: "rgba(187, 107, 217, 0.5)",
				// },
			}),
			option: (styles, { data, isDisabled, isFocused, isSelected }) => {
				return {
					...styles,
					color: "black",
					borderRadius: "10px",
					// marginBottom: "5px",
					// padding: "8px",
					// fontSize: "12px",
					// // cursor: "pointer",
					// backgroundColor: isSelected ? "#bb6bd9" : "white",
					// cursor: "pointer",
					backgroundColor: isSelected ? "#bb6bd9" : "white",
					"&:hover": {
						backgroundColor: "rgba(187, 107, 217, 0.5)",
					},
				};
			},
		};

		const dataTypeOptionChanged = (e) => {
			const selectedValue = e.value;
			if (selectedValue === "string") {
				const operators = [
					{ label: "=", value: "is" },
					{ label: "≠", value: "is not" },
					{ label: "∈", value: "contains" },
					{ label: "∉", value: "does not contains" },
				];
				setSelectedOperator({ label: "=", value: "is" });
				setOperatorOptions(operators);
				setSelectedDataType({ label: "Aa", value: "string" });
			} else if (selectedValue === "number") {
				const operators = [
					{ label: "=", value: "equals" },
					{ label: "≠", value: "not equals" },
					{ label: ">", value: "greater than" },
					{ label: "<", value: "less than" },
					// {label:"∈", value:"between"}, {label:"∉", value:"not between"}
				];

				setSelectedDataType({ label: "#", value: "number" });
				setSelectedOperator({ label: "=", value: "equals" });
				setOperatorOptions(operators);
			} else if (selectedValue === "boolean") {
				const operators = [
					{ label: "true", value: "0" },
					{ label: "false", value: "1" },
				];
				setSelectedOperator({ label: "true", value: "0" });
				setSelectedDataType({ label: "0/1", value: "boolean" });
				setOperatorOptions(operators);
			}
		};
		const handleStepSelectionChange = (selectedOption) => {
			if (filterDisplay) {
				console.log("KAHBJKAHAD", selectedOption);
				setSelectedStep(selectedOption);
			} else {
				console.log("KAHBJKAHAD 2", selectedOption);
				setSelectedStep(selectedOption);
			}
		};

		useEffect(() => {
			if (autoFillingData) {
				console.log("only autofiiling");
				setAutoFillingData(false);
			} else if (selectedStep) {
				setFilterDisplay(false);
				setPersonName([]);
				setFilterOptions("");
				setSelectedProperty("");
				setPropertyOptions("");
				generatePropertiesOptions();
			}
		}, [selectedStep]);
		const operatorChanged = (selected) => {
			setSelectedOperator(selected);
		};

		const filterChanged = (selected) => {
			// console.log("filter changed...");
			// console.log(selected);
			setPersonName(selected);
		};
		const propertyOptionChanged = (selectedOption) => {
			console.log("property option is chaneged!!!", selectedOption);
			setPersonName([]);
			setSelectedProperty(selectedOption);
			// console.log("new selected property is : " + selectedOption.value);
			// const allData = cloneDeep(allFilterDataRef.current);
			setLoading(true);
			const InlineFilterAPI =
				"https://7mbhokze6g.execute-api.us-east-2.amazonaws.com/valueFunnel/value?event=" +
				selectedStep.value +
				"&kit=" +
				kit +
				"&property=" +
				selectedOption.value;
			axios({
				method: "get",
				url: InlineFilterAPI,
				headers: { "Content-Type": "application/json" },
			})
				.then((response) => {
					setLoading(false);
					const datatype = cloneDeep(response.data.result.datatype);
					const filterList = cloneDeep(response.data.result.value);
					const allFilters = [];
					// console.log(response.data);
					// console.log("filter list");
					// console.log(filterList);
					// console.log("data type: ");
					// console.log(datatype);
					filterList.forEach((data) => {
						allFilters.push({ label: data, value: data });
					});
					setFilterOptions(allFilters);

					if (datatype === "String") {
						setSelectedDataType({ value: "string", label: "Aa" });
						dataTypeOptionChanged({ value: "string", label: "Aa" });
					} else if (datatype === "Number") {
						setSelectedDataType({ value: "number", label: "#" });
						dataTypeOptionChanged({ value: "number", label: "#" });
					}
					if (datatype === "Boolean") {
						setSelectedDataType({ value: "boolean", label: "0/1" });
						dataTypeOptionChanged({ value: "boolean", label: "0/1" });
					}
				})
				.catch((err) => {
					setLoading(false);
					console.log("error in getting inline filters");
					console.log(err);
				});
		};

		const generatePropertiesOptions = () => {
			setPropertyPlaceholder("loading...");
			setOperatorOptions("");
			setFilterOptions("");
			setPersonName([]);
			setOperatorOptions("");
			const InlineFilterAPI =
				"https://7mbhokze6g.execute-api.us-east-2.amazonaws.com/PropertyFunnel?event=" +
				selectedStep.value +
				"&kit=" +
				kit;
			axios({
				method: "get",
				url: InlineFilterAPI,
				headers: { "Content-Type": "application/json" },
			})
				.then((response) => {
					// setLoading(false);
					setPropertyPlaceholder("properties");
					// console.log("inline filter response: ");
					// console.log(response.data.result);
					const dataArray = cloneDeep(response.data.result.properties);
					// allFilterDataRef.current = dataArray;
					const allProperties = [];
					console.log(dataArray);
					dataArray.forEach((data) => {
						allProperties.push({ label: data, value: data });
					});
					// console.log("all properties: ");
					// console.log(allProperties);
					setPropertyOptions(allProperties);
				})
				.catch((err) => {
					setLoading(false);
					console.log("error in getting inline filters");
					console.log(err);
				});
		};
		const filterIconClicked = () => {
			if (filterDisplay === true) {
				console.log("filter is already true");
				// getSelectedData();
				setFilterDisplay(!filterDisplay);
			} else {
				if (selectedStep.label !== "All Event") {
					setFilterDisplay(!filterDisplay);
					generatePropertiesOptions();
				}
				// console.log("selected step: " + selectedStep.value + " , kit: " + kit);

				// setLoading(true)
			}
		};
		const multiValueContainer = ({ selectProps, data }) => {
			const label = data.label;
			const allSelected = selectProps.value;
			const allLabels = [];
			const index = allSelected.findIndex((selected) => selected.label === label);
			const isLastSelected = index === allSelected.length - 1;
			const labelSuffix = isLastSelected ? ` (${allSelected.length})` : ", ";
			const val = `${label}${labelSuffix}`;
			return val;
		};
		return (
			<>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						marginTop: "8px",
						// marginBottom: "8px",
						flexWrap: "wrap",
						marginRight: "8px",
						// border: "1px solid red",
					}}
				>
					<Badge bg="secondary" className="badge-class">
						{index + 1}
					</Badge>
					<div
						style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
					>
						{/* steps selector dropdown */}
						<Select
							id={"steps-selector-" + id}
							closeMenuOnSelect={false}
							components={{
								DropdownIndicator: () => null,
								IndicatorSeparator: () => null,
							}}
							closeMenuOnSelect
							placeholder="select step"
							styles={stepStyle}
							value={selectedStep}
							options={stepsOptions}
							onChange={handleStepSelectionChange}
							menuPlacement="auto"
							// className={styling.selectOne}
							// menuPosition="fixed"
						/>
						{/* <FilterListIcon onClick={() => setFilterDisplay(!filterDisplay)}/> */}
						<div onClick={() => filterIconClicked()} className={styling.filterButton}>
							<img
								id="initial"
								className={styling.FilterIconGrey}
								src={FilterIconGrey}
								alt="grey"
							/>
							<img
								id="onhover"
								className={styling.FilterIconColor}
								src={FilterIconColor}
								alt="color"
							/>
						</div>
					</div>

					{filterDisplay ? (
						<>
							<div style={{ display: "flex", width: "190px" }}>
								{/* property selector dropdown */}
								<Select
									closeMenuOnSelect={false}
									components={{
										DropdownIndicator: () => null,
										IndicatorSeparator: () => null,
									}}
									closeMenuOnSelect
									placeholder={propertyPlaceholder}
									styles={propertyStyle}
									value={selectedProperty}
									options={propertyOptions}
									onChange={propertyOptionChanged}
									className="filter-selection-select"
								/>

								{/* data type selector dropdown */}
								<Select
									closeMenuOnSelect={false}
									components={{
										DropdownIndicator: () => null,
										IndicatorSeparator: () => null,
									}}
									closeMenuOnSelect
									placeholder="Aa"
									value={selectedDataType}
									menuPlacement="auto"
									menuPosition="fixed"
									onChange={dataTypeOptionChanged}
									styles={dataTypeStyles}
									options={dataTypeOptions}
									className="filter-selection-select"
								/>
							</div>

							{/* operator dropdown ex, = / AND-OR */}
							<Select
								closeMenuOnSelect={false}
								components={{
									DropdownIndicator: () => null,
									IndicatorSeparator: () => null,
								}}
								closeMenuOnSelect
								menuPlacement="auto"
								menuPosition="fixed"
								defaultValue={defaultDropdownValue}
								styles={operatorTypeSelect}
								options={operatorOptions}
								value={selectedOperator}
								onChange={operatorChanged}
								className="filter-selection-select"
								// placeholder={propertyPlaceholder}
							/>

							{/* filter checkbox */}
							{/* <MUISelect
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={personName}
            displayEmpty
            onChange={handleChange}
            input={<OutlinedInput id="filter-multiple" label="" style={{color:"grey", marginLeft:"10px", height:"41px", fontSize:"12px"}} />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={{ classes: { paper: classes.select } }}
            className={classes.root}
          >
            {filterOptions.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={personName.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </MUISelect> */}

							{selectedOperator != null && selectedOperator.value.includes("is") ? (
								<Select
									isMulti
									components={{
										DropdownIndicator: () => null,
										IndicatorSeparator: () => null,
										MultiValueContainer: multiValueContainer,
									}}
									closeMenuOnSelect={false}
									hideSelectedOptions={false}
									styles={filterStyle}
									options={filterOptions}
									isSearchable={true}
									value={personName}
									onChange={filterChanged}
									className="filter-selection-select"
								/>
							) : (
								<input
									type="text"
									name="select-filter"
									id={"select-filter-contains-" + id}
									placeholder={selectedOperator.value}
									style={{
										width: "145px",
										borderRadius: "10px",
										padding: "12px",
										color: "#4c4c4c",
										fontSize: "12px",
										height: "40px",
										border: "1px solid #D3D3D3",
										marginLeft: "10px",
										// border: "2px solid red",

										// width: isFocused ? "350px" : "145px",
									}}
								/>
							)}
						</>
					) : (
						<>
							<RemoveCircleOutlineRoundedIcon
								onClick={() => remove(id)}
								style={{
									marginLeft: "1vw",
									fontWeight: "bold",
									color: "rgb(203 142 85)",
									cursor: "pointer",
								}}
							/>
						</>
					)}
				</div>
				{/* <SyncLoader
					color="#e09e53"
					id={"panel-" + id + "-loader"}
					loading={loading}
					css={override}
					size={15}
				/> */}
			</>
		);
	}
);

export default DropdownSelection;
