
import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert, Container } from "react-bootstrap"
import { useAuth } from "../../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import './Login.scss'
import Header from "../Header/Header"

export default function Login() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const { login } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setError("")
      setLoading(true)
      await login(emailRef.current.value, passwordRef.current.value)
      history.push("/")
    } catch {
      setError("Failed to log in")
    }

    setLoading(false)
  }

  return (
<>

<Header/>
    <Container className="d-flex align-items-center justify-content-center"
            style={{ minHeight: "60vh" }}
    >
        
        <div className="w-100" style={{ maxWidth: "450px" }}>
            <Card style={{borderRadius:"8%"}}>
                <Card.Body>
                <h2 className="text-center mb-4">Log In</h2>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Group style={{marginTop: "3vh"}} id="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" ref={emailRef} required />
                    </Form.Group>
                    <Form.Group style={{marginTop: "3vh"}} id="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" ref={passwordRef} required />
                    </Form.Group>
                    <Button  style={{marginTop: "3vh", backgroundColor:"#72C8CC", border:"0px solid", 
                                borderRadius:"12px", fontFamily:"Montserrat", fontSize:"1.15rem", fontWeight:"600"}} disabled={loading} className="w-100" type="submit">
                    Log In
                    </Button>
                </Form>
                <div className="w-100 text-center" style={{marginTop: "3vh"}}>
                    <Link to="/login" style={{color:"#5e5e5e"}}>Forgot Password?</Link>
                </div>
                </Card.Body>
            </Card>
            <div className="w-100 text-center mt-2" style={{marginTop: "3vh"}}>
                Need an account? <Link to="/login" style={{color:"#5e5e5e"}}>Sign Up</Link>
            </div>
        </div>

    </Container>
</>
  )
}