import "./ChartPanel2.scss";
import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Line, Bar } from "react-chartjs-2";
import GearIcon from "../../../assets/gear-icon.svg";

const ChartPanel2 = forwardRef(
  ({ id, chartType, data, options, onConfig }, ref) => {
    const chartPanelId = "chart-panel-" + id;
    const [chart, setChart] = useState();
    const allDataPointRef = useRef();
    const chartRef = useRef();
    useImperativeHandle(ref, () => ({
      updateChart(data) {
        if (chartType === "bar") {
          //   alert("bar chart upate called!!!");
          // console.log(data["datasets"][0].data);
          let max = Math.ceil(Math.max(...data["datasets"][0].data) / 10) * 10;
          max = Math.min(max, 100);

          console.log("max: " + max);
          const options = {
            maintainAspectRatio: false,
            scales: {
              xAxes: {
                gridLines: {
                  drawOnChartArea: false,
                },
              },

              yAxes: {
                min: 0,
                max: max,
              },
            },
          };
          chartRef.current.data = data;
          chartRef.current.options = options;
          chartRef.current.update();
        } else {
          chartRef.current.data = data;
          if (chartType.includes("line")) {
            // alert("use effect line");
            // data.labels.pop();
            // data.labels.pop();
            // alert(chartType)
            let chartPointInterval = " from previous day";
            if(chartType.includes("insight_line")) {
              const view = chartType.split("-")[1];
              if(view.includes("month")) {
                chartPointInterval = " from last month";
              } else if(view.includes("year")){
                chartPointInterval = " from last year";
              }
            }
            //   alert("chartype - ", chartType.split("-")[1])
            const option = {
              maintainAspectRatio: false,
              elements: {
                point: {
                  radius: 1,
                },
              },
    
              plugins: {
                
                tooltip: {
                  enabled: false,
                  mode: "index",
                  intersect: false,
                  
                  callbacks:  {
                    
                    label: function (body) {
                      // console.log("body- ", body);
                      const currIndex = getCurrentIndex(body);
                      // console.log("curr index - ", currIndex);
                      data = body.dataset.data
                      if(currIndex > 0) {
                        const prevData = data[currIndex - 1].y
                        const currData = data[currIndex].y
                        let change = (currData - prevData) / prevData * 100;
                        const trend = change > 0 ? "Increase" : "Decrease"
                        change = Math.abs(change.toFixed(2));
                        // console.log("current - ", data[currIndex].y);
                        // console.log("prev - ", data[currIndex - 1].y);
                        return body.dataset.label + " - " + body.formattedValue + "||" + change + "% " + trend + "||" + chartPointInterval +""
                      } else {
                        return body.dataset.label + " - " + body.formattedValue;
                      }
                      
                    },
                    
                  },
    
                  external: function(context) {
                      // Tooltip Element
                      let tooltipEl = document.getElementById('chartjs-tooltip');
    
                      // Create element on first render
                      if (!tooltipEl) {
                          tooltipEl = document.createElement('div');
                          tooltipEl.id = 'chartjs-tooltip';
    
                          // document.getElementsByTagName("thead").style.background = "red"
                          tooltipEl.innerHTML = '<table></table>';
                          document.body.appendChild(tooltipEl);
                      }
    
                      // Hide if no tooltip
                      const tooltipModel = context.tooltip;
                      if (tooltipModel.opacity === 0) {
                          tooltipEl.style.opacity = 0;
                          return;
                      }
                      tooltipEl.style.border = "2px solid #dbdbdb"
                      tooltipEl.style.padding = "4px 5px 5px 8px"
                      tooltipEl.style.background = "white"
                      tooltipEl.style.borderRadius = "10px"
                      tooltipEl.style.borderLeft = "6px solid #BB6BD9"
                      tooltipEl.style.minWidth = "200px"
                      tooltipEl.style.boxShadow ="rgba(0, 0, 0, 0.35) 0px 5px 15px";
                      
    
                      // Set caret Position
                      tooltipEl.classList.remove('above', 'below', 'no-transform');
                      if (tooltipModel.yAlign) {
                          tooltipEl.classList.add(tooltipModel.yAlign);
                      } else {
                          tooltipEl.classList.add('no-transform');
                      }
    
                      function getBody(bodyItem) {
                          return bodyItem.lines;
                      }
    
                      // Set Text
                      if (tooltipModel.body) {
                          const titleLines = tooltipModel.title || [];
                          const bodyLines = tooltipModel.body.map(getBody);
                          // console.log("body line - ", bodyLines);
                          let innerHtml = '<thead>';
    
                          titleLines.forEach(function(title) {
                            let style = 'background:white;font-family: Montserrat, sans-serif;color:#4f4d4d;font-weight:700;font-size:14px';
                              innerHtml += '<tr><th style="' + style + '" >' + title + '</th></tr>';
                          });
                          innerHtml += '</thead><hr style="margin-top:6px;margin-bottom:3px"><tbody>';
    
                          bodyLines.forEach(function(body, i) {
                            if(body.includes("||")) {
                              let style = 'background:white;font-family: Montserrat, sans-serif;color:#666464;font-weight:500;font-size:12px;margin:1px;';
                              const span = '<span style="' + style + '">';
                              innerHtml += '<tr><td>' + span + body + '</span> </td></tr>';
                            } else {
                              const labelLine = body[0].split("||")[0];
                              const changeLine = body[0].split("||")[1];
                              const changeDirection = changeLine?.split(" ")[1];
                              const changeValue = changeLine?.split(" ")[0];
                              const timeIntervalLine = body[0].split("||")[2];
                              let style = 'background:white;font-family: Montserrat, sans-serif;color:#666464;font-weight:500;font-size:12px;margin:1px;';
                              const span = '<span style="' + style + '">';
                              const positiveSpan = '<span style="color:green;font-size:11px;font-weight:500;">';
                              const negativeSpan = '<span style="color:red;font-size:11px;font-weight:500">';
                              const positiveValueSpan = '<span style="font-weight:600;background-color:rgba(177, 242, 176, 0.4);padding:4px;width:16px;">';
                              const negativeValueSpan = '<span style="font-weight:600;background-color:rgba(242, 176, 176, 0.4);padding:4px;width:16px;">';
                              innerHtml += '<tr><td>' + span + "<b>" + labelLine + "</b>" + '</span></td></tr>';
                              if(changeValue)
                              innerHtml += '<tr><td>' + (changeDirection.includes("Increase") ? positiveSpan + positiveValueSpan + " + " : negativeSpan + negativeValueSpan + " - " ) 
                                            + changeValue + "</span>" + "</span>" + span + " " 
                                            + timeIntervalLine + '</span></td></tr>';
                            }
                              
                              
                          });
                          innerHtml += '</tbody>';
    
                          let tableRoot = tooltipEl.querySelector('table');
                          tableRoot.innerHTML = innerHtml;
                      }
    
                      const position = this._chart.canvas.getBoundingClientRect();
                      // const bodyFont = this._chart.toFont(tooltipModel.options.bodyFont);
                      // Display, position, and set styles for font
                      tooltipEl.style.opacity = 1;
                      tooltipEl.style.position = 'absolute';
                      tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                      tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                      // tooltipEl.style.font = bodyFont.string;
                      tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
                      tooltipEl.style.pointerEvents = 'none';
                      
                  }
                },
                hover: {
                  mode: "nearest",
                  intersect: true,
                },
              },
    
              scales: {
                yAxes: {
                  ticks: {
                    callback: function (label, index, labels) {
                      return chartType === "line" ? label + "%" : label;
                    },
                  },
                },
                x: {
                  offset: true,
                },
              },
            };
            chartRef.current.options = option
          } 
          chartRef.current.update();
        }
      },
      updateChartAndType(data, type) {
        // console.log("chartpanel 2 ... updating type");
        chartType = type;
        if (type === "line") {
          // alert("in chart panel2 line chart case");
          chartRef.current.data = data;
          chartRef.current.update();
          const chartComp = (
            <Line
              ref={chartRef}
              data={data}
              className="chart-props"
              height="100%"
              width="100%"
              options={{
                maintainAspectRatio: false,
                elements: {
                  point: {
                    radius: 1,
                  },
                },
                plugins: {
                  tooltip: {
                    mode: "index",
                    intersect: false,
                  },
                  hover: {
                    mode: "nearest",
                    intersect: true,
                  },
                },
                scales: {
                  yAxes: {
                    ticks: {
                      callback: function (label, index, labels) {
                        return label + "%";
                      },
                    },
                  },
                  x: {
                    offset: true,
                  },
                },
              }}
            />
          );
          setChart(chartComp);
        } else {
          // alert("in chart panel2 bar chart case");
          chartRef.current.data = data;
          chartRef.current.update();
          let max = Math.ceil(Math.max(...data["datasets"][0].data) / 10) * 10;
          max = Math.min(max, 100);
          // alert("first time bar chart  called!!!");
          // console.log("max: " + max);
          const option = {
            maintainAspectRatio: false,
            scales: {
              yAxes: {
                min: 0,
                max: max,
                ticks: {
                  callback: function (label, index, labels) {
                    return label + "%";
                  },
                },
              },
            },
          };
          const chartComp = (
            <Bar
              ref={chartRef}
              data={data}
              id={"bar-chart-" + id}
              className="chart-props"
              height="100%"
              width="100%"
              options={option}
            />
          );
          setChart(chartComp);
        }
      },
    }));

    const getCurrentIndex = (data) => {
      const currentPoint = data.label;
      // console.log("current point is - ", currentPoint);
      const allData = data.dataset.data;
      // console.log("all data - " , allData);
      let index = 0;
      for (index = 0; index < allData.length; index++) {
        if(currentPoint === allData[index].x) break;      
      }
      return index;
    }
    useEffect(() => {
      // alert("hey - ----")
      if (chartType.includes("line")) {
        // alert("use effect line");
        // data.labels.pop();
        // data.labels.pop();
        // alert(chartType)
        // alert("use effect!!")
        let chartPointInterval = " from previous day";
        if(chartType.includes("insight_line")) {
          const view = chartType.split("-")[1];
          if(view === "monthly") {
            chartPointInterval = " from last month";
          } else if(view === "yearly"){
            chartPointInterval = " from last year";
          }
        }
        //   alert("chartype - ", chartType.split("-")[1])
        const option = {
          maintainAspectRatio: false,
          elements: {
            point: {
              radius: 1,
            },
          },

          plugins: {
            
            tooltip: {
              enabled: false,
              mode: "index",
              intersect: false,
              height:"50px",
              callbacks:  {
                
                label: function (body) {
                  if(chartType.includes("insight_line")) {
                    // console.log("body- ", body);
                    const currIndex = getCurrentIndex(body);
                    // console.log("curr index - ", currIndex);
                    data = body.dataset.data
                    if(currIndex > 0) {
                      const prevData = data[currIndex - 1].y
                      const currData = data[currIndex].y
                      let change = (currData - prevData) / prevData * 100;
                      const trend = change > 0 ? "Increase" : "Decrease"
                      change = Math.abs(change.toFixed(2));
                      // console.log("current - ", data[currIndex].y);
                      // console.log("prev - ", data[currIndex - 1].y);
                      return body.dataset.label + " - " + body.formattedValue + "||" + change + "% " + trend + "||" + chartPointInterval +""
                    } else {
                      return body.dataset.label + " - " + body.formattedValue;
                    }
                  } else {
                    return body.dataset.label + " - " + body.formattedValue;
                  }
                },
                
              },

              external: function(context) {
                  // Tooltip Element
                  let tooltipEl = document.getElementById('chartjs-tooltip');

                  // Create element on first render
                  if (!tooltipEl) {
                      tooltipEl = document.createElement('div');
                      tooltipEl.id = 'chartjs-tooltip';

                      // document.getElementsByTagName("thead").style.background = "red"
                      tooltipEl.innerHTML = '<table></table>';
                      document.body.appendChild(tooltipEl);
                  }

                  // Hide if no tooltip
                  const tooltipModel = context.tooltip;
                  if (tooltipModel.opacity === 0) {
                      tooltipEl.style.opacity = 0;
                      return;
                  }
                  tooltipEl.style.border = "2px solid #dbdbdb"
                  tooltipEl.style.padding = "4px 5px 5px 8px"
                  tooltipEl.style.background = "white"
                  tooltipEl.style.borderRadius = "10px"
                  tooltipEl.style.borderLeft = "6px solid #BB6BD9"
                  tooltipEl.style.minWidth = "200px"
                  tooltipEl.style.maxHeight = "360px"
                  tooltipEl.style.overflow = "hidden"
                  tooltipEl.style.boxShadow ="rgba(0, 0, 0, 0.35) 0px 5px 15px";
                  

                  // Set caret Position
                  tooltipEl.classList.remove('above', 'below', 'no-transform');
                  if (tooltipModel.yAlign) {
                      tooltipEl.classList.add(tooltipModel.yAlign);
                  } else {
                      tooltipEl.classList.add('no-transform');
                  }

                  function getBody(bodyItem) {
                      return bodyItem.lines;
                  }

                  // Set Text
                  if(chartType.includes("insight_line")) {
                  if (tooltipModel.body) {
                      const titleLines = tooltipModel.title || [];
                      const bodyLines = tooltipModel.body.map(getBody);
                      // console.log("body line - ", bodyLines);
                      let innerHtml = '<thead>';

                      titleLines.forEach(function(title) {
                        let style = 'background:white;font-family: Montserrat, sans-serif;color:#4f4d4d;font-weight:700;font-size:14px';
                          innerHtml += '<tr><th style="' + style + '" >' + title + '</th></tr>';
                      });
                      innerHtml += '</thead><hr style="margin-top:6px;margin-bottom:3px"><tbody>';

                      bodyLines.forEach(function(body, i) {
                        if(body.includes("||")) {
                          let style = 'background:white;font-family: Montserrat, sans-serif;color:#666464;font-weight:500;font-size:12px;margin:1px;';
                          const span = '<span style="' + style + '">';
                          innerHtml += '<tr><td>' + span + body + '</span> </td></tr>';
                        } else {
                          const labelLine = body[0].split("||")[0];
                          const changeLine = body[0].split("||")[1];
                          const changeDirection = changeLine?.split(" ")[1];
                          const changeValue = changeLine?.split(" ")[0];
                          const timeIntervalLine = body[0].split("||")[2];
                          let style = 'background:white;font-family: Montserrat, sans-serif;color:#666464;font-weight:500;font-size:12px;margin:1px;';
                          const span = '<span style="' + style + '">';
                          const positiveSpan = '<span style="color:green;font-size:11px;font-weight:500;">';
                          const negativeSpan = '<span style="color:red;font-size:11px;font-weight:500">';
                          const positiveValueSpan = '<span style="font-weight:600;background-color:rgba(177, 242, 176, 0.4);padding:4px;width:16px;">';
                          const negativeValueSpan = '<span style="font-weight:600;background-color:rgba(242, 176, 176, 0.4);padding:4px;width:16px;">';
                          innerHtml += '<tr><td>' + span + "<b>" + labelLine + "</b>" + '</span></td></tr>';
                          if(changeValue)
                          innerHtml += '<tr><td>' + (changeDirection.includes("Increase") ? positiveSpan + positiveValueSpan + " + " : negativeSpan + negativeValueSpan + " - " ) 
                                        + changeValue + "</span>" + "</span>" + span + " " 
                                        + timeIntervalLine + '</span></td></tr>';
                        }
                          
                          
                      });
                      innerHtml += '</tbody>';

                      let tableRoot = tooltipEl.querySelector('table');
                      tableRoot.innerHTML = innerHtml;
                  }}

                  const position = this._chart.canvas.getBoundingClientRect();
                  // const bodyFont = this._chart.toFont(tooltipModel.options.bodyFont);
                  // Display, position, and set styles for font
                  tooltipEl.style.opacity = 1;
                  tooltipEl.style.position = 'absolute';
                  tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                  tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                  // tooltipEl.style.font = bodyFont.string;
                  tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
                  tooltipEl.style.pointerEvents = 'none';
                  
              }
            },
            hover: {
              mode: "nearest",
              intersect: true,
            },
          },

          scales: {
            yAxes: {
              ticks: {
                callback: function (label, index, labels) {
                  return chartType === "line" ? label + "%" : label;
                },
              },
            },
            x: {
              offset: true,
            },
            
          },
        };
        const chartComp = (
          <Line
            ref={chartRef}
            data={data}
            className="chart-props"
            height="100%"
            width="100%"
            options={option}
          />
        );
        setChart(chartComp);
      } else {
        let max = Math.ceil(Math.max(...data["datasets"][0].data) / 10) * 10;
        max = Math.min(max, 100);
        // alert("first time bar chart  called!!!");
        // console.log("max: " + max);
        let plugins = {};
        if (chartType === "barFunnel") {
          plugins = {
            tooltip: {
              callbacks: {
                title: function (title) {
                  return "Conversion " + title[0].formattedValue + "%";
                },
                label: function (body) {
                  return body.label;
                },
              },
              backgroundColor: "#142165",
              titleFontSize: 16,
              titleFontColor: "#FFFFFF",
              bodyFontColor: "#FFFFFF",
              bodyFontSize: 16,
              displayColors: false,
            },
          };
        }
        const option = {
          maintainAspectRatio: false,
          plugins: plugins,
          scales: {
            yAxes: {
              ticks: {
                callback: function (label, index, labels) {
                  return label + "%";
                },
              },
            },
          },
        };
        const chartComp = (
          <Bar
            ref={chartRef}
            data={data}
            id={"bar-chart-" + id}
            className="chart-props"
            height="100%"
            width="100%"
            options={option}
          />
        );
        setChart(chartComp);
      }
    }, []);

    return (
      <>
        <div id={chartPanelId} className="chart-panel">
          {chart}
        </div>
      </>
    );
  }
);

export default ChartPanel2;
