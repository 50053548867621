import React, { useRef, useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "./leftPanel.scss";
import Select, { components } from "react-select";
import EventQuerySelector from "./QuerySelectorComponents/EventQuerySelector/EventQuerySelector";
import FilterQuerySelector from "./QuerySelectorComponents/FilterQuerySelector/FilterQuerySelector";
import BreakdownSelector from "./QuerySelectorComponents/BreakdownQuerySelector/BreakdownQuerySelector";
import axios from "axios";
import Modal from "react-modal";
const LeftPanel = ({ submitQueryData, savedEventData }) => {
  const kitStyle = {
    control: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: "white",
      width: "18rem",
      border: "0.5px solid rgba(0, 0, 0, 0.3)",
      borderRadius: "10px",
      height: "30px",
      overflow: "wrap ",
      fontSize: "12px",
      marginBottom: "32px",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 10,
      overflow: "wrap",
      fontSize: "13px",
      width: "16rem",
      borderRadius: "20px",
      padding: "10px",
    }),

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#bb6bd9" : "white",
        color: isSelected ? "white" : "black",
        borderRadius: "10px",
        marginBottom: "5px",
        padding: "8px",
        fontSize: "12px",
        fontWeight: "500",
      };
    },
  };

  const eventQuerySelectorRef = useRef();
  const filterQuerySelectorRef = useRef();
  const breakdownSelectorRef = useRef();

  const [breakdownOptions, setBreakdownOptions] = useState([
    { label: "- No Breakdown -", value: "" },
    { label: "App Version", value: "where_app" },
    { label: "Platform", value: "where_platform" },
    { label: "Grade", value: "where_grade" },
  ]);

  const [selectedDistribution, setSelectedDistribution] = useState({
    label: "Active Days",
    value: "Active Days",
  });
  const [selectedBreakdown, setSelectedBreakdown] = useState({
    label: "- No Breakdown -",
    value: "",
  });
  const [gameKitsList, setGameKitsList] = useState([]);
  const [platformList, setPlatformList] = useState([]);
  const [kitList, setKitList] = useState([]);
  const [gameList, setGameList] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState();
  const [selectedKit, setSelectedKit] = useState({
    label: "Tacto",
    value: "Tacto",
  });

  const [selectedUserType, setSelectedUserType] = useState({
    label: "All Users",
    value: "All Users",
  });

  const [selectedEmail, setSelectedEmail] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedGame, setSelectedGame] = useState();

  const getKitsData = async () => {
    const kitSelectionURL =
      "https://2b03ggq1we.execute-api.us-east-2.amazonaws.com/getKits";
    const response = await fetch(kitSelectionURL);
    const responseJSON = await response.json();
    setGameKitsList(responseJSON.from);
  };
  const selectedKitRef = useRef(selectedKit);

  const handleKitChange = (e) => {
    // setSelectedKit(e);
    // selectedKitRef.current = e;
    // console.log("loading breakdown option - ");
    // loadBreakdownOptions();
  };

  const customEnailPopupStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "30px",
      height: "220px",
      width: "400px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      border: "3px solid #F2F2F2",
    },
  };

  const handleInsightSubmitSelection = () => {
    const finalData = {
      main: selectedDistribution.value.toLowerCase().split(" ").join("_"),
      filter1: selectedUserType.value.toLowerCase().split(" ").join("_"),
      platform: selectedPlatform.value,
      kit: selectedKit.value,
      from_val: selectedGame.value,
      grouper: selectedBreakdown.value,
      email_id: selectedEmail,
    };

    // console.log("final data - ", finalData);
    submitQueryData(finalData);
  };

  const xAxisOptions = [
    { label: "Active Days", value: "Active Days" },
    { label: "Levels Completed", value: "Levels Completed" },
    { label: "Sessions Played", value: "Sessions Played" },
    { label: "Time Spent", value: "Time Spent" },
    { label: "Calendar Days", value: "Calendar Days" },
  ];
  const [userType, setUserType] = useState([
    { label: "All Users", value: "All Users" },
    // { label: "Specific Email", value: "Specific Email" },
    { label: "One Time Users", value: "One Time Users" },
    { label: "Non One Time Users", value: "Non One Time Users" },
    { label: "Power User", value: "Power User" },
    { label: "Specific Email", value: "Specific Email" },
  ]);
  useEffect(() => {
    if (savedEventData) {
      const savedKit = savedEventData["0"]["kit"];
      // setSelectedKit({ label: savedKit.split("_").join(" "), value: savedKit });
      handleKitChange({
        label: savedKit.split("_").join(" "),
        value: savedKit,
      });
    }
  }, [savedEventData]);

  useEffect(() => {
    getKitsData();
  }, []);

  useEffect(() => {
    console.log("game kits - ", gameKitsList);
    if (Object.keys(gameKitsList).length > 0) {
      setPlatformList([
        { label: "Tacto", value: "Tacto" },
        { label: "Plugo", value: "Plugo" },
        { label: "Orboot", value: "Orboot" },
      ]);
      setSelectedPlatform({ label: "Tacto", value: "Tacto" });
    }
  }, [gameKitsList]);

  useEffect(() => {
    if (selectedPlatform && Object.keys(gameKitsList).length > 0) {
      let kitListArr = Object.keys(gameKitsList[selectedPlatform.value]);
      kitListArr = kitListArr.map((kit) => {
        return { label: kit.split("_").join(" "), value: kit };
      });
      console.log("kit list arr - ", kitListArr);
      setKitList(kitListArr);
      setSelectedKit(kitListArr[0]);
    }
  }, [selectedPlatform]);

  useEffect(() => {
    if (selectedPlatform && Object.keys(gameKitsList).length > 0) {
      let gameListArr = gameKitsList[selectedPlatform.value][selectedKit.value];
      console.log("gmae list arr - ", gameListArr);
      gameListArr = gameListArr.map((game) => {
        return { label: game.split("_").join(" "), value: game };
      });

      setGameList(gameListArr);
      setSelectedGame(gameListArr[0]);
    }
  }, [selectedKit]);

  const handleSelectedUserTypeChange = (e) => {
    if (e.value === "Specific Email") {
      setIsOpen(true);
    } else {
      setSelectedEmail("");
      setSelectedUserType({ label: e.value, value: e.value });
    }
  };
  return (
    <div>
      <Row
        style={{
          borderRight: "1px solid #DFDFDF",
          flexDirection: "column",
          width: "95%",
          height: "88vh",
        }}>
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minHeight: "20vh",
            maxHeight: "82vh",
            paddingBottom: "20px",
            overflowX: "hidden",
            overflowY: "scroll",
          }}>
          <span
            style={{
              width: "18rem",
              fontWeight: 700,
              marginBottom: "4px",
              marginTop: "10px",
            }}>
            Platform
          </span>
          <Select
            id={"platform-selector"}
            components={{
              IndicatorSeparator: () => null,
            }}
            closeMenuOnSelect
            placeholder="Select here"
            styles={kitStyle}
            value={selectedPlatform}
            options={platformList}
            onChange={(e) => {
              console.log("platform changed!!");
              setSelectedPlatform(e);
            }}
            menuPlacement="auto"
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            isSearchable={false}
          />

          <span
            style={{
              width: "18rem",
              fontWeight: 700,
              marginBottom: "4px",
              marginTop: "10px",
            }}>
            Kit
          </span>
          <Select
            id={"kit-selector"}
            components={{
              IndicatorSeparator: () => null,
            }}
            closeMenuOnSelect
            placeholder="Select here"
            styles={kitStyle}
            value={selectedKit}
            options={kitList}
            onChange={(e) => {
              console.log("kit changed!!");
              setSelectedKit(e);
            }}
            menuPlacement="auto"
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            isSearchable={false}
          />

          <span
            style={{
              width: "18rem",
              fontWeight: 700,
              marginBottom: "4px",
              marginTop: "10px",
            }}>
            Game
          </span>
          <Select
            id={"game-selector"}
            components={{
              IndicatorSeparator: () => null,
            }}
            closeMenuOnSelect
            placeholder="Select here"
            styles={kitStyle}
            value={selectedGame}
            options={gameList}
            onChange={(e) => {
              setSelectedGame(e);
            }}
            menuPlacement="auto"
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            isSearchable={false}
          />

          <span
            style={{
              width: "18rem",
              fontWeight: 700,
              marginBottom: "4px",
              marginTop: "10px",
            }}>
            User Type
          </span>
          <Select
            id={"user-selector"}
            components={{
              IndicatorSeparator: () => null,
            }}
            closeMenuOnSelect
            placeholder="Select here"
            styles={kitStyle}
            value={selectedUserType}
            options={userType}
            onChange={(e) => {
              handleSelectedUserTypeChange(e);
            }}
            menuPlacement="auto"
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            isSearchable={false}
          />

          <span
            style={{
              width: "18rem",
              fontWeight: 700,
              marginBottom: "4px",
              marginTop: "10px",
            }}>
            Distribution
          </span>
          <Select
            id={"distribution-selector"}
            components={{
              IndicatorSeparator: () => null,
            }}
            closeMenuOnSelect
            placeholder="Select here"
            styles={kitStyle}
            value={selectedDistribution}
            options={xAxisOptions}
            onChange={(e) => {
              setSelectedDistribution(e);
            }}
            menuPlacement="auto"
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            isSearchable={false}
          />

          <span
            style={{
              width: "18rem",
              fontWeight: 700,
              marginBottom: "4px",
              marginTop: "10px",
            }}>
            Breakdown
          </span>

          <Select
            id={"breakdown-selector"}
            components={{
              // DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            closeMenuOnSelect
            styles={kitStyle}
            value={selectedBreakdown}
            options={breakdownOptions}
            onChange={(e) => setSelectedBreakdown(e)}
            menuPlacement="auto"
            isSearchable={false}
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
          />
        </Col>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "30px",
            marginTop: "20px",
          }}>
          <button
            className={"query-selector-go-btn"}
            onClick={handleInsightSubmitSelection}>
            Go
          </button>
        </div>
      </Row>
      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        // onRequestClose={closeModal}
        style={customEnailPopupStyles}
        contentLabel="Example Modal">
        <span
          className="label label-default"
          style={{ color: "black", fontFamily: "Montserrat" }}>
          Specific email id
        </span>
        <br />
        <a
          href="#"
          style={{
            position: "absolute",
            right: "0",
            top: "0",
            margin: "14px",
            textDecoration: "none",
            color: "black",
          }}
          onClick={() => {
            setIsOpen(false);
          }}>
          X
        </a>
        <input
          type="text"
          className="form-control"
          name="user_email"
          defaultValue={selectedEmail}
          id={"selection-input-user-email"}
          placeholder="email address"
        />
        <br /> <br />
        <button
          className="btn "
          style={{
            alignSelf: "center",
            width: "35%",
            backgroundColor: "#72C8CC",
            color: "white",
            fontFamily: "Montserrat",
          }}
          onClick={() => {
            setSelectedEmail(
              document.getElementById("selection-input-user-email").value
            );
            setSelectedUserType({
              label:
                document.getElementById("selection-input-user-email").value ===
                ""
                  ? "Specific Email"
                  : "Specific Email [" +
                    document.getElementById("selection-input-user-email")
                      .value +
                    "] ",
              value: "Specific Email",
            });
            console.log(
              document.getElementById("selection-input-user-email").value
            );
            setIsOpen(false);
          }}>
          Apply
        </button>
      </Modal>
    </div>
  );
};

export default LeftPanel;
