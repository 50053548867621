import React, {  } from "react";
// import "./Sidebar.scss";

import { Link } from "react-router-dom";
import styling from "./Sidebar.module.scss";
import furnaceLogo from "../../assets/FrameNew.svg";
import LiveView from "../../assets/LiveViewGrey.svg";
import TableDictionary from "../../assets/TableDictionaryPurple.svg";
import DashboardPurpleLogo from "../../assets/DashboardGrey.svg";
import shifuLogo from "../../assets/playshifu-logo.png";
import ReportsGreyIcon from "../../assets/reports_grey_icon.svg";
const Sidebar = ({ onFilterApply }) => {
	

	const input = localStorage.getItem("input");


	return (
		
			<div className={styling.Sidebar}>
			<div className={styling.iconRow}>
				<img src={furnaceLogo} style={{ width: "100%" }} alt="shifuLogo" />
			</div>
			<div className={styling.tabSection}>
			<div className={styling.navLink}>
					<div className={styling.logo}>
						<img src={ReportsGreyIcon} style={{ height: "13px", color:"red" }} alt="Reports Page" />
					</div>
					<div className={styling.linkDiv}>
						<Link to="/Reports/Insight" className={styling.sidepanelLink} style={{  }}>
							Reports
						</Link>
					</div>
				</div>
				<div className={styling.navLink}>
					<div className={styling.logo}>
						<img src={DashboardPurpleLogo} style={{ height: "70%", color:"red" }} alt="dashboard" />
					</div>
					<div className={styling.linkDiv}>
						<Link to="/" className={styling.sidepanelLink}>
							Dashboard
						</Link>
					</div>
				</div>
				<div className={styling.navLink}>
					<div className={styling.logo}>
						<img src={TableDictionary} style={{ height: "70%" }} alt="dashboard" />
					</div>
					<div className={styling.linkDiv}>
						<Link to="/tabledictionary" className={styling.sidepanelLinkActive}>
							Table Dictionary
						</Link>
					</div>
				</div>
				<div className={styling.navLink}>
					<div className={styling.logo}>
						<img src={LiveView} style={{ height: "70%" }} alt="dashboard" />
					</div>
					<div className={styling.linkDiv}>
						<Link to="/liveview" className={styling.sidepanelLink}>
							Live View
						</Link>
					</div>
				</div>
			</div>
			<div className={styling.FilterSection}>
				{/* <div className={styling.eachFilter}>Custome Date</div> */}
				
			</div>
			<div className={styling.shifuLogo} style={{ position:"absolute", bottom:"1vh" }}>
				<img style={{ width: "90%", height: "auto" }} src={shifuLogo} alt="shifulogo" />
			</div>
		</div>
	);
};
export default Sidebar;
