import React, { useEffect, useState } from "react";
import Header from "./Header/Header";
import LeftPanel from "./LeftPanel/LeftPanel";
import RightPanel from "./RightPanel/RightPanel";
import { Row, Col } from "react-bootstrap";
import { useLocation } from "react-router";
import queryString from "query-string";
import axios from "axios";
const DistributionFeature = (props) => {
  const [queryData, setQueryData] = useState("");
  const [savedReportData, setSavedReportData] = useState("");
  const [savedEventData, setSavedEventData] = useState("");
  const [savedReportName, setSavedReportName] = useState("");
  const [savedReportId, setSavedReportId] = useState("");
  const [lastUpdatedAt, setLastUpdatedAt] = useState("");
  const [reportId, setReportId] = useState(
    queryString.parse(props.location.search).id
  );
  const handleLeftPanelQueryData = (data) => {
    setQueryData(data);
  };
  useEffect(() => {
    if (reportId) {
      console.log("report id ", reportId);
      // getFurnaceReportData();
    }
  }, [reportId]);

  // const getFurnaceReportData = () => {
  //   const getReportDataAPI =
  //     "https://us-central1-mws-sales-data.cloudfunctions.net/FurnaceGetReport";
  //   const payloadData = {
  //     id: reportId,
  //   };
  //   axios({
  //     method: "POST",
  //     url: getReportDataAPI,
  //     data: payloadData,
  //     headers: { "Content-Type": "application/json" },
  //   })
  //     .then(function (response) {
  //       // setLoading(false);
  //       console.log("response - ", response.data.body);
  //       if (response.data.body) {
  //         const reportData = response.data.body.reportData;
  //         console.log("report data - ", reportData);
  //         setSavedEventData(reportData);
  //         console.log("report name = ", response.data.body.reportName);
  //         setSavedReportName(response.data.body.reportName);
  //         setLastUpdatedAt(response.data.body.updated_at);
  //       }
  //     })
  //     .catch((error) => {
  //       // setLoading(false);
  //       console.log(error);
  //       alert("Error in getting report");
  //     });
  // };
  return (
    <>
      <Row style={{}}>
        <Col lg="12">
          <Header
            savedReportNameFromDB={savedReportName}
            lastUpdatedAt={lastUpdatedAt}
            savedReportIdFromDB={reportId}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="3" style={{}}>
          <LeftPanel
            submitQueryData={handleLeftPanelQueryData}
            savedEventData={savedEventData}
          />
        </Col>
        <Col lg="9">
          <RightPanel queryData={queryData} savedEventData={savedEventData} />
        </Col>
      </Row>
    </>
  );
};
export default DistributionFeature;
