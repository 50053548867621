import React, {
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import { nanoid } from "nanoid";
import { cloneDeep } from "lodash";
import axios from "axios";
import BrekdownDropdown from "./BreakdownDropdown";
const BreakdownSelector = forwardRef(
  ({ kitName, eventName, breakdownOptions, savedEventData }, ref) => {
    const breakdownJSONData = require("../../../../../Dashboard/Insight/BreakdownJSON.json");
    const [dropdownPanels, setDropdownPanels] = useState([]);
    const [savedBreakdownData, setSavedBreakdownData] = useState("");
    useImperativeHandle(ref, () => ({
      getBreakdownData() {
        let breakdownValues = [];
        for (
          let index = 0;
          index < breakdownDropdownRef.current.length;
          index++
        ) {
          if (breakdownDropdownRef.current[index] == null) continue;
          return breakdownDropdownRef.current[index].getBreakdownValue();
        }
        return "";
      },
    }));
    const panelsRef = useRef([...dropdownPanels]);
    const breakdownDropdownRef = useRef([]);
    function handleRemoveSelection(id) {
      console.log("remove clicked - " + id);
      console.log(dropdownPanels);
      const newArr = dropdownPanels.filter((d) => d.id !== id);
      panelsRef.current = cloneDeep(newArr);
      setDropdownPanels(newArr);
    }
    const allAvailableEventsRef = useRef("");
    function addFilterDropdown() {
      if (dropdownPanels.length >= 1) return;
      const uniqueId = nanoid();
      const newArr = [...panelsRef.current, { id: uniqueId, value: "" }];
      setDropdownPanels(newArr);
      panelsRef.current = cloneDeep(newArr);
      console.log("add called, new panels - ", panelsRef.current);
      return uniqueId;
    }
    useEffect(() => {
      if (savedEventData) {
        const breakdownVal = savedEventData["0"]["breakdownval"];
        if (breakdownVal !== "") {
          const uniqueId = nanoid();
          const newArr = [...panelsRef.current, { id: uniqueId, value: "" }];
          setDropdownPanels(newArr);
          panelsRef.current = cloneDeep(newArr);
          const breakdownLabel = getBreakdownLabel(breakdownVal);
          console.log("breakdown label - ", breakdownLabel);
          setSavedBreakdownData({ label: breakdownLabel, value: breakdownVal });
        }
      }
    }, [kitName]);

    const getBreakdownLabel = (value) => {
      if (!kitName) return "";
      let labelName = "";
      // const breakdownOptionArr = [{ key: "Breakdown", value: "" }];
      console.log("kit name - ", kitName.value);
      Object.entries(breakdownJSONData).forEach((breakdownData) => {
        const [jsonKit, jsonBreakdownOptions] = breakdownData;

        if (jsonKit === kitName.label) {
          console.log("break ", jsonBreakdownOptions);
          console.log("val - ", value);
          Object.entries(jsonBreakdownOptions).forEach((options) => {
            // console.log("options,", options);
            console.log("options 1- ", options[1]);
            if (options[1] === value) {
              console.log("matched - ", value, " options[0] ", options[0]);
              labelName = options[0];
            }
            // breakdownOptionArr.push({ label: options[0], value: options[1] });
          });
        }
      });
      return labelName;
    };

    return (
      <>
        <span
          style={{
            width: "18rem",
            fontWeight: 700,
            marginBottom: "4px",
            marginTop: "32px",
          }}>
          Breakdown
        </span>
        {dropdownPanels.map((m, index) => {
          return (
            <BrekdownDropdown
              events={allAvailableEventsRef.current}
              ref={(el) => (breakdownDropdownRef.current[index] = el)}
              index={index}
              id={m.id}
              breakdownOptions={breakdownOptions}
              removeBreakdown={handleRemoveSelection}
              savedBreakdownData={savedBreakdownData}
            />
          );
        })}

        <button
          className="querySelector-parent-btn"
          onClick={addFilterDropdown}>
          {" "}
          + Add
        </button>
      </>
    );
  }
);

export default BreakdownSelector;
